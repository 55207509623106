<span class="inline-message-container">
    <div class="flex gap-4 grow pt-[6px] pb-[6px] pr-4">
        <mat-icon
            class="icon min-w-6"
            [ngClass]="inlineMessageType$ | async"
            [svgIcon]="icon$ | async"
        ></mat-icon>
        <div class="inline-message-text">
            <ng-content select="[content]"></ng-content>
        </div>
    </div>
    <ng-content select="[button]"></ng-content>
</span>
