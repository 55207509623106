import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ExpandableComponent } from './expandable.component'
import { MatIcon } from '@angular/material/icon'

@NgModule({
    declarations: [ExpandableComponent],
    exports: [ExpandableComponent],
    imports: [CommonModule, MatIcon],
})
export class ExpandableModule {}
