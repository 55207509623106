import { CommonModule } from '@angular/common'
import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { Router, RouterModule } from '@angular/router'
import { Language } from '@localization-lib/language/models/Language'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { LandingPageLanguageSelectorComponent } from '@platform-lib/components/header/landing-page-top-bar/language-selector/landing-page-language-selector.component'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { ParallaxService } from '@shared-util-lib/parallax/parallax.service'
import { Subscription } from 'rxjs'

@Component({
    standalone: true,
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        LandingPageLanguageSelectorComponent,
        MatButtonModule,
        RouterModule,
        TranslationModule,
    ],
    selector: 'app-landing-page-top-bar',
    templateUrl: './landing-page-top-bar.component.html',
    styleUrls: ['./landing-page-top-bar.component.scss'],
})
export class LandingPageTopBarComponent implements OnInit, OnDestroy {
    Language = Language
    private subscriptions = new Subscription()
    private destroyParallax?: () => void

    isScrolled = false
    isScrolledToSticky = false

    constructor(
        private ngZone: NgZone,
        private router: Router,
        private elementRef: ElementRef,
        private parallaxService: ParallaxService
    ) {}

    ngOnInit() {
        this.destroyParallax = this.parallaxService.create({
            from: 0,
            to: 64,
            direct: this.elementRef.nativeElement,
            props: {
                '--transition': {
                    from: '0',
                    to: '1',
                },
            },
            inside: (_, percentage) => {
                this.ngZone.run(() => {
                    this.isScrolled = percentage > 0
                    this.isScrolledToSticky && (this.isScrolledToSticky = false)
                })
            },
            outside: (_, percentage) => {
                this.ngZone.run(() => {
                    this.isScrolled = true
                    !this.isScrolledToSticky && (this.isScrolledToSticky = true)
                })
            },
        })
    }

    ngOnDestroy() {
        this.destroyParallax && this.destroyParallax()
        this.subscriptions.unsubscribe()
    }

    signIn() {
        this.router.navigate(['/dashboard'])
    }

    goToRegister() {
        this.router.navigate(['/register'])
    }
}
