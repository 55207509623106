import { Component, HostBinding, Input } from '@angular/core'
import { ReplaySubject, Subscription } from 'rxjs'

type ButtonGroupAlignment = 'left' | 'center' | 'right' | 'space-between'

@Component({
    selector: 'app-button-group',
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent {
    private subscriptions = new Subscription()

    @HostBinding('class.button-group-center') alignCenter = false
    @HostBinding('class.button-group-right') alignRight = false
    @HostBinding('class.button-group-space-between') alignRSpaceBetween = false

    alignment$ = new ReplaySubject<ButtonGroupAlignment>(1)
    @Input()
    set alignment(alignment: ButtonGroupAlignment) {
        this.alignment$.next(alignment)
    }

    constructor() {
        this.subscriptions.add(
            this.alignment$.subscribe((alignment) => {
                this.alignCenter = alignment === 'center'
                this.alignRight = alignment === 'right'
                this.alignRSpaceBetween = alignment === 'space-between'
            })
        )
    }
}
