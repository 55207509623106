import { NgModule } from '@angular/core'
import { TrackHoverDirective } from '@analytics-lib/track-hover/track-hover.directive'
import { TrackClickDirective } from './track-click/track-click.directive'
import { TrackContextDirective } from './track-context/track-context.directive'
import { TrackOverlayContextDirective } from './track-overlay-context/track-overlay-context.directive'

@NgModule({
    declarations: [
        TrackClickDirective,
        TrackContextDirective,
        TrackHoverDirective,
        TrackOverlayContextDirective,
    ],
    exports: [
        TrackClickDirective,
        TrackContextDirective,
        TrackHoverDirective,
        TrackOverlayContextDirective,
    ],
    imports: [],
})
export class AnalyticsModule {}
