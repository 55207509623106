<mat-expansion-panel expanded class="mx-1 my-1 mt-6">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <span translate>Access Period (optional)</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex flex-col flex-1 justify-between overflow-auto py-4">
        <span translate class="text-accent-medium py-2">
            AccessPeriodDescription
        </span>

        <div class="flex flex-row pt-4">
            <mat-form-field appearance="outline" class="date-picker pr-4">
                <mat-label translate>Start (optional)</mat-label>
                <input
                    matInput
                    [matDatepicker]="startPicker"
                    [formControl]="startDateControl"
                    [matDatepickerFilter]="isValidStartDate"
                    (focus)="startPicker.open()"
                    class="date-picker__input"
                    autocomplete="off"
                />
                <app-button
                    *ngIf="startDateControl.value"
                    (buttonClick)="
                        resetPeriodSelection($event, startDateControl)
                    "
                    matSuffix
                    icon="close"
                    iconButton
                ></app-button>
                <mat-datepicker-toggle
                    *ngIf="!startDateControl.value"
                    matSuffix
                    [for]="startPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="date-picker pr-1">
                <mat-label translate>End (optional)</mat-label>
                <input
                    matInput
                    [matDatepicker]="endPicker"
                    [formControl]="endDateControl"
                    [matDatepickerFilter]="isValidEndDate"
                    (focus)="endPicker.open()"
                    class="date-picker__input"
                    autocomplete="off"
                />
                <app-button
                    *ngIf="endDateControl.value"
                    (buttonClick)="resetPeriodSelection($event, endDateControl)"
                    matSuffix
                    icon="close"
                    iconButton
                ></app-button>
                <mat-datepicker-toggle
                    *ngIf="!endDateControl.value"
                    matSuffix
                    [for]="endPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</mat-expansion-panel>
