import { Injectable, OnDestroy } from '@angular/core'
import { UserProfileService } from '@auth-util-lib/user-profile.service'
import { ApiService } from '@env-lib/api/api.service'
import { FeatureFlag } from '@feature-flag-lib/feature-flags.model'
import { isTruthy } from '@util-lib/isTruthy'
import { LDClient, LDUser, initialize } from 'launchdarkly-js-client-sdk'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class LaunchDarklyService implements OnDestroy {
    private subscriptions = new Subscription()
    private client?: LDClient
    private clientAvailable$ = new ReplaySubject<boolean>(1)

    private changes$ = this.clientAvailable$.pipe(
        filter(isTruthy),
        switchMap(
            () =>
                new Observable<void>((subscriber) => {
                    // We know for sure that te client is available since clientReady has been emitted
                    const client = this.client as LDClient

                    const notifySubscriber = () => subscriber.next()

                    this.subscriptions.add(
                        this.clientAvailable$.subscribe(() => {
                            notifySubscriber()
                        })
                    )

                    const useStreaming = this.api.launchDarkly.useStreaming

                    if (useStreaming) {
                        client.on('change', notifySubscriber)
                    }

                    return () => {
                        if (useStreaming) {
                            client.off('change', notifySubscriber)
                        }
                    }
                })
        )
    )

    ready$ = this.clientAvailable$.asObservable()

    allFlags$ = this.changes$.pipe(map(() => this.client?.allFlags() || {}))

    private setClientAvailable(available: boolean) {
        this.clientAvailable$.next(available)
    }

    constructor(
        private api: ApiService,
        private userProfileService: UserProfileService
    ) {
        this.setClientAvailable = this.setClientAvailable.bind(this)

        this.subscriptions.add(
            this.userProfileService.profileChanges().subscribe((profile) => {
                if (profile) {
                    const user: LDUser = {
                        email: profile.email,
                        key: profile.userId,
                    }

                    if (!this.client) {
                        this.client = initialize(
                            this.api.launchDarkly.clientSideId,
                            user
                        )
                        this.client.on('ready', () =>
                            this.setClientAvailable(true)
                        )
                    } else {
                        this.client.identify(user)
                    }
                } else {
                    this.setClientAvailable(false)
                }
            })
        )
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
        if (this.client) {
            this.client.off('ready', this.setClientAvailable)
        }
    }

    variation$<TValue>({ name, defaultValue }: FeatureFlag<TValue>) {
        return this.changes$.pipe(
            map(
                () =>
                    (this.client?.variation(name, defaultValue) ??
                        defaultValue) as TValue
            )
        )
    }
}
