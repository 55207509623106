import { BlockScrollStrategy, ScrollStrategy } from '@angular/cdk/overlay'
import { ViewportRuler } from '@angular/cdk/scrolling'
import { ParallaxService } from '@shared-util-lib/parallax/parallax.service'

export class ParallaxBlockScrollStrategy
    extends BlockScrollStrategy
    implements ScrollStrategy
{
    constructor(
        private parallaxService: ParallaxService,
        _viewportRuler: ViewportRuler,
        document: Document
    ) {
        super(_viewportRuler, document)
    }

    disable() {
        this.parallaxService.setScrollBlock(false)
        super.disable()
    }

    enable() {
        this.parallaxService.setScrollBlock(true)
        super.enable()
    }
}
