<footer class="md:h-18">
    <a class="md:mx-8" (click)="router.navigate(['/legal/contact'])">
        <translate>Contact</translate></a
    >
    <a class="md:mx-8" (click)="router.navigate(['/legal/imprint'])">
        <translate>Imprint</translate></a
    >
    <a class="md:mx-8" (click)="router.navigate(['/legal/privacy'])">
        <translate>Privacy</translate></a
    >
    <a
        class="md:mx-8"
        (click)="router.navigate(['/legal/terms-and-conditions'])"
    >
        <translate> Terms of Use</translate>
    </a>
</footer>
