import {
    AnalyticsCategory,
    AnalyticsHeaderAction,
} from '@analytics-lib/analytics.model'
import { AnalyticsService } from '@analytics-lib/analytics.service'
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AuthnService } from '@auth-util-lib/authn.service'
import { UserProfileService } from '@auth-util-lib/user-profile.service'
import { Animations } from '@shared-util-lib/animations/animations'

@Component({
    animations: [Animations.flipIcon, Animations.fadeIn],
    selector: 'app-user-menu',
    styleUrls: ['./user-menu.component.scss'],
    templateUrl: './user-menu.component.html',
})
export class UserMenuComponent {
    @ViewChild('userMenu', { static: false }) public userMenu?: ElementRef

    readonly user$ = this.profile.profileChanges()
    expanded = false

    @HostListener('document:click', ['$event'])
    onClick(event: Event) {
        if (
            this.userMenu &&
            !this.userMenu.nativeElement.contains(event.target)
        ) {
            this.expanded = false
        }
    }
    constructor(
        private authn: AuthnService,
        private profile: UserProfileService,
        private router: Router,
        private analytics: AnalyticsService
    ) {}

    signOut() {
        this.analytics.trackEvent({
            category: AnalyticsCategory.Header,
            action: AnalyticsHeaderAction.Logout,
        })
        this.authn.initSignOutFlow()
        this.expanded = false
    }

    handleAccountSettingsClick() {
        this.router.navigate(['/account'])
        this.toggleMenu()
        this.analytics.trackEvent({
            category: AnalyticsCategory.Header,
            action: AnalyticsHeaderAction.OpenAccountSettings,
        })
    }

    toggleMenu() {
        this.expanded = !this.expanded
        if (this.expanded) {
            this.analytics.trackEvent({
                category: AnalyticsCategory.Header,
                action: AnalyticsHeaderAction.OpenUserMenu,
            })
        }
    }
}
