import { Pipe, PipeTransform } from '@angular/core'
import { getDateInTimezone } from '@localization-lib/date-time/getDateInTimezone'

export const DEFAULT_TIMEZONE = 'Europe/Berlin'

@Pipe({
    name: 'dateInDefaultTimezone',
})
export class DateInDefaultTimezonePipe implements PipeTransform {
    transform(
        dateTime: Date | string | undefined,
        format?: string
    ): string | undefined {
        return getDateInTimezone({
            dateTime,
            format,
            timezone: DEFAULT_TIMEZONE,
        })
    }
}
