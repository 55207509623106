import { ValidatorFn } from '@angular/forms'

export const validateGroupName = (): ValidatorFn => (control) => {
    if (!control) {
        return null
    }

    let value = control.value
    if (!value?.length) {
        return null
    }

    value = value.replace(/["' ]/g, '')
    if (value === '') {
        return {
            validateGroupName: {
                valid: false,
            },
        }
    }

    return null
}
