import { AnalyticsModule } from '@analytics-lib/analytics.module'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { LoadingModule } from '@loading-lib/loading.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { InlineMessageComponent } from '@shared-ui-lib/inline-message/inline-message.component'
import { UicWagonNumberPipeModule } from '@shared-util-lib/pipes/uic-wagon-number/uic-wagon-number-pipe.module'
import { GroupManagementWagonNumberInputComponent } from './group-management-wagon-number-input.component'

@NgModule({
    declarations: [GroupManagementWagonNumberInputComponent],
    exports: [GroupManagementWagonNumberInputComponent],
    imports: [
        AnalyticsModule,
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        FormsModule,
        LoadingModule,
        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule,
        TranslationModule,
        UicWagonNumberPipeModule,
        InlineMessageComponent,
    ],
})
export class GroupManagementWagonNumberInputModule {}
