import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { DialogService } from '@shared-ui-lib/dialog/dialog.service'

@Component({
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        MatDialogModule,
        TranslationModule,
    ],
    standalone: true,
    selector: 'app-dialog-wrapper',
    templateUrl: './dialog-wrapper.component.html',
})
export class DialogWrapperComponent {
    _showCloseButton = false
    _hideActions = false

    title: string | undefined
    bodyText: string | undefined
    actionButtonText: string | undefined

    constructor(
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<DialogWrapperComponent>,
        @Inject(MAT_DIALOG_DATA) dialogData
    ) {
        this._showCloseButton = this.dialogService.showCloseButton

        if (dialogData && dialogData.hideActions) {
            this._hideActions = true
        }

        if (dialogData && dialogData.simpleDialog) {
            this.title = dialogData.simpleDialog.title
            this.bodyText = dialogData.simpleDialog.bodyText
            this.actionButtonText = dialogData.simpleDialog.actionButtonText
        }
    }

    onCloseClick() {
        this.dialogRef.close({ actionButtonClicked: false })
    }

    onActionButtonClick() {
        this.dialogRef.close({ actionButtonClicked: true })
    }
}
