import { CommonModule } from '@angular/common'
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core'
import { TranslationComponent } from '@localization-lib/language/translation.component'
import { TranslationDirective } from '@localization-lib/language/translation.directive'
import { TranslationPipe } from '@localization-lib/language/translation.pipe'
import {
    TranslationService,
    TranslationServiceOptions,
} from '@localization-lib/language/translation.service'

export interface TranslationModuleOptions {
    localesPath?: string
}

// NOTE: This value has to be exported otherwise the AoT compiler won't see it.
export const FOR_ROOT_TRANSLATION_SERVICE_OPTIONS_TOKEN =
    new InjectionToken<TranslationModuleOptions>(
        'forRoot() TranslationService configuration.'
    )

// NOTE: This value has to be exported otherwise the AoT compiler won't see it.
export function provideTranslationServiceOptions(
    options?: TranslationModuleOptions
): TranslationServiceOptions {
    const translationServiceOptions = new TranslationServiceOptions()

    if (options) {
        if (options.localesPath) {
            translationServiceOptions.localesPath = options.localesPath
        }
    }

    return translationServiceOptions
}

@NgModule({
    declarations: [TranslationComponent, TranslationPipe, TranslationDirective],
    exports: [TranslationComponent, TranslationPipe, TranslationDirective],
    imports: [CommonModule],
})
export class TranslationModule {
    static forRoot(): ModuleWithProviders<TranslationModule> {
        return {
            ngModule: TranslationModule,
            providers: [
                {
                    provide: FOR_ROOT_TRANSLATION_SERVICE_OPTIONS_TOKEN,
                    useValue: undefined,
                },
                {
                    provide: TranslationServiceOptions,
                    useFactory: provideTranslationServiceOptions,
                    deps: [FOR_ROOT_TRANSLATION_SERVICE_OPTIONS_TOKEN],
                },
                TranslationService,
            ],
        }
    }

    static forChild(
        options?: TranslationServiceOptions
    ): ModuleWithProviders<TranslationModule> {
        return {
            ngModule: TranslationModule,
            providers: [
                {
                    provide: FOR_ROOT_TRANSLATION_SERVICE_OPTIONS_TOKEN,
                    useValue: options,
                },
                {
                    provide: TranslationServiceOptions,
                    useFactory: provideTranslationServiceOptions,
                    deps: [FOR_ROOT_TRANSLATION_SERVICE_OPTIONS_TOKEN],
                },
                TranslationService,
            ],
        }
    }
}
