import { isElementNode } from '@util-lib/isElementNode'
import { isTextNode } from '@util-lib/isTextNode'

export function convertElementToTranslationString(element: Element | Text) {
    let result = ''

    if (isTextNode(element)) {
        result += element.nodeValue
    } else if (isElementNode(element)) {
        element.childNodes.forEach((childNode, i) => {
            if (isTextNode(childNode)) {
                result += childNode.nodeValue
            } else if (isElementNode(childNode)) {
                result += `<${childNode.nodeName.toLowerCase()}>`
                result += convertElementToTranslationString(childNode)
                result += `</${childNode.nodeName.toLowerCase()}>`
            }
        })
    }

    return result
}
