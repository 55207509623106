import dayjs from '@localization-lib/date-time/dayjs'
import { DEFAULT_LANGUAGE } from '@localization-lib/language/models/Language'

export function getDateInTimezone({
    dateTime,
    format,
    timezone,
    locale,
}: {
    dateTime?: Date | string
    format?: string
    timezone?: string
    locale?: string
}): string | undefined {
    if (!dateTime || !dayjs(dateTime).isValid()) {
        return undefined
    }

    return dayjs(dateTime)
        .tz(timezone || dayjs.tz.guess(), false)
        .locale(locale || DEFAULT_LANGUAGE)
        .format(format || 'DD.MM.YYYY HH:mm')
}
