import {
    Component,
    EventEmitter,
    HostBinding,
    ViewChild,
    ElementRef,
    Input,
    Output,
} from '@angular/core'

@Component({
    selector: 'app-expandable',
    styleUrls: ['./expandable.component.scss'],
    templateUrl: './expandable.component.html',
})
export class ExpandableComponent {
    @HostBinding('class.expanded')
    private _expanded = false

    @ViewChild('expandablePanel', { static: true })
    private _panelRef!: ElementRef<HTMLDivElement>

    private get _panel() {
        return this._panelRef.nativeElement
    }

    @Input() set expanded(expanded: boolean) {
        if (expanded === this._expanded) {
            return
        }

        this._expanded = expanded

        if (expanded) {
            this._panel.addEventListener(
                'transitionend',
                this.handleFullyExpanded
            )
            this._panel.style.maxHeight = this._panel.scrollHeight + 'px'
        } else {
            this._panel.style.maxHeight = this._panel.scrollHeight + 'px'
            setTimeout(() => (this._panel.style.maxHeight = '0'))
        }
    }
    get expanded() {
        return this._expanded
    }

    @Input()
    showBorderBottom = true

    @Input()
    showIndicator = false

    @Output() public readonly expandedChange = new EventEmitter<boolean>()

    private handleFullyExpanded = () => {
        this._panel.removeEventListener(
            'transitionend',
            this.handleFullyExpanded
        )
        this._panel.style.maxHeight = 'none'
    }

    toggleExpanded() {
        this.expanded = !this.expanded
        this.expandedChange.emit(this.expanded)
    }
}
