<app-error-panel
    [ngSwitch]="authenticated$ | async"
    [class.screen]="(authenticated$ | async) === AuthState.Unauthenticated"
    [customIcon]="
        (authenticated$ | async) === AuthState.Unauthenticated
            ? 'error-permission'
            : undefined
    "
>
    <div *ngSwitchCase="AuthState.Unauthenticated">
        <p class="text-body-3" translate>Seems like you are not logged in.</p>

        <p class="mat-subtitle-2" translate>
            Try logging in or visit the
            <a href="/covid-19-update">Supplier &amp; Workshop Operations</a>
            page.
        </p>

        <div class="actions">
            <app-button
                (buttonClick)="signIn()"
                icon="arrow-right"
                iconPosition="right"
            >
                <span translate>Log In</span>
            </app-button>
        </div>
    </div>
    <div *ngSwitchCase="AuthState.Authenticated">
        <p translate>
            There is nothing here for you yet. But we are working on it!
        </p>
        <p translate>
            Meanwhile, you could have a look at the navigation menu and discover
            traigo.
        </p>
    </div>
    <div *ngSwitchDefault>
        <p>Loading...</p>
    </div>
</app-error-panel>
