<app-page>
    <h4>VTG Rail Europe GmbH</h4>
    <section>
        <p>Nagelsweg 34</p>
        <p>20097 Hamburg, Germany</p>
        <p>Tel: +49 40 2354 - 0</p>
        <p>Fax: +49 40 2354 1199</p>
        <p>E-Mail: <a href="mailto:info@vtg.com">info&#64;vtg.com</a></p>
    </section>
    <section>
        <p translate>ExecutiveBoardMembers</p>
        <p>
            <translate>Headquarter:</translate>
            Hamburg
        </p>
        <p>
            <translate
                >Registration court and Commercial Registry number:</translate
            >
            Hamburg HRB 135611
        </p>
        <p>
            <translate>VAT ID number:</translate>
            DE815580376
        </p>
    </section>
    <section>
        <h4 translate>Liability for content and links</h4>
        <p>
            <span translate>
                LiabilityForContentAndLinksTextToTheExtendTheOperatorImplementsLinksEtc
            </span>
            <a href="mailto:servicedesk@vtg.com">servicedesk&#64;vtg.com</a>
        </p>
    </section>

    <section>
        <h4 translate>Copyright</h4>
        <p translate>CopyrightTextThisWebsiteContainsEtc</p>
        <p translate>CopyrightTextTheEuComissionProvidesEtc</p>
    </section>
    <section>
        <h4 translate>Disclaimer</h4>
        <p translate>DisclaimerTextTheContentsOfThisPublicationEtc</p>
        <div class="eu-logo">
            <img
                srcset="
                    ../../../assets/images/en-horizontal-cef-logo-2@2x.png 2x,
                    ../../../assets/images/en-horizontal-cef-logo-2@3x.png 3x
                "
            />
        </div>
    </section>
    <div class="ml-auto text-primary flex justify-end pt-8">
        <app-back-to-top></app-back-to-top>
    </div>
</app-page>
