import { Component, OnDestroy } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { TermsConditionsService } from '@platform-lib/legal/terms-conditions/terms-conditions.service'
import { Animations } from '@shared-util-lib/animations/animations'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
    animations: [Animations.flipIcon],
    selector: 'app-terms-conditions-dialog',
    styleUrls: ['./terms-conditions-dialog.component.scss'],
    templateUrl: './terms-conditions-dialog.component.html',
})
export class TermsConditionsDialogComponent implements OnDestroy {
    private readonly openExpandable$ = new Subject<string | null>()

    readonly showError$ = new Subject<boolean>()

    readonly showTermsAndConditionsExpandable$ = this.openExpandable$.pipe(
        map((expandable) => expandable === 'termsAndConditions')
    )

    readonly showPrivacyPolicyExpandable$ = this.openExpandable$.pipe(
        map((expandable) => expandable === 'privacyPolicy')
    )

    readonly form = this.formBuilder.group({
        termsAndConditions: this.formBuilder.control(false, [
            Validators.requiredTrue,
        ]),
        privacyPolicy: this.formBuilder.control(false, [
            Validators.requiredTrue,
        ]),
    })

    readonly formStatusChangeSubscription = this.form.statusChanges.subscribe(
        (_) => this.showError$.next(false)
    )

    constructor(
        private dialogRef: MatDialogRef<
            TermsConditionsDialogComponent,
            boolean
        >,
        private formBuilder: UntypedFormBuilder,
        private termsConditionsService: TermsConditionsService
    ) {}

    async handleAccept() {
        this.form.disable()
        try {
            await this.termsConditionsService.saveConsent()
            this.dialogRef.close(true)
        } catch (_) {
            this.form.enable()
            this.form.reset()
            this.showError$.next(true)
        }
    }

    handleOpen(isOpen: boolean, expandable: string) {
        this.openExpandable$.next(isOpen ? expandable : null)
    }

    handleCancel() {
        this.dialogRef.close(false)
    }

    ngOnDestroy() {
        this.formStatusChangeSubscription.unsubscribe()
    }
}
