<div
    class="expandable-header"
    [ngClass]="showIndicator ? 'flex flex-row items-center' : ''"
    [class.show_border_bottom]="showBorderBottom"
    (click)="toggleExpanded()"
>
    <ng-content class="grow" select="[expandable-head]"></ng-content>
    <mat-icon
        *ngIf="showIndicator"
        color="primary"
        class="h-6 w-6 ml-auto shrink-0"
        [svgIcon]="expanded ? 'chevron-up' : 'chevron-down'"
    >
    </mat-icon>
</div>
<div class="expandable-panel" #expandablePanel>
    <div class="expandable-panel-content">
        <ng-content select="[expandable-body]"></ng-content>
    </div>
</div>
