import { Injectable } from '@angular/core'
import { Action } from '@auth-util-lib/auth.model'
import { Language } from '@localization-lib/language/models/Language'
import { FeatureFlagOverride } from '@platform-lib/components/developer-tools/developer-tools.model'
import { BehaviorSubject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class DeveloperToolsService {
    private _overriddenLanguage$ = new BehaviorSubject<Language | null>(null)
    private _disabledActions$ = new BehaviorSubject<Action[]>([])
    private _featureFlags$ = new BehaviorSubject<
        FeatureFlagOverride<boolean>[]
    >([])

    constructor() {
        this.resetActions()
    }

    get overriddenLanguage$() {
        return this._overriddenLanguage$.asObservable()
    }

    get disabledActions$() {
        return this._disabledActions$.asObservable()
    }

    get overriddenFeatureFlags$() {
        return this._featureFlags$.asObservable()
    }

    overrideLanguage(language: Language) {
        this._overriddenLanguage$.next(language)
    }

    toggleAction(action: Action) {
        const currentDisabledActions = this._disabledActions$.getValue()
        this._disabledActions$.next(
            currentDisabledActions.includes(action)
                ? currentDisabledActions.filter((_action) => _action !== action)
                : [...currentDisabledActions, action]
        )
    }

    overrideFeatureFlag(override: FeatureFlagOverride<boolean>) {
        const currentOverriddenFeatureFlags = this._featureFlags$.getValue()
        this._featureFlags$.next([
            ...currentOverriddenFeatureFlags.filter(
                (_override) => _override.flag.name !== override.flag.name
            ),
            override,
        ])
    }

    resetLanguage() {
        this._overriddenLanguage$.next(null)
    }

    resetActions() {
        this._disabledActions$.next([])
    }

    resetFeatureFlags() {
        this._featureFlags$.next([])
    }
}
