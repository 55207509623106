import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { FooterComponent } from '@platform-lib/components/footer/footer.component'

@NgModule({
    declarations: [FooterComponent],
    exports: [FooterComponent],
    imports: [CommonModule, RouterModule, TranslationModule],
})
export class FooterModule {}
