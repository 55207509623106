<div
    #userMenu
    class="flex items-center text-white user hover:bg-primary px-4 relative cursor-pointer"
    [ngClass]="{ 'bg-primary': expanded === true }"
>
    <div (click)="toggleMenu()" class="flex items-center">
        <mat-icon svgIcon="user" class="text-white mr-2"></mat-icon>
        <span class="text-button hidden md:block">
            {{ (user$ | async)?.firstName }}
            {{ (user$ | async)?.lastName }}
        </span>
        <mat-icon
            class="text-white"
            svgIcon="drop-down"
            [@flipIcon]="expanded"
        ></mat-icon>
    </div>
    <div
        *ngIf="expanded"
        class="dropdown text-primary bg-white overflow-hidden w-auto md:w-full"
        [@fadeIn]
    >
        <div class="entry text-button" (click)="handleAccountSettingsClick()">
            <translate class="block p-4">Account Settings</translate>
        </div>
        <div class="entry text-button" (click)="signOut()">
            <translate class="block p-4">Logout</translate>
        </div>
    </div>
</div>
