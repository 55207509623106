<div
    [@slideContentRight]="animationState"
    (@slideContentRight.start)="onAnimationStart($event)"
    (@slideContentRight.done)="onAnimationDone($event)"
    class="h-full flex flex-col bg-white editor-root"
>
    <div class="flex flex-row flex-0 items-center px-6 py-2">
        <mat-icon
            *ngIf="isDefaultGroup"
            svgIcon="company-filled"
            class="min-w-6 mr-2 text-accent-dark"
        ></mat-icon>
        <span class="text-subtitle-1 flex-1">{{ groupName }}</span>
        <app-button
            buttonType="ghost"
            iconButton
            icon="close"
            class="close-dialog"
            (buttonClick)="requestClose()"
        ></app-button>
    </div>
    <mat-divider></mat-divider>
    <ng-container *ngIf="isGroupAccessible$ | async; else noAccess">
        <ng-container
            *ngIf="
                (isGroupEmptyAndNormalUserInGacClient$ | async) === false;
                else emptyGroup
            "
        >
            <ng-container
                *ngIf="loadPossibleWagonsLoading$ | async; else loadWagonsError"
            >
                <div class="flex flex-col flex-1 justify-between">
                    <div class="p-6">
                        <div class="flex flex-col gap-2">
                            <div class="editor-skeleton w-[336px] h-7"></div>
                            <div
                                class="editor-skeleton w-[336px] h-[60px] mb-12"
                            ></div>
                            <div class="editor-skeleton w-162 h-7"></div>
                            <div class="editor-skeleton w-162 h-7"></div>
                            <div class="editor-skeleton w-162 h-[60px]"></div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #loadWagonsError>
                <app-system-response
                    small
                    *ngIf="savingErrorOccurred$ | async; else editGroupError"
                    responseType="error"
                    title="LoadPossibleWagonsError"
                >
                </app-system-response>
            </ng-template>

            <ng-template #editGroupError>
                <app-system-response
                    *ngIf="
                        (editGroupError$ | async) ||
                            (changeWagonSelectionError$ | async);
                        else editorContent
                    "
                    small
                    responseType="error"
                    title="EditGroupError"
                >
                </app-system-response>
            </ng-template>

            <ng-template #editorContent>
                <div class="flex flex-col flex-1 overflow-auto px-6">
                    <div
                        *ngIf="(showStepper$ | async) === false"
                        class="pt-6"
                    ></div>
                    <mat-stepper
                        #stepper
                        (selectionChange)="resetSteps(stepper)"
                        (selectedIndexChange)="
                            stepIndex.next(stepper.selectedIndex)
                        "
                        [ngClass]="{
                            'hidden-header': (showStepper$ | async) === false,
                        }"
                    >
                        <mat-step>
                            <ng-template matStepLabel>
                                <translate>Basics</translate>
                            </ng-template>

                            <ng-container
                                *ngIf="
                                    isDefaultGroup === false &&
                                    (isGroupManagementAllowed$ | async)
                                "
                            >
                                <form
                                    [formGroup]="groupNameFormGroup"
                                    class="flex flex-col"
                                >
                                    <span translate class="text-body-3 pb-6"
                                        >Name</span
                                    >
                                    <mat-form-field
                                        appearance="outline"
                                        class="w-80 pb-6"
                                    >
                                        <input
                                            matInput
                                            required
                                            [placeholder]="
                                                'Set a group name' | translate
                                            "
                                            formControlName="groupName"
                                            [maxLength]="30"
                                        />

                                        <mat-label translate
                                            >Set a group name
                                        </mat-label>

                                        <mat-error
                                            *ngIf="
                                                groupNameFormGroup.get(
                                                    'groupName'
                                                )?.errors?.required ||
                                                groupNameFormGroup.get(
                                                    'groupName'
                                                )?.errors?.validateGroupName
                                            "
                                            translate
                                        >
                                            Please provide a group name
                                        </mat-error>
                                    </mat-form-field>
                                </form>
                            </ng-container>
                            <app-group-management-wagon-number-input
                                [isAdmin]="isAdmin$ | async"
                                [accessPeriodEndOn]="accessPeriodEndOn$ | async"
                                [alreadySelectedWagons]="
                                    initialSelectedWagons$ | async
                                "
                                [readOnly]="
                                    (isGroupManagementAllowed$ | async) ===
                                    false
                                "
                                [isDefaultGroup]="isDefaultGroup"
                                (selectedWagons)="selectedWagons($event)"
                                (containsInvalidWagonNumberEntries)="
                                    containsInvalidWagonNumberEntries($event)
                                "
                            ></app-group-management-wagon-number-input>
                        </mat-step>
                        <mat-step
                            *ngIf="isExtendedGroupManagementAllowed$ | async"
                        >
                            <ng-template matStepLabel>
                                <translate>Share group</translate>
                            </ng-template>
                            <app-group-management-user-selection
                                [keepAdmins]="isDefaultGroup"
                                [colleagues]="colleagues$ | async"
                                (selectedUserIds)="selectedUserIds.next($event)"
                            ></app-group-management-user-selection>
                            <app-group-management-access-period-selection
                                *ngIf="!isDefaultGroup"
                                [accessPeriod]="currentAccessPeriod$ | async"
                                (selectedAccessPeriod)="
                                    selectAccessPeriod($event)
                                "
                            >
                            </app-group-management-access-period-selection>
                        </mat-step>
                    </mat-stepper>
                </div>
                <div class="flex flex-col flex-0 p-6">
                    <app-button-group
                        *ngIf="
                            isGroupManagementAllowed$ | async;
                            else notPermittedUser
                        "
                        [alignment]="isDefaultGroup ? 'right' : 'space-between'"
                    >
                        <app-button
                            *ngIf="!isDefaultGroup"
                            class="pr-6"
                            icon="delete-outline"
                            buttonType="basic-primary"
                            iconPosition="left"
                            (buttonClick)="deleteGroup()"
                        >
                            <translate>Delete group</translate>
                        </app-button>

                        <div>
                            <app-button
                                class="pr-4"
                                *ngIf="hasPreviousStep$ | async"
                                buttonType="raised-basic"
                                (buttonClick)="stepper.previous()"
                            >
                                <translate>Back</translate>
                            </app-button>
                            <app-button
                                *ngIf="shouldHaveSaveButton$ | async"
                                [buttonType]="
                                    (hasNextStep$ | async)
                                        ? 'raised-basic'
                                        : 'flat-primary'
                                "
                                (buttonClick)="handleFormSubmit()"
                                [status]="
                                    (editGroupLoading$ | async) ||
                                    (changeWagonSelectionLoading$ | async)
                                        ? 'loading'
                                        : 'default'
                                "
                                [disabled]="disableEditButton()"
                            >
                                <translate>Save changes</translate>
                            </app-button>
                            <app-button
                                *ngIf="hasNextStep$ | async"
                                class="pl-4"
                                buttonType="flat-primary"
                                (buttonClick)="stepper.next()"
                            >
                                <translate>Next</translate>
                            </app-button>
                        </div>
                    </app-button-group>
                    <ng-template #notPermittedUser>
                        <span translate class="text-center text-accent-dark">
                            If you have any question, reach out to your admin
                        </span>
                    </ng-template>
                </div>
            </ng-template>
        </ng-container>

        <ng-template #emptyGroup>
            <div class="flex flex-col h-full justify-center overflow-hidden">
                <div
                    class="flex flex-col grow justify-center items-center text-center"
                >
                    <app-system-response
                        small
                        title="Nothing to see here"
                        message="This group is empty and does not contain any wagons"
                        responseType="empty"
                    >
                    </app-system-response>
                </div>
                <div class="flex flex-col flex-0 p-6 text-center">
                    <span translate class="text-accent-dark">
                        If you have any question, reach out to your admin
                    </span>
                </div>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #noAccess>
        <ng-container *ngIf="isExpiredGroup$ | async; else isPending">
            <div
                class="my-64 mx-16 flex place-items-center flex-col text-center"
            >
                <mat-icon
                    svgIcon="clock-filled-past"
                    class="mb-8 text-accent-dark h-[88px] w-[88px]"
                ></mat-icon>

                <span translate class="text-headline-6 text-accent-extra-dark">
                    Ask your admin to modify the access period
                </span>

                <span translate class="text-body-1 mx-3 text-accent-dark mt-0">
                    The group has expired. To use the group and access its
                    details, contact your admin
                </span>
            </div>
        </ng-container>
        <ng-template #isPending>
            <div
                class="my-64 mx-16 flex place-items-center flex-col text-center"
            >
                <mat-icon
                    svgIcon="clock-filled-future"
                    class="mb-8 text-accent-dark h-[88px] w-[88px]"
                ></mat-icon>

                <translate
                    class="text-headline-6 text-accent-extra-dark"
                    [params]="{ beginOn: accessPeriodBeginOn$ | async }"
                    >This group will become available on $$beginOn$$
                </translate>

                <translate class="text-body-1 mx-3 text-accent-dark mt-0">
                    Contact your admin, if you need earlier access
                </translate>
            </div>
        </ng-template>
    </ng-template>
</div>
