import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { Notification } from '@platform-lib/notification/notification.model'

export const notificationEntryAdapter: EntityAdapter<Notification> =
    createEntityAdapter<Notification>({
        selectId: ({ id }) => id,
        // descending sort by occurredAt
        sortComparer: ({ occurredAt: ts1 }, { occurredAt: ts2 }) =>
            ts1 === ts2 ? 0 : ts1 < ts2 ? 1 : -1,
    })
