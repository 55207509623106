import { AnalyticsModule } from '@analytics-lib/analytics.module'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { NotificationBellModule } from '@platform-lib/notification/notification-bell/notification-bell.module'
import { NotificationListModule } from '@platform-lib/notification/notification-list/notification-list.module'
import { IntersectionModule } from '@shared-util-lib/directives/intersection/intersection.module'
import { NotificationPanelComponent } from './notification-panel.component'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'

@NgModule({
    declarations: [NotificationPanelComponent],
    exports: [NotificationPanelComponent],
    imports: [
        AnalyticsModule,
        CommonModule,
        EffectsModule,
        IntersectionModule,
        MatIconModule,
        MatTooltipModule,
        NotificationBellModule,
        NotificationListModule,
        OverlayModule,
        RouterModule,
        StoreModule,
        TranslationModule,
        ButtonModule,
        SystemResponseModule,
    ],
})
export class NotificationPanelModule {}
