import { Component } from '@angular/core'
import { IncompatibleBrowserService } from '@platform-lib/services/incompatible-browser.service'

@Component({
    selector: 'app-incompatible-browser',
    styleUrls: ['./incompatible-browser.component.scss'],
    templateUrl: './incompatible-browser.component.html',
})
export class IncompatibleBrowserComponent {
    constructor(
        private incompatibleBrowserService: IncompatibleBrowserService
    ) {}

    handleContinueClick(event: MouseEvent) {
        event.preventDefault()
        this.incompatibleBrowserService.redirectBackToApplication()
    }
}
