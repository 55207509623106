import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { GroupManagementEffects } from './redux/group-management.effects'
import { groupManagementReducer } from './redux/group-management.reducer'
import {
    groupManagementFeatureKey,
    GroupManagementState,
} from './redux/group-management.state'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { ReactiveFormsModule } from '@angular/forms'
import { MatExpansionModule } from '@angular/material/expansion'
import { TranslationModule } from '@localization-lib/language/translation.module'

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([GroupManagementEffects]),
        StoreModule.forFeature<GroupManagementState>(
            groupManagementFeatureKey,
            groupManagementReducer
        ),
        MatCheckboxModule,
        ReactiveFormsModule,
        MatExpansionModule,
        TranslationModule,
    ],
})
export class GroupManagementStoreModule {}
