import { objectValues } from '@util-lib/objectValues'

export enum Language {
    DE = 'de',
    EN = 'en',
}

export const isSupportedLanguage = (lang: string): lang is Language =>
    objectValues(Language).indexOf(lang as Language) >= 0

export const DEFAULT_LANGUAGE: Language = Language.EN

export type MultiLanguageString = {
    [key in Language]: string
}

export type MultiLanguageNumber = {
    [key in Language]: number
}
