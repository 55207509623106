import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { LanguageService } from '@localization-lib/language/language.service'
import { Observable } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
    constructor(private inj: Injector) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (request.headers.has('SkipLanguageInterceptor')) {
            return next.handle(
                request.clone({
                    headers: request.headers.delete('SkipLanguageInterceptor'),
                })
            )
        }

        const languageService = this.inj.get(LanguageService)
        return languageService.selectedLanguage$.pipe(
            first(),
            switchMap((lang) =>
                next.handle(
                    request.clone({
                        headers: request.headers.set('Accept-Language', lang),
                    })
                )
            )
        )
    }
}
