import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IncompatibleBrowserComponent } from './incompatible-browser.component'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'

@NgModule({
    declarations: [IncompatibleBrowserComponent],
    imports: [CommonModule, MatIconModule, MatTooltipModule],
})
export class IncompatibleBrowserModule {}
