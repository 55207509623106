import { AnalyticsEvent } from '@analytics-lib/analytics.model'
import { TrackContextDirective } from '@analytics-lib/track-context/track-context.directive'
import { TrackEventEmitterService } from '@analytics-lib/track-event-emitter.service'
import { Directive, HostListener, Input, Optional } from '@angular/core'

/**
 * Emit tracking events from bound node.
 */
@Directive({
    selector: '[trackClick]', // eslint-disable-line @angular-eslint/directive-selector
})
export class TrackClickDirective<Event extends AnalyticsEvent> {
    /**
     * Tracking event category. If not supplied, a parent TrackContextDirective will be used as fallback.
     *
     * @see TrackContextDirective
     */
    @Input()
    trackCategory?: Event['category']

    /**
     * Tracking event action. If not supplied, a parent TrackContextDirective will be used as fallback.
     *
     * @see TrackContextDirective
     */
    @Input()
    trackAction?: Event['action']

    /**
     * Tracking event label.
     */
    @Input()
    trackLabel?: string

    /**
     * Whether or not to log a datadog warning when category or action is missing.
     */
    @Input()
    suppressErrors?: boolean

    @HostListener('click', ['$event.target'])
    onClick() {
        const category = this.trackCategory || this.context?.trackCategory
        const action = this.trackAction || this.context?.trackAction
        const label = this.trackLabel
        this.trackEventEmitter.emitEvent(
            { category, action, label },
            this.suppressErrors
        )
    }

    constructor(
        private trackEventEmitter: TrackEventEmitterService,
        @Optional() private context: TrackContextDirective<Event>
    ) {}
}
