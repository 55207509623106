import { Injectable } from '@angular/core'
import { Action } from '@auth-util-lib/auth.model'
import { AuthzService } from '@auth-util-lib/authz.service'
import { LocalStorageService } from '@storage-lib/local-storage.service'
import { BehaviorSubject, combineLatest, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { generateHiddenStorageKey } from './util/generateHiddenStorageKey'

@Injectable({
    providedIn: 'root',
})
export class BannerService {
    private triggerReloadStorage$ = new BehaviorSubject<void>(void 0)

    constructor(
        private localStorageService: LocalStorageService,
        private authzService: AuthzService
    ) {}

    showBanner$(action?: Action, id?: string) {
        return combineLatest([
            action ? this.authzService.hasAction$(action) : of(false),
            this.triggerReloadStorage$,
        ]).pipe(
            map(([hasAction]) => {
                if (hasAction) {
                    return false
                }

                if (
                    this.localStorageService.get(
                        generateHiddenStorageKey(action, id)
                    ) === 'true'
                ) {
                    return false
                }
                return true
            })
        )
    }

    hideBanner(action?: Action, id?: string) {
        this.localStorageService.set(
            generateHiddenStorageKey(action, id),
            'true'
        )
        this.triggerReloadStorage$.next()
    }
}
