import { AnalyticsModule } from '@analytics-lib/analytics.module'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { UserMenuComponent } from '@platform-lib/components/user-menu/user-menu.component'

@NgModule({
    declarations: [UserMenuComponent],
    exports: [UserMenuComponent],
    imports: [
        AnalyticsModule,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TranslationModule,
    ],
})
export class UserMenuModule {}
