import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
} from '@angular/core'
import { IntersectionContextDirective } from '@shared-util-lib/directives/intersection/intersection-context.directive'
import { IntersectionAbstract } from '@shared-util-lib/directives/intersection/intersection.abstract'
import { IntersectionChangePayload } from '@shared-util-lib/directives/intersection/intersection.model'
import { isTruthy } from '@util-lib/isTruthy'
import { filter } from 'rxjs/operators'

@Directive({
    selector: '[appIntersection]',
})
export class IntersectionDirective
    extends IntersectionAbstract
    implements OnInit
{
    @Input()
    set intersectionRoot(value: Element | Document | null | undefined) {
        if (typeof value !== 'undefined') {
            this.rootSubject.next(value)
        }
    }

    @Output() readonly intersectionChange =
        new EventEmitter<IntersectionChangePayload>()

    constructor(
        protected elementRef: ElementRef,
        @Optional() protected context: IntersectionContextDirective
    ) {
        super(elementRef)
    }

    ngOnInit() {
        super.ngOnInit()

        this.subscription.add(
            this.observerSubject.pipe(filter(isTruthy)).subscribe(() => {
                // unobserve through context
                this.context?.unobserve(this.elementRef.nativeElement)
                // observe through own intersection observable
                this.observe(
                    this.elementRef.nativeElement,
                    this.emitChange.bind(this)
                )
            })
        )

        // use intersection observer from context (parent) if available
        if (this.context?.getObserver()) {
            this.context.observe(
                this.elementRef.nativeElement,
                this.emitChange.bind(this)
            )
        }
    }

    emitChange(entry: IntersectionObserverEntry) {
        this.intersectionChange.emit({
            entry,
            unobserve: () => {
                this.unobserve(entry.target)
                this.context?.unobserve(entry.target)
            },
        })
    }
}
