<app-page hidePageTitle>
    <div
        [ngClass]="[
            'header-container',
            'grid mb-10 grid-cols-1',
            'md:grid-cols-2',
        ]"
    >
        <h1
            [ngClass]="[
                'text-3xl text-center',
                'md:inline-flex md:self-end',
                'md:mb-7 md:text-5xl  md:text-left',
            ]"
        >
            Service Desk
        </h1>
        <h3
            [ngClass]="[
                'subtitle text-3xl text-center',
                'md:text-4xl md:text-left',
            ]"
            translate
        >
            Nice to hear from you!
        </h3>
        <app-lottie
            class="lottie"
            path="/assets/animations/landing-page/support/data.json"
            cropTop="6.5%"
            cropBottom="6.5%"
        >
        </app-lottie>
    </div>

    <ng-container *ngIf="showServiceDeskStatus$ | async">
        <app-service-desk-status></app-service-desk-status>
    </ng-container>
    <div class="content">
        <div [ngClass]="isSmall === true ? 'card' : 'card--small'">
            <h5 translate>How can we assist you?</h5>
            <p translate>ServiceDeskQuestions</p>
            <div
                [ngClass]="isSmall === true ? 'card__row' : 'card__row--small'"
            >
                <div class="card__row__icon">
                    <mat-icon svgIcon="user"></mat-icon>
                </div>
                <translate class="card__row__contact">Service Desk</translate>
                <a class="card__row__location" href="tel:+494023542626"
                    >+49 40 2354 - 2626</a
                >
                <a class="card__row__email" href="mailto:servicedesk@traigo.com"
                    >servicedesk&#64;traigo.com</a
                >
            </div>
        </div>
        <div [ngClass]="isSmall === true ? 'card' : 'card--small'">
            <h5 translate>Help us improve this platform.</h5>
            <p translate>ServiceDeskImproving</p>
            <div
                [ngClass]="isSmall === true ? 'card__row' : 'card__row--small'"
            >
                <div class="card__row__icon">
                    <mat-icon svgIcon="user"></mat-icon>
                </div>
                <translate class="card__row__contact">Product Team</translate>
                <translate class="card__row__location"
                    >Headquarters Hamburg</translate
                >
                <a href="mailto:feedback@traigo.com" class="card__row__email"
                    >feedback&#64;traigo.com</a
                >
            </div>
        </div>
    </div>
    <div class="ml-auto text-primary flex justify-end pt-8">
        <app-back-to-top></app-back-to-top>
    </div>
</app-page>
