import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { FastTrackService } from '@fast-track-lib/fast-track.service'
import { processHttpResponse } from '@util-lib/processHttpResponse'

@Component({
    selector: 'app-fast-track-mailto-dialog',
    templateUrl: './fast-track-access-request-dialog.component.html',
})
export class FastTrackAccessRequestDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<FastTrackAccessRequestDialogComponent>,
        private router: Router,
        private fastTrackService: FastTrackService
    ) {}

    onSendMailClick() {
        this.fastTrackService
            .submitAccessRequest()
            .pipe(processHttpResponse())
            .subscribe(({ error }) => {
                if (error) {
                    this.dialogRef.close()
                    this.router.navigate(['/fast-track/access-failure'])
                } else {
                    this.dialogRef.close()
                    this.router.navigate(['/fast-track/access-success'])
                }
            })
    }

    onCloseClick() {
        this.dialogRef.close()
    }
}
