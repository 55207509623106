export function transformUicWagonNumber(wagonNumber: string): string {
    let formattedString = wagonNumber
    if (
        !!wagonNumber &&
        wagonNumber.length === 12 &&
        !isNaN(Number(wagonNumber))
    ) {
        const type: string = wagonNumber.substr(0, 2)
        const country: string = wagonNumber.substr(2, 2)
        const info: string = wagonNumber.substr(4, 4)
        const serial: string = wagonNumber.substr(8, 3)
        const check: string = wagonNumber.substr(11)

        formattedString = `${type} ${country} ${info} ${serial}-${check}`
    }
    return formattedString
}
