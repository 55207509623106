import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { Redirect } from '@auth-util-lib/routing.model'
import { StatusType } from '@datadog/browser-logs'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-not-found',
    styleUrls: ['./not-found.component.scss'],
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
    authenticated$: Observable<AuthenticationState> = this.auth.stateChanges()

    AuthState = AuthenticationState

    constructor(
        private auth: AuthnService,
        activatedRoute: ActivatedRoute,
        private router: Router,
        private datadogService: DatadogService
    ) {
        const url =
            '/' +
            activatedRoute.pathFromRoot
                .map((r) => r.snapshot.url)
                .reduce((acc, val) => acc.concat(val), [])
                .map((f) => f.path)
                .join('/')
        const redirect = activatedRoute.snapshot.data?.redirect.find(
            (redirect: Redirect) => url.startsWith(redirect.from)
        )
        if (!!redirect) {
            router.navigate([redirect.to])
        } else {
            this.datadogService.log(
                `User navigated to 404 page by route ${this.router?.url}`,
                StatusType.info
            )
        }
    }

    signIn() {
        this.auth.initSignInFlow()
    }
}
