import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { AuthnService } from '@auth-util-lib/authn.service'
import { TermsConditionsService } from '@platform-lib/legal/terms-conditions/terms-conditions.service'
import { Observable, iif, of } from 'rxjs'
import { catchError, first, mapTo, switchMap, tap } from 'rxjs/operators'
import { TermsConditionsDialogComponent } from './terms-conditions-dialog/terms-conditions-dialog.component'
import { termsConditionsDialogConfig } from './terms-conditions-dialog/terms-conditions-dialog.config'

@Injectable({
    providedIn: 'root',
})
export class TermsConditionsGuard {
    constructor(
        private authnService: AuthnService,
        private termsConditionsService: TermsConditionsService,
        private dialog: MatDialog
    ) {}

    canLoad(): Observable<boolean> {
        return this.termsConditionsService.isAccepted$.pipe(
            first(),
            tap((accepted) => {
                if (!accepted)
                    throw new Error(
                        'Terms and Conditions are not yet accepted by the User'
                    )
            }),
            catchError(() => this.requestUserPermission())
        )
    }

    private requestUserPermission(): Observable<boolean> {
        return this.dialog
            .open<TermsConditionsDialogComponent, null, boolean>(
                TermsConditionsDialogComponent,
                termsConditionsDialogConfig
            )
            .afterClosed()
            .pipe(
                tap((termsAccepted) => {
                    if (!termsAccepted) {
                        this.authnService.initSignOutFlow()
                    }
                }),
                switchMap((termsAccepted) =>
                    iif(
                        () => !!termsAccepted && termsAccepted,
                        this.authnService
                            .refreshAccessToken()
                            .pipe(mapTo(true)),
                        of(false)
                    )
                ),
                catchError(() => {
                    this.authnService.initSignOutFlow()
                    return of(false)
                })
            )
    }
}
