import { MenuEntry } from '@shared-util-lib/models/menu-entry.model'
import { deepEqualAny } from '@util-lib/deepEqualAny'

export const removeHiddenEntries =
    (
        featureFlagValues: Array<{
            name: string
            value: unknown
        }> | null
    ) =>
    (entries: MenuEntry[]) =>
        featureFlagValues
            ? entries.filter(
                  ({ requiredFeatureFlags }) =>
                      !requiredFeatureFlags?.length ||
                      requiredFeatureFlags?.every(
                          ({ featureFlag, acceptedValue }) =>
                              deepEqualAny(
                                  featureFlagValues.find(
                                      ({ name }) => name === featureFlag.name
                                  )?.value,
                                  acceptedValue
                              )
                      )
              )
            : entries
