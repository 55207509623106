import { Injectable } from '@angular/core'

/**
 * Inject the Microsoft Clarity script into the page.
 *
 * todo replace manual injection with clarity-js when possible (currently causes errors)
 *
 * @see https://www.npmjs.com/package/clarity-js
 */
@Injectable({
    providedIn: 'root',
})
export class ClarityService {
    initClarity() {
        // todo for final implementation store id in api config for
        this.injectClarityScript(window, document, 'ef8rjwmdsh')
    }

    // ORIGINAL SNIPPET:
    // <script type="text/javascript">
    // (function(c,l,a,r,i,t,y){
    //      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    //      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    //      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    // })(window, document, "clarity", "script", "ef8rjwmdsh");
    // </script>
    protected injectClarityScript(
        win: Window & { clarity?: (() => void) & { q?: unknown[] } },
        doc: Document,
        id: string
    ) {
        win.clarity =
            win?.clarity ||
            function (...args) {
                if (win.clarity) {
                    win.clarity.q = win.clarity?.q || []
                    win.clarity.q.push(args)
                }
            }
        const t = doc.createElement('script') as HTMLScriptElement
        t.async = true
        t.src = 'https://www.clarity.ms/tag/' + id
        doc.head.appendChild(t)
    }
}
