import { ErrorType } from '@error-util-lib/error.model'
import { EntityState } from '@ngrx/entity'
import {
    MAX_NOTIFICATION_CHUNK_SIZE,
    Notification,
    NotificationFilterValues,
    NotificationPriority,
    NotificationsActionRequest,
    NotificationsActionRequestWithValue,
    NotificationUseCase,
} from '@platform-lib/notification/notification.model'
import { notificationEntryAdapter } from './notification.adapter'

export const notificationFeatureKey = 'notification'

type NotificationEntryState = EntityState<Notification>

export type NotificationState = Readonly<{
    count: number
    entries: NotificationEntryState
    entriesAvailable: boolean
    entriesLimit: number
    entriesLoading: boolean
    entriesLoadingError: ErrorType | null
    chunkLoading: boolean
    chunkLoadingError: ErrorType | null
    chunkSize: number
    chunkEntriesLoaded: number
    filterValues: NotificationFilterValues
    removeEntriesParams: NotificationsActionRequest | null
    setIsMarkedParams: NotificationsActionRequestWithValue | null
    setIsReadParams: NotificationsActionRequestWithValue | null
}>

export const initialNotificationState: NotificationState = {
    count: 0,
    entries: notificationEntryAdapter.getInitialState(),
    entriesAvailable: true,
    entriesLimit: 15,
    entriesLoading: false,
    entriesLoadingError: null,
    chunkLoading: false,
    chunkLoadingError: null,
    chunkSize: MAX_NOTIFICATION_CHUNK_SIZE,
    chunkEntriesLoaded: 0,
    filterValues: {
        isMarked: false,
        priority: Object.values(NotificationPriority),
        useCase: Object.values(NotificationUseCase),
    },
    removeEntriesParams: null,
    setIsMarkedParams: null,
    setIsReadParams: null,
}
