import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BannerHandlerModule } from '@banner-lib/banner-handler/banner-handler.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { LayoutComponent } from './layout.component'

@NgModule({
    declarations: [LayoutComponent],
    exports: [LayoutComponent],
    imports: [CommonModule, TranslationModule, BannerHandlerModule],
})
export class LayoutModule {}
