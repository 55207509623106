import { Component } from '@angular/core'
import { LanguageService } from '@localization-lib/language/language.service'
import { Language } from '@localization-lib/language/models/Language'

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
})
export class PrivacyComponent {
    readonly Language = Language

    readonly language$ = this.languageService.selectedLanguage$

    constructor(private languageService: LanguageService) {}
}
