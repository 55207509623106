import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core'
import { dateFormats } from '@localization-lib/date-time/material-dayjs/dateFormats'
import { MaterialDayjsDateAdapter } from '@localization-lib/date-time/material-dayjs/material-dayjs-date-adapter'
import { LanguageService } from '@localization-lib/language/language.service'

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {
            provide: DateAdapter,
            useClass: MaterialDayjsDateAdapter,
            deps: [LanguageService],
        },
        { provide: MAT_DATE_FORMATS, useValue: dateFormats },
    ],
})
export class MaterialDayjsModule {}
