import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-loading-indicator',
    styleUrls: ['./loading-indicator.component.scss'],
    templateUrl: './loading-indicator.component.html',
})
export class LoadingIndicatorComponent {
    _primary = false

    @Input()
    set primary(value: boolean) {
        this._primary = coerceBooleanProperty(value)
    }
    get primary() {
        return this._primary
    }

    _small = false
    @Input()
    set small(value: boolean) {
        this._small = coerceBooleanProperty(value)
    }
    get small() {
        return this._small
    }
}
