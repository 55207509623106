import { Component, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { filter } from 'rxjs/operators'

@Component({
    selector: 'app-unregistered',
    styleUrls: ['./unregistered.component.scss'],
    templateUrl: './unregistered.component.html',
})
export class UnregisteredComponent implements OnDestroy {
    private readonly redirect$ = this.authn
        .stateChanges()
        .pipe(
            filter(
                (authState) => authState === AuthenticationState.Authenticated
            )
        )

    private readonly subscription = this.redirect$.subscribe(() =>
        this.continue()
    )

    constructor(
        public readonly authn: AuthnService,
        private router: Router
    ) {}

    continue() {
        this.router.navigate(['/'])
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
