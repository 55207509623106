import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from '@env-lib/api/api.service'
import { Observable } from 'rxjs'
import {
    MAX_NOTIFICATION_CHUNK_SIZE,
    NotificationCountResponse,
    NotificationsActionRequest,
    NotificationsRequest,
    NotificationsResponse,
} from './notification.model'

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(
        private api: ApiService,
        private http: HttpClient
    ) {}

    getNotificationCount(): Observable<NotificationCountResponse> {
        return this.http.get<NotificationCountResponse>(
            `${this.api.notification.backendUrl}/notifications/count`
        )
    }

    getNotifications(
        request: NotificationsRequest
    ): Observable<NotificationsResponse> {
        const params = new HttpParams().set(
            'limitNumberOfNotifications',
            request.limitNumberOfNotifications ?? MAX_NOTIFICATION_CHUNK_SIZE
        )
        return this.http.get<NotificationsResponse>(
            `${this.api.notification.backendUrl}/notifications`,
            { params }
        )
    }

    markAsRead(request: NotificationsActionRequest) {
        return this.http.patch<void>(
            `${this.api.notification.backendUrl}/notifications/read`,
            request
        )
    }

    markAsUnread(request: NotificationsActionRequest) {
        return this.http.patch<void>(
            `${this.api.notification.backendUrl}/notifications/unread`,
            request
        )
    }

    bookmark(request: NotificationsActionRequest) {
        return this.http.patch<void>(
            `${this.api.notification.backendUrl}/notifications/mark`,
            request
        )
    }

    unbookmark(request: NotificationsActionRequest) {
        return this.http.patch<void>(
            `${this.api.notification.backendUrl}/notifications/unmark`,
            request
        )
    }

    deleteNotifications(request: NotificationsActionRequest) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: request,
        }

        return this.http.delete<void>(
            `${this.api.notification.backendUrl}/notifications`,
            httpOptions
        )
    }
}
