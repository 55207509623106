import { AnalyticsEvent } from '@analytics-lib/analytics.model'
import { TrackEventEmitterService } from '@analytics-lib/track-event-emitter.service'
import { findLabel, firstMatchInPath } from '@analytics-lib/track.util'
import { Directive, OnDestroy } from '@angular/core'
import { StatusType } from '@datadog/browser-logs'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'
import { Subject, Subscription } from 'rxjs'
import { throttleTime } from 'rxjs/operators'

/**
 * Special directive to track DOM-nodes in material overlays.
 * These nodes are rendered outside the <app> host directly in the body tag.
 *
 * This directive should be used only once, ideally on the <app> component.
 */
@Directive({
    selector: '[trackOverlayContext]', // eslint-disable-line @angular-eslint/directive-selector
})
export class TrackOverlayContextDirective implements OnDestroy {
    /**
     * Singelton instance (kind of!).
     */
    private static instance?: TrackOverlayContextDirective

    /**
     * Selector of overlay container.
     */
    private selector = '.cdk-overlay-container'

    /**
     * Analytics context (category and action).
     */
    private context?: AnalyticsEvent = undefined

    /**
     * Using a subject to be able to throttle consecutive values,
     * since some clicks produce more than one click event.
     */
    private event$ = new Subject<AnalyticsEvent>()

    private subscription = new Subscription()

    /**
     * The sole purpose of this observer is kick-off overlayMutationObserver
     * once the overlay container is injected into the body
     * (e.g. when the first mat-select is clicked and its dropdown is opened).
     * After that it disconnects itself.
     */
    private bodyMutationObserver = new MutationObserver(() => {
        const overlayContainer = document.body.querySelector(this.selector)
        if (overlayContainer) {
            this.overlayMutationObserver.observe(overlayContainer, {
                childList: true,
            })
            this.bodyMutationObserver.disconnect()
        }
    })

    /**
     * Observes the layout container and unsets the tracking contexts
     * when an overlay is closed (i.e. it has 0 child nodes).
     */
    private overlayMutationObserver = new MutationObserver((mutations) => {
        mutations.map((mutation) => {
            if (!mutation.target.childNodes.length) {
                this.setContext(undefined)
            }
        })
    })

    static getInstance() {
        return TrackOverlayContextDirective.instance
    }

    constructor(
        private trackEventEmitter: TrackEventEmitterService,
        private datadogService: DatadogService
    ) {
        if (TrackOverlayContextDirective.instance) {
            this.datadogService.log(
                'Trying to instantiate multiple instances of TrackOverlayContextDirective',
                StatusType.warn
            )
        } else {
            // set the instance
            TrackOverlayContextDirective.instance = this
            // start observing body
            this.bodyMutationObserver.observe(document.body, {
                childList: true,
            })
            // handle body clicks
            document.body.addEventListener(
                'mousedown',
                this.onBodyClick.bind(this),
                { capture: true }
            )
            // emit tracking events
            this.subscription.add(
                this.event$
                    .pipe(throttleTime(100))
                    .subscribe((event) =>
                        this.trackEventEmitter.emitEvent(event, true)
                    )
            )
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    setContext(context: AnalyticsEvent | undefined) {
        this.context = context
    }

    onBodyClick(event: MouseEvent) {
        const element = firstMatchInPath(
            event,
            document.body,
            `${this.selector} .mat-option`
        )
        if (element && this?.context) {
            this.event$.next({
                category: this.context.category,
                action: this.context.action,
                label: findLabel(element),
            } as AnalyticsEvent)
        }
    }
}
