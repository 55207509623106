import {
    AnalyticsCategory,
    AnalyticsNotificationAction,
} from '@analytics-lib/analytics.model'
import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay'
import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { loadEntries } from '@platform-lib/notification/redux/notification.actions'
import {
    isLoadingEntries,
    selectEntries,
    selectEntryLoadingError,
    selectTotalNotificationCount,
} from '@platform-lib/notification/redux/notification.selectors'
import { Animations } from '@shared-util-lib/animations/animations'
import { Subscription } from 'rxjs'
import { map, shareReplay, withLatestFrom } from 'rxjs/operators'

@Component({
    selector: 'app-notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.scss'],
    animations: [Animations.overlay],
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
    AnalyticsCategory = AnalyticsCategory
    AnalyticsNotificationAction = AnalyticsNotificationAction

    @ViewChild('overlayHeader') overlayHeaderRef?: ElementRef<HTMLElement>

    overlayIsOpen = false

    readonly overlayPositions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
        },
    ]

    readonly overlayOrigin = new CdkOverlayOrigin(this.elementRef)

    readonly error$ = this.store.select(selectEntryLoadingError)

    readonly notifications$ = this.store.select(selectEntries).pipe(
        map((notifications) => notifications.slice(0, 15)),
        shareReplay(1)
    )

    readonly notificationsLoading$ = this.store
        .select(isLoadingEntries)
        .pipe(shareReplay(1))

    // show pixel loader only if dropdown wasn't empty before loading
    readonly loading$ = this.notificationsLoading$.pipe(
        withLatestFrom(this.store.select(selectTotalNotificationCount)),
        map(([loading, count]) => loading && 0 < count),
        shareReplay(1)
    )

    // show skeletons only if dropdown was empty before loading
    readonly showSkeletons$ = this.notificationsLoading$.pipe(
        withLatestFrom(this.store.select(selectTotalNotificationCount)),
        map(([loading, count]) => loading && !count),
        shareReplay(1)
    )

    private subscription = new Subscription()

    constructor(
        public elementRef: ElementRef,
        private renderer: Renderer2,
        private store: Store
    ) {}

    ngOnInit() {
        this.renderer.listen(window, 'click', this.onWindowClick.bind(this))
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    toggleOverlay() {
        this.overlayIsOpen = !this.overlayIsOpen
        if (this.overlayIsOpen) {
            this.store.dispatch(loadEntries({}))
        }
    }

    onWindowClick(event: MouseEvent) {
        const path = event.composedPath()
        const element = this.elementRef.nativeElement
        const overlay = this.overlayHeaderRef?.nativeElement
        // close overlay if it's open only when the panel and bell aren't in the event path
        if (
            this.overlayIsOpen &&
            path.indexOf(element) === -1 &&
            (!overlay || path.indexOf(overlay) === -1)
        ) {
            this.toggleOverlay()
        }
    }
}
