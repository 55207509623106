import { Component, Input } from '@angular/core'
import { ButtonType } from '@shared-ui-lib/button/button.model'
import { BehaviorSubject } from 'rxjs'

@Component({
    selector: 'app-back-to-top',
    templateUrl: './back-to-top.component.html',
})
export class BackToTopComponent {
    buttonType$ = new BehaviorSubject<ButtonType | undefined>(undefined)
    @Input()
    set buttonType(buttonType: ButtonType) {
        this.buttonType$.next(buttonType)
    }

    handleClick() {
        setTimeout(() =>
            scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        )
    }
}
