import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LottieModule as NgxLottieModule } from 'ngx-lottie'
import { LottieComponent } from './lottie.component'

@NgModule({
    declarations: [LottieComponent],
    imports: [CommonModule, NgxLottieModule],
    exports: [LottieComponent],
})
export class LottieModule {}
