/**
 * Retrieve items of `event.path` until `limit` is reached.
 *
 * @param event
 * @param limit
 */
export const eventPathUntil = (event: Event, limit: HTMLElement) => {
    const path = event.composedPath() as HTMLElement[]
    return path.slice(0, path.indexOf(limit) + 1)
}

/**
 * Return first node in `event.path` that matches `selector` before `limit` is reached.
 *
 * @param event
 * @param limit
 * @param selector
 */
export const firstMatchInPath = (
    event: Event,
    limit: HTMLElement,
    selector: string
) =>
    eventPathUntil(event, limit).find((el) => {
        try {
            return el.matches(selector)
        } catch {
            return undefined
        }
    })

/**
 * Find a possible label of a node by checking certain attributes and child nodes.
 *
 * Recursively checks parent nodes if no label is found.
 *
 * @param element
 */
export const findLabel = (element: HTMLElement): string => {
    const labelAttrs = ['aria-label', 'label', 'title', 'alt']
    const possibleLabel = labelAttrs
        .map((attr) => element.getAttribute(attr))
        .find((label) => !!label)
    if (possibleLabel) {
        return possibleLabel
    }
    const labelTag = element.querySelector(
        'label, .mat-label, .mat-option-text'
    )
    if (labelTag) {
        const possibleChildLabel = labelAttrs
            .map((attr) => labelTag.getAttribute(attr))
            .find((label) => !!label)
        if (possibleChildLabel) {
            return possibleChildLabel
        }
    }
    if (labelTag?.textContent) {
        return labelTag.textContent.trim()
    }
    if (element.parentElement && element.parentElement.tagName !== 'BODY') {
        return findLabel(element.parentElement)
    }
    return 'LABEL NOT AVAILABLE'
}
