import { Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { DeveloperToolsService } from '@platform-lib/components/developer-tools/developer-tools.service'
import { combineLatest, Observable } from 'rxjs'
import { filter, map, shareReplay } from 'rxjs/operators'
import {
    Action,
    ActionsMap,
    Claims,
    ClientConfig,
    ClientInformation,
    PermittedAction,
} from './auth.model'
import { AuthenticationState, AuthnService } from './authn.service'
import { isTruthy } from '@util-lib/isTruthy'

@Injectable({
    providedIn: 'root',
})
export class AuthzService {
    private readonly jwtHelper = new JwtHelperService()

    private readonly token$ = this.authn.tokenChanges()

    private readonly decodedToken$ = this.token$.pipe(
        map((token) =>
            token ? (this.jwtHelper.decodeToken(token) as Claims) : null
        )
    )

    private readonly permittedActions$ = combineLatest([
        this.developerToolsService.disabledActions$,
        this.authn.stateChanges(),
        this.getClaim$('permitted_actions'),
    ]).pipe(
        map(
            ([
                disabledActions,
                authState,
                permittedAcctionsClaim,
            ]): PermittedAction[] => {
                if (authState === AuthenticationState.Unauthenticated) {
                    return []
                }

                const actionsFromToken = (
                    permittedAcctionsClaim
                        ? JSON.parse(String(permittedAcctionsClaim))
                        : []
                ) as Array<{ name: Action; activeSince?: string }>

                return actionsFromToken
                    .map((actionFromToken) => {
                        const action = actionFromToken.name
                        const activeSince = !!actionFromToken.activeSince
                            ? new Date(actionFromToken.activeSince)
                            : undefined
                        return { action, activeSince }
                    })
                    .filter(
                        (permittedAction) =>
                            disabledActions.indexOf(permittedAction.action) < 0
                    )
            }
        ),
        shareReplay(1)
    )

    readonly actions$ = this.permittedActions$.pipe(
        map((permittedActions) =>
            permittedActions.reduce<ActionsMap>(
                (actionsMap, permittedAction) => ({
                    ...actionsMap,
                    [permittedAction.action]: true,
                }),
                {}
            )
        ),
        shareReplay(1)
    )

    readonly authorizedForTraigo$ = this.getClaim$(
        'is_authorized_for_traigo'
    ).pipe(map((authorized) => authorized === 'true'))

    readonly clientInformation$: Observable<ClientInformation | null> =
        combineLatest([
            this.authn.stateChanges(),
            this.getClaim$('client_id').pipe(
                map((clientId) => (clientId as string) || undefined)
            ),
            this.getClaim$('client_configs').pipe(
                map(
                    (clientConfigsString) =>
                        (clientConfigsString &&
                            ((clientConfigsString as string).split(
                                ','
                            ) as ClientConfig[])) ||
                        []
                )
            ),
        ]).pipe(
            map(([state, clientId, clientConfigs]) => {
                if (
                    state === AuthenticationState.Unauthenticated ||
                    !clientId
                ) {
                    return null
                }
                return { clientId, clientConfigs }
            }),
            shareReplay(1)
        )

    readonly isGroupAccessControlEnabled$ = this.clientInformation$.pipe(
        filter(isTruthy),
        map((clientInformation) =>
            clientInformation.clientConfigs.includes(
                ClientConfig.EnableGroupAccessControl
            )
        ),
        shareReplay(1)
    )

    constructor(
        private authn: AuthnService,
        private developerToolsService: DeveloperToolsService
    ) {}

    refreshActions() {
        return this.authn.refreshAccessToken()
    }

    hasAction$(action: Action) {
        return this.actions$.pipe(map((actions) => !!actions[action]))
    }

    hasAllActions$(allActions: Action[]) {
        return this.actions$.pipe(
            map((actions) => allActions.every((action) => !!actions[action]))
        )
    }

    getClaim$(claim: keyof Claims): Observable<string | number | null> {
        return this.decodedToken$.pipe(
            map((decoded) => (decoded && decoded[claim]) || null)
        )
    }

    getActionActiveSince$(action: Action): Observable<Date | undefined> {
        return this.permittedActions$.pipe(
            map(
                (permittedActions) =>
                    permittedActions.find(
                        (permittedAction) => permittedAction.action === action
                    )?.activeSince
            )
        )
    }
}
