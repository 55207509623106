<div
    class="page__container"
    [class.page__container--horizontal-scroll]="horizontalScroll"
>
    <div
        class="page__content-wrapper"
        [ngStyle]="{ minWidth: horizontalScroll ? _minWidth : undefined }"
    >
        <app-loading-pin
            *ngIf="
                loadingWholePage ||
                    (waitForTranslations && (loadingTranslations$ | async));
                else pageContent
            "
        ></app-loading-pin>

        <ng-template #pageContent>
            <app-page-title
                *ngIf="!hidePageTitle || !hideBreadcrumb"
                [hidePageTitle]="hidePageTitle"
                [hideBreadcrumb]="hideBreadcrumb"
                [overwritePageTitle]="customPageTitle"
                [loadingPageTitle]="loadingPageTitle"
            ></app-page-title>

            <ng-content></ng-content>
        </ng-template>
    </div>
</div>
