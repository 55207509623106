import { Injectable } from '@angular/core'
import { StatusType } from '@datadog/browser-logs'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'
import { getErrorMessage } from '@error-util-lib/getErrorMessage'
import dayjs from '@localization-lib/date-time/dayjs'
import { LanguageService } from '@localization-lib/language/language.service'
import {
    DEFAULT_LANGUAGE as DEFAULT,
    Language,
} from '@localization-lib/language/models/Language'
import { TranslationFile } from '@localization-lib/language/models/TranslationFile'
import { TranslationParameters } from '@localization-lib/language/models/TranslationParameters'
import { Translator } from '@localization-lib/language/models/Translator'
import { getTranslationFileFromLanguage } from '@localization-lib/language/utils/getTranslationFileFromLanguage'
import { EMPTY, Observable, combineLatest, from, of } from 'rxjs'
import {
    catchError,
    map,
    mapTo,
    retry,
    shareReplay,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators'

@Injectable()
export class TranslationServiceOptions {
    localesPath? = ''
}

@Injectable()
export class TranslationService {
    private loadedLanguages: {
        [path: string]: {
            [lang in Language]?: Observable<TranslationFile>
        }
    } = {}

    readonly selectedLanguage$ = this.languageService.selectedLanguage$.pipe(
        tap((lang) => {
            this.addTranslationFileObservable(lang, '/')
            this.addTranslationFileObservable(DEFAULT, '/')
            this.addTranslationFileObservable(lang, this.localesPath)
            this.addTranslationFileObservable(DEFAULT, this.localesPath)

            dayjs.locale(lang)
        })
    )

    readonly loadingTranslations$ = this.selectedLanguage$.pipe(
        switchMap((lang) =>
            combineLatest([
                this.loadedLanguages['/'][lang] || EMPTY,
                this.loadedLanguages['/'][DEFAULT] || EMPTY,
                this.loadedLanguages[this.localesPath][lang] || EMPTY,
                this.loadedLanguages[this.localesPath][DEFAULT] || EMPTY,
            ])
        ),
        mapTo(false),
        startWith(true)
    )

    private addTranslationFileObservable(lang: Language, localesPath: string) {
        if (!this.loadedLanguages[localesPath]) {
            this.loadedLanguages[localesPath] = {}
        }

        if (!this.loadedLanguages[localesPath][lang]) {
            this.loadedLanguages[localesPath][lang] =
                this.loadTranslationFileForLanguage$(lang, localesPath).pipe(
                    retry({ count: 3, delay: 300 }),
                    catchError((error) => {
                        this.log(
                            'TranslationError: ' + getErrorMessage(error),
                            'error'
                        )
                        console.warn(
                            `Could not load translation file ${localesPath}${getTranslationFileFromLanguage(
                                lang
                            )}.`
                        )
                        return of({})
                    }),
                    shareReplay(1)
                )
        }
    }

    private get localesPath() {
        return `/${this.options.localesPath}/`.replace(/\/{2,}/g, '/')
    }

    private loadTranslationFileForLanguage$(
        lang: Language,
        localesPath = '/'
    ): Observable<TranslationFile> {
        const file = getTranslationFileFromLanguage(lang)
        return from(
            import(`../../../assets/locales${localesPath}${file}`)
        ).pipe(map((file) => file.default))
    }

    private replaceParametersInTranslation(
        translation: string,
        parameters?: TranslationParameters
    ) {
        let correctedTranslation = translation
        if (parameters) {
            for (const parameter in parameters) {
                if (typeof parameter === 'string') {
                    correctedTranslation = correctedTranslation.replace(
                        new RegExp(`\\$\\$${parameter}\\$\\$`, 'g'),
                        parameters[parameter]
                    )
                }
            }
        }

        if (correctedTranslation.indexOf('$$') >= 0) {
            this.log(
                `Missing translation parameter found in translation ${translation}`,
                'error'
            )
        }

        return correctedTranslation
    }

    constructor(
        private languageService: LanguageService,
        private datadogService: DatadogService,
        private options: TranslationServiceOptions
    ) {}

    log(text: string, severity: StatusType) {
        console.warn(text)
        this.datadogService.log(text, severity)
    }

    get translator$(): Observable<Translator> {
        return this.selectedLanguage$.pipe(
            switchMap((lang) => {
                const localesPath = this.localesPath

                return combineLatest([
                    this.loadedLanguages[localesPath][lang] ||
                        of({} as TranslationFile),
                    this.loadedLanguages[localesPath][DEFAULT] ||
                        of({} as TranslationFile),
                    this.loadedLanguages['/'][lang] ||
                        of({} as TranslationFile),
                    this.loadedLanguages['/'][DEFAULT] ||
                        of({} as TranslationFile),
                ]).pipe(
                    map(
                        ([
                            languageTranslations,
                            defaultTranslations,
                            rootLanguageTranslations,
                            rootDefaultTranslations,
                        ]) =>
                            (
                                key: string,
                                parameters?: TranslationParameters
                            ) => {
                                if (!key || typeof key !== 'string') {
                                    return key
                                }
                                const trimmedKey = key.trim()
                                const isPlaceholderKey =
                                    trimmedKey.startsWith('@')
                                const isDefaultLanguage = lang === DEFAULT

                                // Try key in translation file for user language
                                if (languageTranslations[trimmedKey]) {
                                    return this.replaceParametersInTranslation(
                                        languageTranslations[trimmedKey],
                                        parameters
                                    )
                                }

                                // Try key in root translation file for user language, if locales path is not root
                                if (
                                    this.localesPath !== '/' &&
                                    rootLanguageTranslations[trimmedKey]
                                ) {
                                    return this.replaceParametersInTranslation(
                                        rootLanguageTranslations[trimmedKey],
                                        parameters
                                    )
                                }

                                if (!isDefaultLanguage) {
                                    this.log(
                                        `Could not resolve translation with key\n"${trimmedKey}"\nfor language ${lang}.`,
                                        'info'
                                    )
                                }

                                // Try key in translation file for default language
                                if (defaultTranslations[trimmedKey]) {
                                    return this.replaceParametersInTranslation(
                                        defaultTranslations[trimmedKey],
                                        parameters
                                    )
                                }

                                // Try key in root translation file for default language, if locales path is not root
                                if (
                                    this.localesPath !== '/' &&
                                    rootDefaultTranslations[trimmedKey]
                                ) {
                                    return this.replaceParametersInTranslation(
                                        rootDefaultTranslations[trimmedKey],
                                        parameters
                                    )
                                }

                                this.log(
                                    `Could not resolve translation with key\n"${trimmedKey}"\nfor language ${DEFAULT}. Using key as translation.`,
                                    isPlaceholderKey
                                        ? StatusType.error
                                        : StatusType.warn
                                )

                                // Use key as translation as a fallback
                                return this.replaceParametersInTranslation(
                                    key,
                                    parameters
                                )
                            }
                    ),
                    shareReplay(1)
                )
            })
        )
    }

    getTranslation$(key: string, parameters?: TranslationParameters) {
        return this.translator$.pipe(
            map((translator) => translator(key, parameters))
        )
    }
}
