<app-error-panel customIcon="error-unauthorized" class="error-panel">
    <ng-container
        *ngIf="isAdmin$ | async; then adminView; else userView"
    ></ng-container>
    <ng-template #adminView>
        <p translate>Sorry, you don't seem to have access to this tool!</p>
        <p translate>
            Try logging on again or change permissions for you and your users in
            User Management.
        </p>
        <app-button class="action-button" routerLink="/user-management">
            <translate>Go to user management</translate>
        </app-button>
    </ng-template>
    <ng-template #userView>
        <p translate>Sorry, you don't seem to have access!</p>
        <p translate>Try logging on again or contact your administrator.</p>
        <app-button class="action-button" routerLink="/dashboard">
            <translate>Go to dashboard</translate>
        </app-button>
    </ng-template>
</app-error-panel>
