import { BannerPriority } from '@banner-lib/banner.model'

export function getIconForPriority(priority: BannerPriority): {
    icon: string
    iconTextColor: string
} {
    switch (priority) {
        case BannerPriority.TEASER:
            return { icon: 'star', iconTextColor: 'text-caution' }
        case BannerPriority.INFO:
            return { icon: 'info', iconTextColor: 'text-accent-medium' }
        case BannerPriority.WARNING:
            return { icon: 'warning', iconTextColor: 'text-caution' }
        case BannerPriority.ALERT:
            return { icon: 'warning', iconTextColor: 'text-error' }
        default:
            return { icon: 'star', iconTextColor: 'text-caution' }
    }
}
