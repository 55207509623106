import { Component, HostBinding, Input } from '@angular/core'
import { AnimationOptions } from 'ngx-lottie'

@Component({
    selector: 'app-lottie',
    templateUrl: './lottie.component.html',
    styleUrls: ['./lottie.component.scss'],
})
/**
 * Wrapper component for ng-lottie that allows cropping the animation on top and bottom.
 */
export class LottieComponent {
    @Input() aspectRatio?: [number, number]
    @Input() cropTop?: string | 0
    @Input() cropBottom?: string | 0
    @Input() path?: string
    @Input() lottieOptions?: AnimationOptions

    @HostBinding('style.--aspectX')
    get aspectX() {
        return (this.aspectRatio && this.aspectRatio[0]) || 1
    }

    @HostBinding('style.--aspectY')
    get aspectY() {
        return (this.aspectRatio && this.aspectRatio[1]) || 1
    }

    @HostBinding('style.--cropTop')
    get cropTopValue() {
        return this.cropTop || '0%'
    }

    @HostBinding('style.--cropBottom')
    get cropBottomValue() {
        return this.cropBottom || '0%'
    }
}
