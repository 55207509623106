import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UicWagonNumberPipe } from './uic-wagon-number.pipe'

@NgModule({
    declarations: [UicWagonNumberPipe],
    exports: [UicWagonNumberPipe],
    imports: [CommonModule],
    providers: [UicWagonNumberPipe],
})
export class UicWagonNumberPipeModule {}
