import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { map, tap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class DashboardRedirectGuard implements CanActivate {
    constructor(
        private authn: AuthnService,
        private router: Router
    ) {}

    canActivate() {
        return this.authn.stateChanges().pipe(
            tap(
                (authState) =>
                    authState === AuthenticationState.Authenticated &&
                    this.router.navigate(['/dashboard'])
            ),
            map(
                (authState) => authState === AuthenticationState.Unauthenticated
            )
        )
    }
}
