<div class="container">
    <app-button
        (buttonClick)="handleClose(false)"
        data-test="close-button"
        class="dialog__close-button"
        icon="close"
        iconButton
    >
    </app-button>

    <h4>Cookies</h4>

    <p translate>CookieDialogDescription</p>
</div>
<div class="expandables flex-1">
    <app-expandable-cookie
        consentId="cookieConsent"
        [cookiesAllowed]="cookiesAllowed"
        [(expandedId)]="expandedId"
    >
        <ng-container class="expandable-cookie-title">
            <translate>CookieDialogCookieConsentSettingsTitle</translate>
        </ng-container>
        <ng-container class="expandable-cookie-description">
            <p translate>CookieDialogCookieConsentSettingsDescription</p>
        </ng-container>
    </app-expandable-cookie>

    <app-expandable-cookie
        consentId="auth"
        [cookiesAllowed]="cookiesAllowed"
        [(expandedId)]="expandedId"
    >
        <ng-container class="expandable-cookie-title">
            <translate>CookieDialogAzureTitle</translate>
        </ng-container>
        <ng-container class="expandable-cookie-description">
            <p translate="">CookieDialogAzureDescription</p>
        </ng-container>
    </app-expandable-cookie>

    <app-expandable-cookie
        consentId="maptiler"
        [cookiesAllowed]="cookiesAllowed"
        [(expandedId)]="expandedId"
    >
        <ng-container class="expandable-cookie-title">
            <translate>CookieDialogMaptilerTitle</translate>
        </ng-container>
        <ng-container class="expandable-cookie-description">
            <p translate>CookieDialogMaptilerDescription</p>
        </ng-container>
    </app-expandable-cookie>
</div>

<app-button-group alignment="right" class="button-group">
    <app-button
        buttonType="basic-primary"
        [breakLines]="true"
        (buttonClick)="allowAll()"
        data-test="allow-all-button"
    >
        <translate>Allow all</translate>
    </app-button>

    <app-button
        buttonType="flat-primary"
        [breakLines]="true"
        [disabled]="!minimalAcceptenceReached()"
        (buttonClick)="handleClose(true)"
        data-test="agree-and-continue-button"
    >
        <translate>Agree and continue</translate>
    </app-button>
</app-button-group>
