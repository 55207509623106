import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { LoadingModule } from '@loading-lib/loading.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { BreadcrumbModule } from '@shared-ui-lib/breadcrumb/breadcrumb.module'
import { PageTitleComponent } from '@shared-ui-lib/page/page-title/page-title.component'
import { PageComponent } from '@shared-ui-lib/page/page.component'

@NgModule({
    declarations: [PageComponent, PageTitleComponent],
    exports: [PageComponent, PageTitleComponent],
    imports: [
        BreadcrumbModule,
        CommonModule,
        LoadingModule,
        MatIconModule,
        TranslationModule,
    ],
})
export class PageModule {}
