import { NgModule } from '@angular/core'
import { GroupManagementUserSelectionComponent } from './group-management-user-selection.component'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { ReactiveFormsModule } from '@angular/forms'
import { MatExpansionModule } from '@angular/material/expansion'
import { CommonModule } from '@angular/common'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { MatTooltipModule } from '@angular/material/tooltip'

@NgModule({
    declarations: [GroupManagementUserSelectionComponent],
    exports: [GroupManagementUserSelectionComponent],
    imports: [
        CommonModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatExpansionModule,
        TranslationModule,
        MatTooltipModule,
    ],
})
export class GroupManagementUserSelectionModule {}
