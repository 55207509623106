import { Component, OnDestroy } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Action } from '@auth-util-lib/auth.model'
import { AuthzService } from '@auth-util-lib/authz.service'
import { LanguageService } from '@localization-lib/language/language.service'
import {
    DEFAULT_LANGUAGE,
    Language,
} from '@localization-lib/language/models/Language'
import { ServiceDeskStatus } from '@platform-lib/legal/contact/service-desk-status/service-desk-status.model'
import { ServiceDeskStatusService } from '@platform-lib/legal/contact/service-desk-status/service-desk-status.service'
import { isTruthy } from '@util-lib/isTruthy'
import { processHttpResponse } from '@util-lib/processHttpResponse'
import { BehaviorSubject, Subscription } from 'rxjs'
import {
    filter,
    map,
    shareReplay,
    startWith,
    switchMapTo,
    take,
} from 'rxjs/operators'

const CLASS_WARNING = 'status-warning'
const CLASS_CHECK = 'status-check'
const CLASS_ISSUE = 'status-issue'
const CLASS_INFO = 'status-info'
const CLASS_MAINTENANCE = 'status-maintenance'

const DEFAULT_TEXT_ENGLISH =
    'Dear traigo customer. Currently all traigo services can be used as usual.'
const DEFAULT_TEXT_GERMAN =
    'Lieber traigo Kunde. Aktuell können alle traigo Services ohne Einschränkungen genutzt werden.'

const DEFAULT_STATUS = ServiceDeskStatus.CHECK

export interface AvailableStatusOption {
    status: ServiceDeskStatus
    class: string
    icon: string
}

@Component({
    selector: 'app-service-desk-status',
    templateUrl: './service-desk-status.component.html',
    styleUrls: ['./service-desk-status.component.scss'],
})
export class ServiceDeskStatusComponent implements OnDestroy {
    subscriptions = new Subscription()

    isEditModeOn$ = new BehaviorSubject(false)
    reloadTrigger$ = new BehaviorSubject(true)
    hasServiceDeskAction$ = this.authzService.hasAction$(
        Action.ShowClientManagement
    )
    savingStatus = false

    formGroup = this.fb.group({
        languageControl: [DEFAULT_LANGUAGE, Validators.required],
        englishControl: [null, Validators.required],
        germanControl: [null, Validators.required],
        statusControl: [null, Validators.required],
    })

    readonly availableLanguages: Language[] = [Language.EN, Language.DE]

    readonly availableStatus: AvailableStatusOption[] = [
        {
            status: ServiceDeskStatus.CHECK,
            class: this.getIconClass(ServiceDeskStatus.CHECK),
            icon: this.getIcon(ServiceDeskStatus.CHECK),
        },
        {
            status: ServiceDeskStatus.INFO,
            class: this.getIconClass(ServiceDeskStatus.INFO),
            icon: this.getIcon(ServiceDeskStatus.INFO),
        },
        {
            status: ServiceDeskStatus.ISSUE,
            class: this.getIconClass(ServiceDeskStatus.ISSUE),
            icon: this.getIcon(ServiceDeskStatus.ISSUE),
        },
        {
            status: ServiceDeskStatus.MAINTENANCE,
            class: this.getIconClass(ServiceDeskStatus.MAINTENANCE),
            icon: this.getIcon(ServiceDeskStatus.MAINTENANCE),
        },
        {
            status: ServiceDeskStatus.WARNING,
            class: this.getIconClass(ServiceDeskStatus.WARNING),
            icon: this.getIcon(ServiceDeskStatus.WARNING),
        },
    ]

    language = Language

    selectedLanguage$ = this.formGroup
        .get('languageControl')
        ?.valueChanges.pipe(startWith(DEFAULT_LANGUAGE), shareReplay(1))

    userLanguage$ = this.languageService.selectedLanguage$

    statusResponse$ = this.reloadTrigger$.pipe(
        switchMapTo(this.serviceDeskService.getServiceDeskStatus()),
        processHttpResponse(),
        shareReplay(1)
    )

    loading$ = this.statusResponse$.pipe(map(({ loading }) => loading))
    error$ = this.statusResponse$.pipe(map(({ error }) => error))
    status$ = this.statusResponse$.pipe(
        map(({ data }) => data?.status),
        filter(isTruthy)
    )

    statusIcon$ = this.status$.pipe(map((status) => this.getIcon(status)))
    statusClass$ = this.status$.pipe(map((status) => this.getIconClass(status)))

    statusTextEN$ = this.statusResponse$.pipe(
        map(({ data }) => data?.message.en)
    )
    statusTextDE$ = this.statusResponse$.pipe(
        map(({ data }) => data?.message.de)
    )

    constructor(
        private authzService: AuthzService,
        private fb: UntypedFormBuilder,
        private serviceDeskService: ServiceDeskStatusService,
        private languageService: LanguageService
    ) {
        this.subscriptions.add(
            this.statusTextDE$.subscribe((text) => {
                this.formGroup.get('germanControl')?.patchValue(text)
            })
        )

        this.subscriptions.add(
            this.statusTextEN$.subscribe((text) => {
                this.formGroup.get('englishControl')?.patchValue(text)
            })
        )

        this.subscriptions.add(
            this.status$.subscribe((status) => {
                this.formGroup.get('statusControl')?.patchValue(status)
            })
        )
    }

    getIcon(status: ServiceDeskStatus): string {
        switch (status) {
            case ServiceDeskStatus.CHECK:
                return 'check-filled'
            case ServiceDeskStatus.INFO:
                return 'info'
            case ServiceDeskStatus.ISSUE:
                return 'info'
            case ServiceDeskStatus.MAINTENANCE:
                return 'repair'
            case ServiceDeskStatus.WARNING:
                return 'warning'
            default:
                return ''
        }
    }

    getIconClass(status: ServiceDeskStatus): string {
        switch (status) {
            case ServiceDeskStatus.CHECK:
                return CLASS_CHECK
            case ServiceDeskStatus.INFO:
                return CLASS_INFO
            case ServiceDeskStatus.ISSUE:
                return CLASS_ISSUE
            case ServiceDeskStatus.MAINTENANCE:
                return CLASS_MAINTENANCE
            case ServiceDeskStatus.WARNING:
                return CLASS_WARNING
            default:
                return ''
        }
    }

    handleEditButtonClick() {
        this.reloadTrigger$.next(true)
        this.isEditModeOn$.next(true)
    }

    handleCancelClick() {
        this.reloadTrigger$.next(true)
        this.isEditModeOn$.next(false)
    }

    handleSubmit() {
        this.savingStatus = true
        this.serviceDeskService
            .putServiceDeskStatus(
                this.formGroup.get('statusControl')?.value,
                this.formGroup.get('englishControl')?.value,
                this.formGroup.get('germanControl')?.value
            )
            .pipe(take(1))
            .subscribe((_) => {
                this.savingStatus = false
                this.isEditModeOn$.next(false)
                this.reloadTrigger$.next(true)
            })
    }

    handleDefaultButton() {
        this.formGroup.get('englishControl')?.patchValue(DEFAULT_TEXT_ENGLISH)
        this.formGroup.get('statusControl')?.patchValue(DEFAULT_STATUS)
        this.formGroup.get('germanControl')?.patchValue(DEFAULT_TEXT_GERMAN)
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
