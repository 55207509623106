import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { DeveloperToolsComponent } from './developer-tools.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule } from '@angular/material/select'
import { ReactiveFormsModule } from '@angular/forms'
import { MatRadioModule } from '@angular/material/radio'
import { MatCheckboxModule } from '@angular/material/checkbox'

@NgModule({
    declarations: [DeveloperToolsComponent],
    imports: [
        CommonModule,
        RouterModule,
        OverlayModule,
        MatIconModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatCheckboxModule,
    ],
    exports: [DeveloperToolsComponent],
})
export class DeveloperToolsModule {}
