import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { LoadingBarComponent } from './loading-bar/loading-bar.component'
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component'
import { LoadingPinComponent } from './loading-pin/loading-pin.component'

@NgModule({
    declarations: [
        LoadingBarComponent,
        LoadingIndicatorComponent,
        LoadingPinComponent,
    ],
    exports: [
        LoadingBarComponent,
        LoadingIndicatorComponent,
        LoadingPinComponent,
        MatProgressBarModule,
    ],
    imports: [CommonModule, MatIconModule],
})
export class LoadingModule {}
