import { Component } from '@angular/core'
import { Action } from '@auth-util-lib/auth.model'
import { AuthzService } from '@auth-util-lib/authz.service'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-not-allowed',
    templateUrl: './not-allowed.component.html',
    styleUrls: ['./not-allowed.component.scss'],
})
export class NotAllowedComponent {
    isAdmin$: Observable<boolean> = this.auth.hasAction$(
        Action.ShowUserManagement
    )

    constructor(private auth: AuthzService) {}
}
