import { Injectable } from '@angular/core'
import { RouterStateSnapshot, UrlSegment } from '@angular/router'
import {
    AppActivatedRouteSnapshot,
    AppRoute,
    AppRouteData,
} from '@auth-util-lib/routing.model'
import { FeatureFlag } from '@feature-flag-lib/feature-flags.model'
import { FeatureFlagsService } from '@feature-flag-lib/feature-flags.service'
import { isTruthy } from '@util-lib/isTruthy'
import { of } from 'rxjs'
import { filter, switchMap, take } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagGuard {
    constructor(private featureFlags: FeatureFlagsService) {}

    canLoad(route: AppRoute, segments: UrlSegment[]) {
        const routeData: AppRouteData | undefined = route.data
        const featureFlag = routeData?.featureFlag

        return featureFlag
            ? this.getFeatureFlagObservable(featureFlag)
            : of(false)
    }

    canActivate(route: AppActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const routeData: AppRouteData = route.data
        const featureFlag = routeData.featureFlag

        return featureFlag
            ? this.getFeatureFlagObservable(featureFlag)
            : of(false)
    }

    private getFeatureFlagObservable(featureFlag: FeatureFlag<boolean>) {
        return this.featureFlags.ready$.pipe(
            filter(isTruthy),
            switchMap(() => this.featureFlags.getFeatureFlag(featureFlag)),
            take(1)
        )
    }
}
