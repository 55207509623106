import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthzService } from '@auth-util-lib/authz.service'
import { ApiService } from '@env-lib/api/api.service'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class TermsConditionsService {
    private readonly currentContentKey =
        'TERMS_AND_CONDITIONS_PLUS_PRIVACY_POLICY'
    private readonly acceptedConsentKeys = [
        'TERMS_AND_CONDITIONS',
        this.currentContentKey,
    ]
    constructor(
        private api: ApiService,
        private authz: AuthzService,
        private http: HttpClient
    ) {}

    isAccepted$ = this.authz.getClaim$('consents').pipe(
        map((consents) => String(consents)?.split(',') || []),
        map(
            (consents) =>
                consents.filter((consent) =>
                    this.acceptedConsentKeys.includes(consent)
                ).length !== 0
        )
    )

    saveConsent() {
        return this.http
            .patch(`${this.api.auth.backendUrl}/users/consents`, {
                consents: [
                    {
                        consentKey: this.currentContentKey,
                        consented: true,
                        consentedAt: new Date(Date.now()),
                    },
                ],
            })
            .toPromise()
    }
}
