import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Component, Input } from '@angular/core'
import { TranslationService } from '@localization-lib/language/translation.service'

@Component({
    selector: 'app-page',
    styleUrls: ['./page.component.scss'],
    templateUrl: './page.component.html',
})
export class PageComponent {
    _minWidth = '0'
    _horizontalScroll = false
    _hidePageTitle = false
    _hideBreadcrumb = false

    @Input() public loadingWholePage = false

    _loadingPageTitle = false
    @Input()
    set loadingPageTitle(loadingPageTitle: boolean) {
        this._loadingPageTitle = loadingPageTitle
    }
    get loadingPageTitle() {
        return this._loadingPageTitle
    }

    _waitForTranslations = false
    @Input()
    set waitForTranslations(value: boolean) {
        this._waitForTranslations = coerceBooleanProperty(value)
    }
    get waitForTranslations() {
        return this._waitForTranslations
    }

    @Input()
    set minWidth(value: number | null) {
        this._minWidth = `${value}px`
    }
    get minWidth() {
        return parseInt(this._minWidth, 10) || null
    }

    @Input()
    set horizontalScroll(value: boolean) {
        this._horizontalScroll = coerceBooleanProperty(value)
    }
    get horizontalScroll() {
        return this._horizontalScroll
    }

    @Input()
    set hidePageTitle(value: boolean | undefined) {
        this._hidePageTitle = coerceBooleanProperty(value)
    }
    get hidePageTitle() {
        return this._hidePageTitle
    }

    @Input()
    set hideBreadcrumb(value: boolean | undefined) {
        this._hideBreadcrumb = coerceBooleanProperty(value)
    }
    get hideBreadcrumb() {
        return this._hideBreadcrumb
    }

    @Input() public customPageTitle?: string | null

    loadingTranslations$ = this.translationService.loadingTranslations$

    constructor(private translationService: TranslationService) {}
}
