import { Injectable } from '@angular/core'
import { Route, Router, UrlSegment } from '@angular/router'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { map, shareReplay, take, tap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class AuthnGuard {
    private readonly authenticated$ = this.authn.stateChanges().pipe(
        map((authState) => authState === AuthenticationState.Authenticated),
        shareReplay(1)
    )

    constructor(
        private authn: AuthnService,
        private router: Router
    ) {}

    canLoad(route: Route, segments: UrlSegment[]) {
        return this.authenticated$.pipe(
            tap((authenticated) => {
                if (!authenticated) {
                    const returnToPath = segments
                        .map((segment) => segment.path)
                        .join('/')
                    const query = Object.entries({
                        ...(this.router.getCurrentNavigation()?.extractedUrl
                            .queryParams || {}),
                    })
                        .map((keyVal) => keyVal.join('='))
                        .join('&')
                    this.authn.initSignInFlow(
                        `${returnToPath}${(query && '?') || ''}${query || ''}`
                    )
                }
            }),
            take(1)
        )
    }
}
