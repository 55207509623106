<ng-container *ngIf="responseType$ | async as responseType">
    <div class="response-container">
        <mat-icon
            class="icon pb-4"
            [ngClass]="responseType"
            [svgIcon]="icon$ | async"
        ></mat-icon>
        <div class="title text-subtitle-1 pb-2">
            <span
                [translate]="(title$ | async)?.text"
                [params]="(title$ | async)?.parameter"
            ></span>
        </div>
        <div class="text-body-1 pb-4">
            <span
                [translate]="(message$ | async)?.text"
                [params]="(message$ | async)?.parameter"
            ></span>
        </div>
        <div class="content-wrapper">
            <ng-content></ng-content>
        </div>
        <ng-container
            *ngIf="
                (flatPrimaryButtonText$ | async) ||
                (basicPrimaryButtonText$ | async)
            "
        >
            <app-button-group alignment="center">
                <ng-container
                    *ngIf="
                        basicPrimaryButtonText$
                            | async as basicPrimaryButtonText
                    "
                >
                    <app-button
                        buttonType="basic-primary"
                        [status]="basicPrimaryButtonState$ | async"
                        [icon]="basicPrimaryButtonIcon$ | async"
                        [iconPosition]="basicPrimaryButtonIconPosition$ | async"
                        (buttonClick)="handleTextPrimaryButtonClick()"
                        >{{ basicPrimaryButtonText | translate }}</app-button
                    >
                </ng-container>
                <ng-container
                    *ngIf="
                        flatPrimaryButtonText$ | async as flatPrimaryButtonText
                    "
                >
                    <app-button
                        buttonType="flat-primary"
                        [status]="flatPrimaryButtonState$ | async"
                        [icon]="flatPrimaryButtonIcon$ | async"
                        [iconPosition]="flatPrimaryButtonIconPosition$ | async"
                        (buttonClick)="handleFilledPrimaryButtonClick()"
                        >{{ flatPrimaryButtonText | translate }}</app-button
                    >
                </ng-container>
            </app-button-group>
        </ng-container>
    </div>
</ng-container>
