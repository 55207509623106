import { Injectable } from '@angular/core'
import { Router, UrlSegment } from '@angular/router'
import { AuthnGuard } from '@auth-util-lib/guards/authn.guard'
import { AuthzGuard } from '@auth-util-lib/guards/authz.guard'
import { AppRoute } from '@auth-util-lib/routing.model'
import { IncompatibleBrowserGuard } from '@platform-lib/guards/incompatible-browser-guard'
import { CookieGuard } from '@platform-lib/legal/cookie/cookie.guard'
import { TermsConditionsGuard } from '@platform-lib/legal/terms-conditions/terms-conditions.guard'
import { Subject, concat, of } from 'rxjs'
import {
    catchError,
    debounceTime,
    reduce,
    shareReplay,
    tap,
} from 'rxjs/operators'
import { ProfileGuard } from './profile.guard'

@Injectable({
    providedIn: 'root',
})
export class AuthorizedGuard {
    // utility to show loading indicator while AuthorizedGuard:canLoad runs
    private isProcessingSubject = new Subject<boolean>()
    isProcessing$ = this.isProcessingSubject.pipe(
        debounceTime(30),
        shareReplay(1)
    )

    constructor(
        private cookie: CookieGuard,
        private authn: AuthnGuard,
        private authz: AuthzGuard,
        private profileGuard: ProfileGuard,
        private termsConditions: TermsConditionsGuard,
        private incompatibleBrowser: IncompatibleBrowserGuard,
        private router: Router
    ) {
        this.isProcessingSubject.next(false)
    }

    canLoad(route: AppRoute, segments: UrlSegment[]) {
        this.isProcessingSubject.next(true)
        return concat(
            this.cookie.canLoad().pipe(
                tap((acceptedCookies) => {
                    if (!acceptedCookies)
                        throw Error(
                            'User did not yet give a consent to our cookie policy.'
                        )
                })
            ),
            this.incompatibleBrowser.canLoad(route, segments).pipe(
                tap((isValidBrowser) => {
                    if (!isValidBrowser) {
                        throw Error('Invalid browser')
                    }
                })
            ),
            this.authn.canLoad(route, segments).pipe(
                tap((isAuthenticated) => {
                    if (!isAuthenticated)
                        throw Error('User is not authenticated.')
                })
            ),
            this.authz.canLoad(route, segments).pipe(
                tap((isAuthorized) => {
                    if (!isAuthorized) {
                        this.router.navigateByUrl('/unauthorized', {
                            skipLocationChange: true,
                        })
                    }
                })
            ),
            this.profileGuard.canLoad(route, segments).pipe(
                tap((profileLoaded) => {
                    // when profileLoaded it means the guard timed out
                    // before the profile could be loaded, so redirect to logout
                    if (!profileLoaded) {
                        this.router.navigateByUrl('/logout')
                    }
                })
            ),
            this.termsConditions.canLoad().pipe(
                tap((termsAccepted) => {
                    if (!termsAccepted)
                        throw Error(
                            'User did not accept the terms and conditions'
                        )
                })
            )
        ).pipe(
            reduce((allowed, guardResult) => allowed && guardResult, true),
            catchError(() => of(false)),
            tap(() => this.isProcessingSubject.next(false))
        )
    }
}
