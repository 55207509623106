import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { LoadingModule } from '@loading-lib/loading.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ServiceDeskStatusComponent } from '@platform-lib/legal/contact/service-desk-status/service-desk-status.component'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { SectionModule } from '@shared-ui-lib/section/section.module'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'

@NgModule({
    declarations: [ServiceDeskStatusComponent],
    exports: [ServiceDeskStatusComponent],
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        FormsModule,
        LoadingModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        SectionModule,
        TranslationModule,
        SystemResponseModule,
    ],
})
export class ServiceDeskStatusModule {}
