<mat-icon
    (click)="handleToggleClick($event)"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="icon"
    svgIcon="code"
>
</mat-icon>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayHasBackdrop
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    [cdkConnectedOverlayPositions]="[position]"
    (overlayOutsideClick)="handleToggleClick($event)"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
>
    <div class="container">
        <div class="caption">
            Enforce Language
            <span
                class="cursor-pointer"
                (click)="developerToolsService.resetLanguage()"
                >Reset</span
            >
        </div>

        <div
            class="flex items-center justify-between space-x-4 p-2 bg-accent-extra-light"
        >
            <mat-radio-group
                (change)="selectLanguage($event.value)"
                class="space-x-4"
            >
                <mat-radio-button
                    [value]="Language.DE"
                    [checked]="(renderedLanguage$ | async) === Language.DE"
                >
                    German
                </mat-radio-button>
                <mat-radio-button
                    [value]="Language.EN"
                    [checked]="(renderedLanguage$ | async) === Language.EN"
                >
                    English
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="caption">
            Given Actions
            <span
                class="cursor-pointer"
                (click)="developerToolsService.resetActions()"
            >
                Reset
            </span>
        </div>

        <div class="overflow-y-auto max-h-32 p-2 bg-accent-extra-light">
            <mat-checkbox
                class="block"
                color="primary"
                *ngFor="let action of renderedActions$ | async"
                [value]="action.action"
                [checked]="action.checked"
                (change)="toggleAction(action.action)"
            >
                {{ action.label }}
            </mat-checkbox>
        </div>

        <div class="caption">
            Boolean Feature Flags
            <span
                class="cursor-pointer"
                (click)="developerToolsService.resetFeatureFlags()"
                >Reset</span
            >
        </div>

        <div class="overflow-y-auto max-h-32 p-2 bg-accent-extra-light">
            <mat-checkbox
                class="block"
                *ngFor="let data of renderedFeatureFlags$ | async"
                color="primary"
                [value]="data.name"
                [checked]="data.checked"
                (change)="toggleFeatureFlag(data)"
            >
                {{ data.label }}
            </mat-checkbox>
        </div>

        <div class="font-medium mt-4 text-caption uppercase">
            <a routerLink="design-system">Go to design system</a>
        </div>
    </div>
</ng-template>
