import { Action } from '@auth-util-lib/auth.model'
import { MultiLanguageString } from '@localization-lib/language/models/Language'

export interface BannerConfiguration {
    bannerConfigurationId?: string
    bannerText: MultiLanguageString
    buttonText?: MultiLanguageString
    buttonIcon?: string
    published: boolean
    priority: BannerPriority
    closeable: boolean
    action?: Action
    paths: string[]
    dialogToOpen?: string
    routerLink?: string
}

export enum BannerPriority {
    ALERT = 'ALERT',
    WARNING = 'WARNING',
    TEASER = 'TEASER',
    INFO = 'INFO',
}
