import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { StatusType } from '@datadog/browser-logs'
import { ApiService } from '@env-lib/api/api.service'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'
import { LocalStorageService } from '@storage-lib/local-storage.service'
import { Observable, of, throwError, timer } from 'rxjs'
import { catchError, mergeMap, retryWhen } from 'rxjs/operators'
import {
    ConsentSettings,
    CookieConsent,
    CookieConsentId,
} from './cookie.consent.model'

const genericRetryStrategy =
    ({
        maxRetries = 3,
        duration = 1000,
    }: {
        maxRetries?: number
        duration?: number
    } = {}) =>
    (attempts: Observable<unknown>) =>
        attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1
                if (retryAttempt > maxRetries) {
                    return throwError(error)
                }
                return timer(retryAttempt * duration)
            })
        )

@Injectable({
    providedIn: 'root',
})
export class CookieConsentService {
    private readonly storageKey = 'Cookie Consent Settings'
    private readonly cookieConsentVersion = 1

    constructor(
        private api: ApiService,
        private localStorage: LocalStorageService,
        private http: HttpClient,
        private datadogService: DatadogService
    ) {}

    consentGiven(): boolean {
        const settings = this.loadConsentData()

        if (!settings) {
            return false
        }

        const now = new Date(Date.now())
        const expired =
            now.valueOf() > this.getOneYearFrom(settings.consentedAt).valueOf()

        const outdated =
            !settings.version || settings.version < this.cookieConsentVersion

        if (expired) {
            return false
        }

        if (outdated) {
            return false
        }

        // IE11 does not support Object.values(...)
        const consentValues: boolean[] = Object.keys(settings.consents).map(
            (e) => settings.consents[e as CookieConsentId]
        )

        return consentValues.reduce(
            (previous, current) => previous && current,
            true
        )
    }

    saveLocally(consent: CookieConsent) {
        const now = new Date(Date.now())

        const settings: ConsentSettings = {
            consentedAt: now,
            consents: consent,
            version: this.cookieConsentVersion,
        }

        this.localStorage.set(this.storageKey, JSON.stringify(settings))
    }

    private saveToServer(): Observable<void> {
        const consentData = this.loadConsentData()
        if (consentData) {
            return this.http.post<void>(
                `${this.api.auth.backendUrl}/users/cookies`,
                this.loadConsentData()
            )
        } else {
            return throwError(
                'Could not save cookie consent. No consent data found in localstorage.'
            )
        }
    }

    eventuallySaveToServer() {
        this.saveToServer()
            .pipe(
                retryWhen(
                    genericRetryStrategy({
                        maxRetries: 5,
                    })
                ),
                catchError((error) =>
                    of(this.datadogService.log(error, StatusType.warn))
                )
            )
            .subscribe()
    }

    private loadConsentData(): ConsentSettings | null {
        if (!this.localStorage.has(this.storageKey)) {
            return null
        }

        let settings: ConsentSettings | null = null
        try {
            settings = JSON.parse(
                this.localStorage.get(this.storageKey) as string
            )
        } catch (e) {}

        if (!settings || !settings.consents || !settings.consentedAt) {
            return null
        }

        return {
            ...settings,
            consentedAt: new Date(settings.consentedAt),
        }
    }

    private getOneYearFrom(date: Date) {
        const nextDate = new Date(date)
        nextDate.setFullYear(nextDate.getFullYear() + 1)
        return nextDate
    }
}
