import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-page-title',
    styleUrls: ['./page-title.component.scss'],
    templateUrl: './page-title.component.html',
})
export class PageTitleComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute) {}
    showBreadcrumbs?: boolean
    pageTitle = ''
    icon?: string

    _hidePageTitle = false
    _hideBreadcrumb = false
    _loadingPageTitle = false

    @Input() public overwritePageTitle?: string

    @Input()
    set hidePageTitle(value: boolean | undefined) {
        this._hidePageTitle = coerceBooleanProperty(value)
    }
    get hidePageTitle() {
        return this._hidePageTitle
    }

    @Input()
    set hideBreadcrumb(value: boolean | undefined) {
        this._hideBreadcrumb = coerceBooleanProperty(value)
    }
    get hideBreadcrumb() {
        return this._hideBreadcrumb
    }

    @Input()
    set loadingPageTitle(loading: boolean) {
        this._loadingPageTitle = loading
    }
    get loadingPageTitle() {
        return this._loadingPageTitle
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe(({ breadcrumb, title, icon }) => {
            this.pageTitle = title || (!!breadcrumb && breadcrumb.name)
            this.showBreadcrumbs = !this._hideBreadcrumb && !!breadcrumb
            this.icon = icon
        })
    }
}
