import { Component, HostBinding, Input } from '@angular/core'

@Component({
    selector: 'app-skeleton',
    template: `<span class="skeleton__placeholder" aria-label="loading">{{
        placeholder
    }}</span>`,
})
export class SkeletonComponent {
    @Input() placeholder = 'Placeholder'

    @Input()
    set classname(value: string) {
        this.hostClassname = value
    }

    @HostBinding('class') hostClassname!: string
}
