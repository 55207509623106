import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Injectable, OnDestroy } from '@angular/core'
import { SidebarComponent } from '@platform-lib/components/sidebar/sidebar.component'
import { OverlayContentRef } from '@shared-ui-lib/overlay/overlay-content-ref'
import { BehaviorSubject, Subscription, fromEvent, merge } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

interface SidebarConfig {
    belowHeader?: boolean
}

@Injectable({
    providedIn: 'root',
})
export class SidebarService implements OnDestroy {
    private subscriptions = new Subscription()
    private _config?: SidebarConfig | null = null
    private _overlayRef?: OverlayRef | null = null
    private _sidebarOverlayRef?: OverlayContentRef<SidebarComponent> | null =
        null
    private _isSidebarOpen$ = new BehaviorSubject<boolean>(false)

    private getOverlayConfig(config: SidebarConfig): OverlayConfig {
        const positionStrategy = this.overlay
            .position()
            .global()
            .left()
            .bottom()

        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            height: config.belowHeader ? `calc(100% - 56px)` : `100%`,
            width: '368px',
            maxWidth: '100%',
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.block(),
        })

        return overlayConfig
    }

    constructor(private overlay: Overlay) {
        this.subscriptions.add(
            merge(
                fromEvent(window, 'resize'),
                fromEvent(window, 'orientationchange')
            )
                .pipe(debounceTime(100))
                .subscribe(() => {
                    if (this._overlayRef && this._config) {
                        const innerHeight = window.innerHeight

                        this._overlayRef.updateSize({
                            height: this._config.belowHeader
                                ? `calc(${innerHeight}px - 56px)`
                                : `${innerHeight}px`,
                        })
                    }
                })
        )
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    get isSidebarOpen$() {
        return this._isSidebarOpen$.asObservable()
    }

    open(config: SidebarConfig = {}) {
        this._config = config
        this._isSidebarOpen$.next(true)

        this._overlayRef = this.overlay.create(this.getOverlayConfig(config))
        this._sidebarOverlayRef = new OverlayContentRef(this._overlayRef)
        const sidebarPortal = new ComponentPortal(SidebarComponent)

        const sidebarPortalRef = this._overlayRef.attach(sidebarPortal)
        this._overlayRef.backdropClick().subscribe((_) => this.close())

        this._sidebarOverlayRef.componentInstance = sidebarPortalRef.instance
    }

    close() {
        this._config = null
        this._overlayRef = null
        this._sidebarOverlayRef?.close()
        this._sidebarOverlayRef = null
        this._isSidebarOpen$.next(false)
    }

    toggle(config: SidebarConfig = {}) {
        this._sidebarOverlayRef ? this.close() : this.open(config)
    }
}
