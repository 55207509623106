import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonGroupModule } from '../button-group/button-group.module'
import { ButtonModule } from '../button/button.module'
import { SystemResponseComponent } from './system-response.component'

@NgModule({
    declarations: [SystemResponseComponent],
    exports: [SystemResponseComponent],
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        MatIconModule,
        TranslationModule,
    ],
})
export class SystemResponseModule {}
