<form [formGroup]="form">
    <div class="container">
        <app-button
            (buttonClick)="handleCancel()"
            data-test="close-button"
            class="dialog__close-button"
            icon="close"
            iconButton
        >
        </app-button>

        <h4 translate>TermsOfUseAndPivacyPolicy</h4>

        <p translate>BelowYouWillFindOurTermsOfUseAndOurPrivacyPolicyEtc</p>
    </div>
    <div class="content">
        <app-expandable
            [expanded]="showTermsAndConditionsExpandable$ | async"
            (expandedChange)="handleOpen($event, 'termsAndConditions')"
        >
            <div
                expandable-head
                class="expandable-head terms-of-use"
                [ngClass]="{
                    'expandable-head__expanded':
                        showTermsAndConditionsExpandable$ | async,
                }"
            >
                <translate>Terms of Use</translate>
                <mat-icon
                    svgIcon="drop-down"
                    [@flipIcon]="showTermsAndConditionsExpandable$ | async"
                ></mat-icon>
                <mat-checkbox
                    color="primary"
                    formControlName="termsAndConditions"
                    (click)="$event.stopPropagation()"
                >
                    <translate>Allow</translate>
                </mat-checkbox>
            </div>
            <ng-container expandable-body>
                <p translate>
                    SubjectMatterOfTheseTermsOfUseIsTheGrandingOfAccessAndCorrespondingUsageRightsEtc
                </p>
            </ng-container>
        </app-expandable>
        <app-expandable
            [expanded]="showPrivacyPolicyExpandable$ | async"
            (expandedChange)="handleOpen($event, 'privacyPolicy')"
        >
            <div
                expandable-head
                class="expandable-head privacy-policy"
                [ngClass]="{
                    'expandable-head__expanded':
                        showPrivacyPolicyExpandable$ | async,
                }"
            >
                <translate>Privacy Policy</translate>
                <mat-icon
                    svgIcon="drop-down"
                    [@flipIcon]="showPrivacyPolicyExpandable$ | async"
                ></mat-icon>
                <mat-checkbox
                    color="primary"
                    formControlName="privacyPolicy"
                    (click)="$event.stopPropagation()"
                >
                    <translate>Allow</translate>
                </mat-checkbox>
            </div>
            <ng-container expandable-body>
                <p translate>
                    InConnectionWithTheUseOfTraigoCertainPersonalInformationInTheSenseOfEURegulation679/2016(GDPR)areCollectedEtc
                </p>
            </ng-container>
        </app-expandable>
    </div>
    <div class="footer">
        <div class="footer__content">
            <app-button-group>
                <app-button
                    buttonType="basic-primary"
                    [disabled]="form.disabled"
                    (buttonClick)="handleCancel()"
                >
                    <translate>Cancel</translate>
                </app-button>

                <app-button
                    buttonType="flat-primary"
                    (buttonClick)="handleAccept()"
                    [disabled]="form.invalid"
                    [status]="form.disabled ? 'loading' : 'default'"
                >
                    <translate>Accept and continue</translate>
                </app-button>
            </app-button-group>
            <p class="error-message" *ngIf="showError$ | async" translate>
                An error happened. Please contact our service desk or try again
                later.
            </p>
        </div>
    </div>
</form>
