import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    ViewChild,
} from '@angular/core'
import { SidebarService } from '@platform-lib/services/sidebar.service'

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnDestroy {
    /**
     * CSS custom property for viewport height.
     * This is needed since using 100vh might return erratic values on mobile browsers.
     * See: https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
     */
    @HostBinding('style.--layout-height')
    height = `${document.documentElement.clientHeight}px`

    @ViewChild('sidebarContainer')
    sidebarContainer?: ElementRef<HTMLElement>

    @Input()
    useBackgroundImage?: boolean

    @Input()
    customBackgroundColor?: string

    @Input()
    headerClass?: string

    sidebarOpen$ = this.sidebarService.isSidebarOpen$

    private readonly resizeObserver = new ResizeObserver(
        () => (this.height = `${document.documentElement.clientHeight}px`)
    )

    constructor(private sidebarService: SidebarService) {
        this.resizeObserver.observe(document.documentElement)
    }

    ngOnDestroy() {
        this.resizeObserver.disconnect()
    }
}
