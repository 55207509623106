import { animate, state, style, transition, trigger } from '@angular/animations'

export const Animations = {
    fadeIn: trigger('fadeIn', [
        transition('void => *', [
            style({ opacity: 0 }),
            animate('0.2s', style({ opacity: 1 })),
        ]),
        transition('* => void', [
            style({ opacity: 1 }),
            animate('0.2s', style({ opacity: 0 })),
        ]),
    ]),
    flipIcon: trigger('flipIcon', [
        state(
            'true',
            style({
                transform: 'rotateX(180deg)',
            })
        ),
        state(
            'false',
            style({
                transform: 'rotateX(0)',
            })
        ),
        transition('false => true', [
            animate('0.3s', style({ transform: 'rotateX(180deg)' })),
        ]),
        transition('true => false', [
            animate('0.3s', style({ transform: 'rotateX(0)' })),
        ]),
    ]),
    reflectIcon: trigger('reflectIcon', [
        state(
            'true',
            style({
                transform: 'rotateY(180deg)',
            })
        ),
        state(
            'false',
            style({
                transform: 'rotateY(0)',
            })
        ),
        transition('false => true', [
            animate('0.3s', style({ transform: 'rotateY(180deg)' })),
        ]),
        transition('true => false', [
            animate('0.3s', style({ transform: 'rotateY(0)' })),
        ]),
    ]),
    rotateIcon: trigger('rotateIcon', [
        transition('* => *', [
            animate('0.8s', style({ transform: 'rotate(-360deg)' })),
        ]),
    ]),
    overlay: trigger('overlayTrigger', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('250ms', style({ opacity: 1 })),
        ]),
        transition(':leave', [animate('250ms', style({ opacity: 0 }))]),
    ]),
    slideContentLeft: trigger('slideContentLeft', [
        state(
            'void',
            style({
                transform: 'translateX(-100%)',
            })
        ),
        state('enter', style({ transform: 'none' })),
        state('leave', style({ transform: 'translateX(-100%)' })),
        transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
    ]),
    slideContentRight: trigger('slideContentRight', [
        state(
            'void',
            style({
                transform: 'translateX(100%)',
            })
        ),
        state('enter', style({ transform: 'none' })),
        state('leave', style({ transform: 'translateX(100%)' })),
        transition('* => *', animate('500ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
    ]),
}
