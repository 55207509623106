import { DateAdapter } from '@angular/material/core'
import dayjs from '@localization-lib/date-time/dayjs'
import { LanguageService } from '@localization-lib/language/language.service'
import { ConfigType, Dayjs } from 'dayjs'

/**
 * Custom Date-Formats and Adapter (using https://github.com/iamkun/dayjs)
 */

export class MaterialDayjsDateAdapter extends DateAdapter<Dayjs> {
    private localeData!: {
        firstDayOfWeek: number
        longMonths: string[]
        shortMonths: string[]
        dates: string[]
        longDaysOfWeek: string[]
        shortDaysOfWeek: string[]
        narrowDaysOfWeek: string[]
    }

    constructor(private languageService: LanguageService) {
        super()
        this.setLocale(dayjs.locale())

        this.languageService.selectedLanguage$.subscribe((language) => {
            dayjs.locale(language)
            this.setLocale(language)
        })
    }

    getYear(date: Dayjs) {
        return date.year()
    }

    getMonth(date: Dayjs): number {
        return date.month()
    }

    getDate(date: Dayjs): number {
        return date.date()
    }

    getDayOfWeek(date: Dayjs): number {
        return date.day()
    }

    getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
        return style === 'long'
            ? this.localeData.longMonths
            : this.localeData.shortMonths
    }

    getDateNames(): string[] {
        return this.localeData.dates
    }

    getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        if (style === 'long') {
            return this.localeData.longDaysOfWeek
        }
        if (style === 'short') {
            return this.localeData.shortDaysOfWeek
        }
        return this.localeData.narrowDaysOfWeek
    }

    getYearName(date: Dayjs): string {
        return dayjs(date).format('YYYY')
    }

    getFirstDayOfWeek(): number {
        return this.localeData.firstDayOfWeek
    }

    getNumDaysInMonth(date: Dayjs): number {
        return dayjs(date).daysInMonth()
    }

    clone(date: Dayjs): Dayjs {
        return date.clone()
    }

    createDate(year: number, month: number, date: number): Dayjs {
        const returnDayjs = dayjs()
            .set('year', year)
            .set('month', month)
            .set('date', date)
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
        return returnDayjs
    }

    today(): Dayjs {
        return dayjs()
    }

    parse(value: ConfigType, parseFormat: string | string[]) {
        if (this.isDateInstance(value)) {
            return value
        }

        if (!Array.isArray(parseFormat)) {
            return dayjs(value, parseFormat)
        }

        if (typeof value === 'string') {
            const format = parseFormat.find(
                (format) => dayjs(value, format).format(format) === value
            )
            return dayjs(value, format)
        }

        return dayjs(value)
    }

    format(date: Dayjs, displayFormat: string) {
        return dayjs(date).format(displayFormat)
    }

    addCalendarYears(date: Dayjs, years: number): Dayjs {
        return date.add(years, 'year')
    }

    addCalendarMonths(date: Dayjs, months: number): Dayjs {
        return date.add(months, 'month')
    }

    addCalendarDays(date: Dayjs, days: number): Dayjs {
        return date.add(days, 'day')
    }

    toIso8601(date: Dayjs): string {
        return date.toISOString()
    }

    isDateInstance(obj: unknown): obj is Dayjs {
        return dayjs.isDayjs(obj)
    }

    isValid(date: ConfigType): boolean {
        return dayjs(date).isValid()
    }

    invalid(): Dayjs {
        return dayjs('foo')
    }

    deserialize(value: unknown) {
        if (this.isDateInstance(value) && this.isValid(value)) {
            return this.clone(value)
        }

        if (value instanceof Date) {
            return dayjs(value)
        }

        if (typeof value === 'string') {
            const date = dayjs(value)

            if (this.isValid(date)) {
                return date
            }

            return null
        }

        return super.deserialize(value)
    }

    setLocale(locale: string) {
        super.setLocale(locale)

        const dayjsLocaleData = dayjs().localeData()

        this.localeData = {
            firstDayOfWeek: dayjsLocaleData.firstDayOfWeek(),
            longMonths: dayjsLocaleData.months(),
            shortMonths: dayjsLocaleData.monthsShort(),
            dates: new Array(31).fill(0).map((_, i) => `${i + 1}`),
            longDaysOfWeek: new Array(7)
                .fill(0)
                .map((_, i) => dayjs().set('day', i).format('dddd')),
            shortDaysOfWeek: dayjsLocaleData.weekdaysShort(),
            narrowDaysOfWeek: dayjsLocaleData.weekdaysMin(),
        }
    }
}
