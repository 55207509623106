<div class="text-smallheading">
    <translate>Get access to FastTrack now</translate>
</div>
<div mat-dialog-content>
    <p translate>FastTrackAccessDialogText</p>
</div>
<div mat-dialog-actions>
    <app-button-group alignment="right" class="button-group pt-6">
        <app-button
            buttonType="basic-primary"
            [breakLines]="true"
            (buttonClick)="onCloseClick()"
        >
            <translate>Cancel</translate>
        </app-button>

        <app-button
            buttonType="flat-primary"
            [breakLines]="true"
            (buttonClick)="onSendMailClick()"
        >
            <translate>Get access</translate>
        </app-button>
    </app-button-group>
</div>
