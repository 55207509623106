import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IntersectionDirective } from './intersection.directive'
import { IntersectionContextDirective } from './intersection-context.directive'

@NgModule({
    declarations: [IntersectionDirective, IntersectionContextDirective],
    imports: [CommonModule],
    exports: [IntersectionDirective, IntersectionContextDirective],
})
export class IntersectionModule {}
