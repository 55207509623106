import { ErrorType } from '@error-util-lib/error.model'
import { TypedAction } from '@ngrx/store/src/models'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

/**
 * map an observable (normally produced by an HTTP request)
 * to the proper njrx action
 *
 * @param actionIfSuccessful
 * @param actionIfError
 */
export const deferHttpResponseToAction =
    <TResponse>(
        actionIfSuccessful: (props: { response: TResponse }) => {
            response: TResponse
        } & TypedAction<string>,
        actionIfError: (props: { error: ErrorType }) => {
            error: ErrorType
        } & TypedAction<string>
    ) =>
    (request: Observable<TResponse>) =>
        request.pipe(
            map((response) => actionIfSuccessful({ response })),
            catchError((error) => of(actionIfError({ error })))
        )
