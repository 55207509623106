import { NgModule } from '@angular/core'
import { GroupManagementAccessPeriodSelectionComponent } from './group-management-access-period-selection.component'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { ReactiveFormsModule } from '@angular/forms'
import { MatExpansionModule } from '@angular/material/expansion'
import { CommonModule } from '@angular/common'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { ButtonModule } from '@shared-ui-lib/button/button.module'

@NgModule({
    declarations: [GroupManagementAccessPeriodSelectionComponent],
    exports: [GroupManagementAccessPeriodSelectionComponent],
    imports: [
        CommonModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatExpansionModule,
        TranslationModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        ButtonModule,
    ],
})
export class GroupManagementAccessPeriodSelectionModule {}
