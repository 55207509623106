import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'
import {
    BehaviorSubject,
    combineLatest,
    ReplaySubject,
    Subscription,
} from 'rxjs'

@Directive()
export abstract class IntersectionAbstract implements OnInit, OnDestroy {
    protected readonly rootSubject = new ReplaySubject<
        Element | Document | null
    >(1)

    protected readonly rootMarginSubject = new ReplaySubject<string>(1)
    @Input()
    set intersectionRootMargin(value: string) {
        this.rootMarginSubject.next(value)
    }

    protected readonly thresholdSubject = new ReplaySubject<number | number[]>(
        1
    )
    @Input()
    set intersectionThreshold(value: number | number[]) {
        this.thresholdSubject.next(value)
    }

    protected observerSubject =
        new BehaviorSubject<IntersectionObserver | null>(null)

    protected callbackes = new Map<
        Element,
        (entry: IntersectionObserverEntry) => void
    >()

    protected subscription = new Subscription()

    constructor(protected elementRef: ElementRef) {}

    ngOnInit() {
        this.subscription.add(
            combineLatest([
                this.rootSubject,
                this.rootMarginSubject,
                this.thresholdSubject,
            ]).subscribe(([root, rootMargin, threshold]) => {
                if (this.observerSubject.value) {
                    this.observerSubject.value.disconnect()
                }
                this.observerSubject.next(
                    this.makeObserver({ root, rootMargin, threshold })
                )
            })
        )
    }

    ngOnDestroy() {
        if (this.observerSubject.value) {
            this.observerSubject.value.disconnect()
            this.observerSubject.next(null)
        }
    }

    protected makeObserver(config: Partial<IntersectionObserverInit>) {
        if (
            config.root &&
            config.rootMargin &&
            (config.threshold || config.threshold === 0)
        ) {
            return new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const callback = this.callbackes.get(entry.target)
                    if (callback) {
                        callback(entry)
                    }
                })
            }, config)
        }
        return null
    }

    public getObserver() {
        return this.observerSubject.value
    }

    observe(
        target: Element,
        callback: (entry: IntersectionObserverEntry) => void
    ) {
        if (this.observerSubject.value) {
            this.observerSubject.value.observe(target)
            this.callbackes.set(target, callback)
        }
    }

    unobserve(target: Element) {
        if (this.observerSubject.value) {
            this.observerSubject.value.unobserve(target)
            this.callbackes.delete(target)
        }
    }
}
