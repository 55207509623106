<!-- https://css-tricks.com/how-to-create-a-shrinking-header-on-scroll-without-javascript/ -->
<div
    [ngClass]="[
        'h-[88px] z-50',
        'top-bar-wrapper bg-primary-dark',
        'flex flex-row items-center',
    ]"
    [class.scrolled]="isScrolled"
    [class.isScrolledToSticky]="isScrolledToSticky"
>
    <div
        [ngClass]="[
            'sticky top-0',
            'h-12 grow mx-auto py-2',
            'px-10 lg:px-20 2xl:max-w-[1376px]',
        ]"
    >
        <div class="flex justify-between items-center h-full">
            <a [routerLink]="['/']" class="flex">
                <img
                    src="../../../../../assets/images/traigo-logo.svg"
                    class="logo h-10"
                    alt="traigo"
                />
            </a>

            <div class="flex items-center">
                <app-landing-page-language-selector
                    class="mr-8 block"
                ></app-landing-page-language-selector>

                <app-button
                    buttonType="stroked-inverted"
                    (buttonClick)="signIn()"
                >
                    <translate>Login</translate>
                </app-button>
            </div>
        </div>
    </div>
</div>
