<ng-container *ngIf="iconButton$ | async; else buttons">
    <button
        mat-mini-fab
        *ngIf="(buttonType$ | async) === 'flat-primary'"
        class="flat-primary-icon-button"
        [disabled]="disabledOrLoading$ | async"
        [color]="
            (buttonType$ | async) === 'flat-primary'
                ? 'flat-primary'
                : undefined
        "
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
    </button>
    <button
        mat-mini-fab
        *ngIf="(buttonType$ | async) === 'flat-danger'"
        class="flat-danger-icon-button"
        [disabled]="disabledOrLoading$ | async"
        [color]="(buttonType$ | async) === 'flat-danger' ? 'warn' : undefined"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
    </button>
    <button
        mat-mini-fab
        *ngIf="(buttonType$ | async) === 'flat-highlight'"
        class="flat-highlight-icon-button"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
    </button>
    <button
        mat-icon-button
        *ngIf="(buttonType$ | async) === 'raised-basic'"
        class="raised-basic-icon-button"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
    </button>
    <button
        mat-icon-button
        *ngIf="
            (buttonType$ | async) === 'basic-primary' ||
            (buttonType$ | async) === undefined
        "
        class="basic-primary-icon-button"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
    </button>
    <button
        mat-icon-button
        *ngIf="(buttonType$ | async) === 'ghost'"
        class="ghost-icon-button"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
    </button>
    <button
        mat-icon-button
        *ngIf="(buttonType$ | async) === 'basic-inverted'"
        class="basic-inverted-icon-button"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
    </button>
</ng-container>

<ng-template #buttons>
    <button
        *ngIf="
            (buttonType$ | async) === 'raised-basic' ||
            (buttonType$ | async) === undefined
        "
        mat-raised-button
        class="raised-basic-button"
        [class.toggled]="toggled$ | async"
        [class.uppercase]="uppercase$ | async"
        [class.break-lines]="breakLines$ | async"
        [type]="(submit$ | async) ? 'submit' : 'button'"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>

    <button
        *ngIf="(buttonType$ | async) === 'basic-primary'"
        mat-button
        class="basic-primary-button"
        [class.uppercase]="uppercase$ | async"
        [class.break-lines]="breakLines$ | async"
        [type]="(submit$ | async) ? 'submit' : 'button'"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>

    <button
        *ngIf="(buttonType$ | async) === 'basic-highlight'"
        mat-button
        class="basic-highlight-button"
        [class.uppercase]="uppercase$ | async"
        [class.break-lines]="breakLines$ | async"
        [type]="(submit$ | async) ? 'submit' : 'button'"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>

    <button
        *ngIf="(buttonType$ | async) === 'basic-danger'"
        mat-button
        class="basic-danger-button"
        [class.uppercase]="uppercase$ | async"
        [class.break-lines]="breakLines$ | async"
        [type]="(submit$ | async) ? 'submit' : 'button'"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>

    <button
        *ngIf="
            (buttonType$ | async) === 'stroked-inverted' ||
            (buttonType$ | async) === 'stroked-primary'
        "
        mat-stroked-button
        [class.stroked-inverted-button]="
            (buttonType$ | async) === 'stroked-inverted'
        "
        [class.stroked-primary-button]="
            (buttonType$ | async) === 'stroked-primary'
        "
        [class.uppercase]="uppercase$ | async"
        [class.break-lines]="breakLines$ | async"
        [type]="(submit$ | async) ? 'submit' : 'button'"
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>

    <button
        *ngIf="
            (buttonType$ | async) === 'flat-primary' ||
            (buttonType$ | async) === 'flat-danger' ||
            (buttonType$ | async) === 'flat-highlight'
        "
        mat-flat-button
        [class.flat-primary-button]="(buttonType$ | async) === 'flat-primary'"
        [class.flat-highlight-button]="
            (buttonType$ | async) === 'flat-highlight'
        "
        [class.flat-danger-button]="(buttonType$ | async) === 'flat-danger'"
        [class.uppercase]="uppercase$ | async"
        [class.break-lines]="breakLines$ | async"
        [type]="(submit$ | async) ? 'submit' : 'button'"
        [color]="
            (buttonType$ | async) === 'flat-primary'
                ? 'flat-primary'
                : undefined
        "
        [disabled]="disabledOrLoading$ | async"
        (click)="handleClick($event)"
    >
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
</ng-template>

<ng-template #buttonContent>
    <div class="button-content flex">
        <ng-container *ngIf="(iconPosition$ | async) === 'left'">
            <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
        </ng-container>

        <div
            [class.pl-2]="(iconPosition$ | async) === 'left'"
            [class.pr-2]="(iconPosition$ | async) === 'right'"
        >
            <ng-content></ng-content>
        </div>

        <ng-container *ngIf="(iconPosition$ | async) !== 'left'">
            <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #loadingIndicator>
    <ng-container *ngIf="(status$ | async) === 'loading'; else iconContent">
        <app-loading-indicator
            small
            [class.pl-2]="(icon$ | async) === undefined"
        ></app-loading-indicator>
    </ng-container>
</ng-template>

<ng-template #iconContent>
    <ng-container *ngIf="icon$ | async">
        <mat-icon [svgIcon]="icon$ | async" aria-hidden="true"></mat-icon>
    </ng-container>
</ng-template>
