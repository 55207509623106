import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Route, UrlSegment } from '@angular/router'
import { map, take, tap } from 'rxjs/operators'
import { combineLatest, of } from 'rxjs'
import { IncompatibleBrowserService } from '@platform-lib/services/incompatible-browser.service'

@Injectable({
    providedIn: 'root',
})
export class IncompatibleBrowserGuard {
    constructor(
        private incompatibleBrowserService: IncompatibleBrowserService
    ) {}

    canLoad(route: Route, segments: UrlSegment[]) {
        const path = segments.map((segment) => segment.path).join('/')
        return this.checkNavigation(path)
    }

    canActivate(route: ActivatedRouteSnapshot) {
        const path = route.url.map((segment) => segment.path).join('/')
        return this.checkNavigation(path)
    }

    private checkNavigation(path: string) {
        return combineLatest([
            of(this.incompatibleBrowserService.isValidBrowser()),
            of(
                this.incompatibleBrowserService.hasAcceptedIncompatibleBrowser()
            ),
        ]).pipe(
            map(
                ([isValidBrowser, hasAccepted]) => isValidBrowser || hasAccepted
            ),
            tap(
                (canLoad) =>
                    !canLoad &&
                    this.incompatibleBrowserService.redirectToIncompatibleBrowserPage(
                        path
                    )
            ),
            take(1)
        )
    }
}
