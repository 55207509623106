import { Observable } from 'rxjs'

export type ObservableProvider<T> = () => Observable<T>

export const observeIf = <T>(
    condition: boolean,
    ...providers: ObservableProvider<T>[]
): Observable<T>[] => (condition ? providers.map((provider) => provider()) : [])

export const observe = <T>(
    ...providers: ObservableProvider<T>[]
): Observable<T>[] => providers.map((provider) => provider())
