import { Component, Input, HostBinding } from '@angular/core'
import { coerceBooleanProperty } from '@angular/cdk/coercion'

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
    @HostBinding('class.full-width')
    _fullWidth = false

    @Input()
    set fullWidth(fullWidth: boolean) {
        this._fullWidth = coerceBooleanProperty(fullWidth)
    }
    get fullWidth() {
        return this._fullWidth
    }
}
