<div *ngIf="loading$ | async; else errorContainer">
    <app-loading-bar id="loadingbar"></app-loading-bar>
</div>

<ng-template #errorContainer>
    <div *ngIf="error$ | async as error; else statusContainer">
        <app-system-response
            responseType="error"
            class="pb-8"
        ></app-system-response>
    </div>
</ng-template>
<ng-template #statusContainer>
    <ng-container *ngIf="(isEditModeOn$ | async) === false; else editHeader">
        <app-section padding>
            <div class="status-header">
                <mat-icon
                    [svgIcon]="statusIcon$ | async"
                    [class]="statusClass$ | async"
                    class="pt-1"
                ></mat-icon>
                <h5 translate>Service Updates</h5>
                <app-button
                    *ngIf="hasServiceDeskAction$ | async"
                    (buttonClick)="handleEditButtonClick()"
                    class="edit-button"
                    icon="edit"
                    iconButton
                    buttonType="basic-primary"
                >
                </app-button>
            </div>

            <span
                *ngIf="(this.userLanguage$ | async) === this.language.EN"
                class="status-text"
                >{{ statusTextEN$ | async }}</span
            >
            <span
                *ngIf="(this.userLanguage$ | async) === this.language.DE"
                class="status-text"
                >{{ statusTextDE$ | async }}</span
            >
        </app-section>
    </ng-container>

    <ng-template #editHeader>
        <app-section padding>
            <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
                <div class="status-header">
                    <h5 translate>Add new Service Update</h5>
                    <div class="language-select">
                        <mat-form-field appearance="outline">
                            <mat-label translate>Select language</mat-label>
                            <mat-select formControlName="languageControl">
                                <mat-option
                                    *ngFor="let language of availableLanguages"
                                    value="{{ language }}"
                                >
                                    {{ language | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="status-input-container">
                    <mat-form-field
                        class="status-textarea"
                        appearance="outline"
                        [class.hidden]="
                            (this.selectedLanguage$ | async) === language.DE
                        "
                    >
                        <mat-label translate>Message</mat-label>
                        <textarea
                            class="resize-none"
                            matInput
                            #messageEN
                            maxlength="1000"
                            formControlName="englishControl"
                        ></textarea>
                        <mat-hint align="end"
                            >{{ messageEN.value.length }} / 1000
                        </mat-hint>
                    </mat-form-field>
                    <mat-form-field
                        class="status-textarea"
                        appearance="outline"
                        [class.hidden]="
                            (this.selectedLanguage$ | async) === language.EN
                        "
                    >
                        <mat-label translate>Message</mat-label>
                        <textarea
                            matInput
                            #messageDE
                            maxlength="1000"
                            formControlName="germanControl"
                        ></textarea>
                        <mat-hint align="end"
                            >{{ messageDE.value.length }} / 1000
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="status-footer">
                    <mat-form-field appearance="outline" class="w-[300px]">
                        <mat-label translate>Choose status</mat-label>
                        <mat-select formControlName="statusControl">
                            <mat-option
                                *ngFor="let statusOption of availableStatus"
                                [value]="statusOption.status"
                            >
                                <div>
                                    <mat-icon
                                        [svgIcon]="statusOption.icon"
                                        [class]="statusOption.class"
                                    ></mat-icon>
                                    <span>{{ statusOption.status }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-button-group alignment="right" class="button-group">
                        <app-button
                            class="button"
                            (buttonClick)="handleCancelClick()"
                        >
                            <translate>Cancel</translate>
                        </app-button>
                        <app-button
                            class="button"
                            (buttonClick)="handleDefaultButton()"
                        >
                            <translate>Set to default</translate>
                        </app-button>
                        <app-button
                            class="button"
                            buttonType="flat-primary"
                            submit
                            [disabled]="formGroup.invalid || (loading$ | async)"
                            [status]="this.savingStatus ? 'loading' : null"
                        >
                            <translate>Save</translate>
                        </app-button>
                    </app-button-group>
                </div>
            </form>
        </app-section>
    </ng-template>
</ng-template>
