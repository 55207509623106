import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { Unprotected } from '@auth-util-lib/auth.model'
import { AppRoute } from '@auth-util-lib/routing.model'
import { LandingPageFooterResolver } from '@platform-lib/components/footer/landing-page-footer.resolver'
import { CookieGuard } from '@platform-lib/legal/cookie/cookie.guard'
import { CallbackComponent } from './callback/callback.component'
import { LogoutComponent } from './logout/logout.component'
import { UnregisteredComponent } from './unregistered/unregistered.component'

const routes: AppRoute[] = [
    {
        canActivate: [CookieGuard],
        component: CallbackComponent,
        data: {
            customBackgroundColor: '#f6f6f7',
            fullWidth: true,
            hideFooter: true,
            hideHeader: true,
            protection: Unprotected,
        },
        path: 'auth',
    },
    {
        component: LogoutComponent,
        data: {
            fullWidth: true,
            hideHeader: true,
            useImageBackground: true,
            protection: Unprotected,
        },
        resolve: {
            footerComponent: LandingPageFooterResolver,
        },
        path: 'logout',
    },
    {
        component: UnregisteredComponent,
        data: {
            fullWidth: true,
            hideHeader: true,
            useImageBackground: true,
            protection: Unprotected,
        },
        resolve: {
            footerComponent: LandingPageFooterResolver,
        },
        path: 'unregistered',
    },
]

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class AuthRoutingModule {}
