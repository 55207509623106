<img class="logo" src="../../../../assets/images/traigo-logo.svg" />
<div class="content-box" *ngIf="(this.authenticated$ | async) === false">
    <h3 class="content-box__title" translate>Successfully logged out.</h3>
    <p translate>You will be forwarded to the main page</p>
    <div class="content-box__counter text-body-3">
        {{ countdown$ | async }}
        <span class="seconds-label" translate>seconds</span>
    </div>
    <app-button-group alignment="center">
        <app-button
            (buttonClick)="this.navigateToLogoutOrigin()"
            buttonType="flat-primary"
        >
            <translate> Go back to landing page </translate>
        </app-button>
    </app-button-group>
</div>
<div class="content-box" *ngIf="this.authenticated$ | async">
    <h1 class="content-box__title" translate>Logging out ...</h1>
</div>
<div class="contact">
    <p translate>
        You do not have login data yet? Contact our customer service
    </p>
    <a class="contact__mailto" href="mailto:servicedesk@traigo.com"
        >servicedesk&#64;traigo.com</a
    >
</div>
