import { Injectable } from '@angular/core'
import { Stage } from '@env-lib/stage'

@Injectable({
    providedIn: 'root',
})
export class FaviconService {
    adjustFaviconForStage(stage?: Stage) {
        switch (stage) {
            case Stage.Dev:
                this.setLinkAndMetaTags('/assets/favicon/dev', '#e51e34')
                break

            case Stage.Int:
                this.setLinkAndMetaTags('/assets/favicon/int', '#f08727')
                break

            case Stage.Prod:
            default:
                this.setLinkAndMetaTags('/assets/favicon/prod', '#0092A8')
                break
        }
    }

    private setLinkAndMetaTags(path: string, color: string) {
        const query = (s: string) => window.document.querySelector(s)

        const appleTouchIcon = query('link[rel="apple-touch-icon"]')
        appleTouchIcon?.setAttribute('href', `${path}/apple-touch-icon.png`)

        const icon32 = query('link[rel="icon"][sizes="32x32"]')
        icon32?.setAttribute('href', `${path}/favicon-32x32.png`)

        const icon16 = query('link[rel="icon"][sizes="16x16"]')
        icon16?.setAttribute('href', `${path}/favicon-16x16.png`)

        const manifest = query('link[rel="manifest"]')
        manifest?.setAttribute('href', `${path}/site.webmanifest`)

        const maskIcon = query('link[rel="mask-icon"]')
        maskIcon?.setAttribute('href', `${path}/safari-pinned-tab.svg`)
        maskIcon?.setAttribute('color', color)

        const tileColor = query('meta[name="msapplication-TileColor"]')
        tileColor?.setAttribute('content', color)

        const themeColor = query('meta[name="theme-color"]')
        themeColor?.setAttribute('content', color)
    }
}
