import { Stage } from '@env-lib/stage'
import { ApiConfigModel } from './api-config.model'

const baseUrl = 'https://traigo.com'
const authDomain = 'https://traigo-prod.auth.eu-central-1.amazoncognito.com'
const clientId = '7fvpcu8h0di9p8j1nv3h5tgn88'
const cognitoPoolId = 'eu-central-1_14jbKOosQ'
const adLogoutUrl = `https://vtgb2c.b2clogin.com/vtgb2c.onmicrosoft.com/B2C_1_Traigo_SignIn/oauth2/v2.0/logout?post_logout_redirect_uri=${baseUrl}/logout`

export const apiConfig: ApiConfigModel = {
    account: {
        backendUrl: 'https://auth.prod.traigo.com',
    },
    analytics: {
        googleTagManagerId: 'GTM-N4878PS',
        googleTagManagerEnvironment: 'env-8',
        googleTagManagerAuth: 'YLu3kYsnh5JRScZ4dwcZSg',
    },
    assets: { backendUrl: 'https://assets.traigo.com' },
    auth: {
        backendUrl: 'https://auth.prod.traigo.com',
        clientId,
        cognitoLogoutUrl: `${authDomain}/logout?client_id=${clientId}&logout_uri=${adLogoutUrl}`,
        issuer: `https://cognito-idp.eu-central-1.amazonaws.com/${cognitoPoolId}`,
        loginUrl: `${authDomain}/oauth2/authorize`,
        redirectUrl: `${baseUrl}/auth`,
        tokenEndpoint: `${authDomain}/oauth2/token`,
    },
    booking: {
        backendUrl: 'https://use-case-booking.prod.traigo.com',
    },
    connectEvent: {
        backendUrl: 'https://connect-event.prod.traigo.com',
    },
    contracts: {
        backendUrl: 'https://contracts.prod.traigo.com',
    },
    dashboard: {
        backendUrl: 'https://dashboard.prod.traigo.com',
    },
    dataForwarding: {
        backendUrl: 'https://message-relay.prod.traigo.com',
    },
    dispatch: {
        backendUrl: 'https://dispatch.prod.traigo.com',
    },
    fastTrack: {
        backendUrl: 'https://fasttrack.prod.traigo.com',
    },
    fleet: {
        backendUrl: 'https://fleet.prod.traigo.com',
    },
    performance: {
        backendUrl: 'https://performance.prod.traigo.com',
    },
    salesCare: {
        backendUrl: 'https://sales-care.prod.traigo.com',
    },
    launchDarkly: {
        clientSideId: '5e5e66979e25d309ca9fde68',
        useStreaming: true,
    },
    releasenotes: {
        backendUrl: 'https://releasenotes.prod.traigo.com',
    },
    datadog: {
        environmentName: Stage.Prod,
    },
    serviceDesk: {
        backendUrl: 'https://servicedesk.prod.traigo.com',
    },
    transport: {
        backendUrl: 'https://transport.prod.traigo.com',
    },
    e2eEta: {
        backendUrl: 'https://e2e-eta.prod.traigo.com',
    },
    userInterest: {
        backendUrl: 'https://user-interest.prod.traigo.com',
    },
    wagon: {
        backendUrl: 'https://wagon.prod.traigo.com',
    },
    pathfinder: {
        backendUrl: 'https://pathfinder.prod.traigo.com',
    },
    virtuwall: {
        backendUrl: 'https://virtuwall.prod.traigo.com',
    },
    wagonHire: {
        backendUrl: 'https://wagon-rental.prod.traigo.com',
    },
    userTracking: {
        backendUrl: 'https://user-tracking.prod.traigo.com',
    },
    notification: {
        backendUrl: 'https://notification.prod.traigo.com',
    },
    sensor: {
        backendUrl: 'https://sensor.prod.traigo.com',
    },
}
