<div
    class="flex items-start justify-start pr-6"
    [class.clickable]="!!notification?.path"
    [class.opacity-50]="deleting"
    [attr.disabled]="deleting"
    [routerLink]="[notificationLink.path]"
    [queryParams]="notificationLink.params"
    aria-label="notification"
    (click)="onClick($event)"
    trackClick
    [trackCategory]="AnalyticsCategory.Notification"
    [trackAction]="AnalyticsNotificationAction.ClickNotification"
    [trackLabel]="maskedNotificationPath"
>
    <div class="pl-6 pt-5">
        <span
            class="h-2 w-2 block rounded-full"
            [class.bg-caution]="notification?.isRead === false"
            [attr.aria-label]="notification?.isRead ? 'read' : 'unread'"
        ></span>
    </div>
    <div class="pl-2 pt-5">
        <div class="h-8 w-8">
            <mat-icon
                *ngIf="useCaseIcon; else iconSkeleton"
                [svgIcon]="useCaseIcon"
                class="h-8 w-8"
                aria-hidden
            ></mat-icon>
            <ng-template #iconSkeleton>
                <app-skeleton
                    *ngIf="!notification?.id"
                    placeholder="..."
                    class="icon-skeleton"
                ></app-skeleton>
            </ng-template>
        </div>
    </div>
    <div class="pl-2 pt-3 flex-grow">
        <div
            *appSkeleton="!notification?.id; classname: 'block'"
            class="flex items-center text-accent-medium text-caption"
        >
            <div>{{ notification?.useCase + '_NOTIFICATION' | translate }}</div>
            <div class="pl-2">
                <span
                    class="priority"
                    [class.text-caution]="
                        notification?.priority === NotificationPriority.Alert
                    "
                    [class.text-primary]="
                        notification?.priority === NotificationPriority.Warn
                    "
                    [class.text-accent-medium]="
                        notification?.priority === NotificationPriority.Info
                    "
                    aria-label="priority"
                >
                    {{ notification?.priority | translate }}
                </span>
            </div>
            <div class="pl-2 pr-6 flex-grow flex justify-end">
                <div class="actions as-normal">
                    <span
                        class="timestamp"
                        [class.mr-8]="!notification?.isMarked"
                        aria-label="timestamp"
                    >
                        {{ 'As of:' | translate }}
                        {{
                            notification?.occurredAt
                                | dateInDefaultTimezone: 'DD.MM.YYYY, HH:mm'
                        }}
                    </span>
                    <mat-icon
                        *ngIf="notification?.isMarked"
                        svgIcon="flag-filled"
                        class="action as-bookmark"
                        [class.opacity-50]="disableDelete"
                        aria-label="bookmarked"
                    ></mat-icon>
                </div>
                <div class="actions as-hover">
                    <mat-icon
                        svgIcon="delete-outline"
                        class="action as-remove"
                        [class.opacity-50]="disableDelete"
                        aria-label="remove"
                        (click)="onDeleteClick($event)"
                        trackClick
                        [trackCategory]="AnalyticsCategory.Notification"
                        [trackAction]="
                            AnalyticsNotificationAction.ClickRemoveNotification
                        "
                    ></mat-icon>
                    <mat-icon
                        [svgIcon]="
                            notification?.isMarked
                                ? 'flag-filled'
                                : 'flag-outline'
                        "
                        class="action as-bookmark"
                        [class.opacity-50]="disableBookmark"
                        [attr.aria-label]="
                            notification?.isMarked ? 'unbookmark' : 'bookmark'
                        "
                        (click)="onBookmarkClick($event)"
                        trackClick
                        [trackCategory]="AnalyticsCategory.Notification"
                        [trackAction]="
                            notification?.isMarked
                                ? AnalyticsNotificationAction.ClickBookmarkOff
                                : AnalyticsNotificationAction.ClickBookmarkOn
                        "
                    ></mat-icon>
                </div>
            </div>
        </div>
        <div
            *appSkeleton="!notification?.id; classname: 'block mb-4 mt-2'"
            class="mb-4 leading-6 line-clamp-2 overflow-hidden"
            [matTooltip]="
                notification?.text?.length > 100 ? notification?.text : null
            "
            matTooltipPosition="below"
            aria-label="notification content"
        >
            {{ notification?.text }}
        </div>
    </div>
</div>
