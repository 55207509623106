import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { ExpandableModule } from '@shared-ui-lib/expandable/expandable.module'
import { CookieDialogComponent } from './cookie-dialog/cookie-dialog.component'
import { ExpandableCookieComponent } from './expandable-cookie/expandable-cookie.component'

@NgModule({
    declarations: [CookieDialogComponent, ExpandableCookieComponent],
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        ExpandableModule,
        FormsModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        TranslationModule,
    ],
})
export class CookieModule {}
