import { Translator } from '@localization-lib/language/models/Translator'
import {
    MenuEntry,
    MenuEntryWithTranslatedLabel,
} from '@shared-util-lib/models/menu-entry.model'

export const translateEntries =
    (translator: Translator) => (entries: MenuEntry[]) =>
        entries.map(
            (entry): MenuEntryWithTranslatedLabel => ({
                ...entry,
                translatedLabel: translator(entry.label),
            })
        )
