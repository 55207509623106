import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ErrorPanelComponent } from './error-panel.component'

@NgModule({
    declarations: [ErrorPanelComponent],
    exports: [ErrorPanelComponent],
    imports: [CommonModule, MatIconModule, TranslationModule],
})
export class ErrorPanelModule {}
