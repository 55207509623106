export const FLEET_FORCE_USE_CASES = [
    'idle-times',
    'fleet',
    'wagon-tracking',
    '/wagon/',
]
export const COMBO_USE_CASES = [
    'contracts',
    'fast-track',
    'account',
    'wagon-hire',
    'booking',
    'traigo-updates',
]
export const PLATFORM_PROPHETS_USE_CASES = [
    'group-management',
    'dispatch-management',
    'eta',
    'contact',
    'sales-care',
    'dashboard',
    'user-management',
    'client-management',
    'account',
]
