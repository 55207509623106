import { HttpErrorResponse } from '@angular/common/http'

export type ErrorType =
    | string
    | Error
    | HttpErrorResponse
    | ErrorWithOriginalError

export interface ErrorWithOriginalError extends Error {
    originalError: ErrorType
}

export const isError = (error: ErrorType): error is Error =>
    error instanceof Error

export const isErrorWithOriginalError = (
    error: ErrorType
): error is ErrorWithOriginalError => isError(error) && 'originalError' in error

export const isHttpErrorResponse = (
    error: ErrorType
): error is HttpErrorResponse => error instanceof HttpErrorResponse
