import { Injectable } from '@angular/core'
import { CookieService } from '@storage-lib/cookie.service'
import { SessionStorageService } from '@storage-lib/session-storage.service'
import { OAuthStorage } from 'angular-oauth2-oidc'

@Injectable({
    providedIn: 'root',
})
export class AuthStorageService implements OAuthStorage {
    constructor(
        private cookieService: CookieService,
        private storage: SessionStorageService
    ) {}

    getItem(key: string): string | null {
        let value: string | null = null
        if (key === 'nonce' || key === 'PKCI_verifier') {
            value = this.cookieService.get(key)
        } else {
            value = this.storage.get(key)
        }
        return value
    }

    removeItem(key: string): void {
        this.storage.delete(key)
    }

    setItem(key: string, data: string): void {
        if (key === 'nonce' || key === 'PKCI_verifier') {
            this.cookieService.set(key, data)
        } else {
            this.storage.set(key, data)
        }
    }
}
