<app-expandable
    *ngIf="consentId"
    [expanded]="consentId === expandedId"
    (expandedChange)="handleExpandedChange($event)"
>
    <div
        expandable-head
        class="expandable-head cookie__head"
        [ngClass]="{
            'cookie__head--expanded': expandedId === consentId,
        }"
    >
        <ng-content select=".expandable-cookie-title"></ng-content>
        <mat-icon
            class="flex-shrink-0"
            svgIcon="drop-down"
            [@flipIcon]="expandedId === consentId"
        ></mat-icon>
        <ng-container *ngIf="cookiesAllowed && consentId">
            <mat-checkbox
                color="primary"
                (click)="handleCheckbox($event)"
                [(ngModel)]="cookiesAllowed[consentId]"
                ><translate>AllowCookie</translate></mat-checkbox
            >
        </ng-container>
    </div>
    <div expandable-body class="expandable-body">
        <ng-content select=".expandable-cookie-description"></ng-content>
    </div>
</app-expandable>
