import { ErrorType } from '@error-util-lib/error.model'
import {
    Colleague,
    GroupMetaData,
    Groups,
    GroupSelection,
    ParsedAccessPeriod,
    Wagon,
    WagonId,
} from '@group-management-lib/group-management.model'
import { createAction, props } from '@ngrx/store'

// #######################
// General
// #######################

export const setGroupsFromToken = createAction(
    '[GroupManagement] set groups from token',
    props<{
        groupsFromToken: string[] | null
    }>()
)

export const loadGroupsFromBackend = createAction(
    '[GroupManagement] load groups from backend'
)

export const loadGroupsFromBackendSuccess = createAction(
    '[GroupManagement] load groups from backend success',
    props<{ groupsFromBackend: GroupMetaData[] | null }>()
)

export const loadColleaguesSuccess = createAction(
    '[GroupManagement] load colleagues from backend',
    props<{ colleagues: Colleague[] }>()
)

export const loadGroupsFromBackendError = createAction(
    '[GroupManagement] load groups from backend error',
    props<{ error: ErrorType | null }>()
)

export const refreshBackend = createAction(
    '[GroupManagement] refresh backend',
    props<{ refresh: boolean }>()
)

export const triggerDataReload = createAction(
    '[GroupManagement] reload data after change in selection or groups'
)

// #######################
// Group Management Page
// #######################

export const loadGroupsWithResources = createAction(
    '[GroupManagement] load groups with resources'
)

export const loadGroupsWithResourcesSuccess = createAction(
    '[GroupManagement] load groups with resources success',
    props<{ groupsWithResources: Groups[] | null }>()
)

export const loadGroupsWithResourcesError = createAction(
    '[GroupManagement] load groups with resources error',
    props<{ error: ErrorType | null }>()
)

// #######################
// Dropdown / Header
// #######################

export const changeGroupSelection = createAction(
    '[GroupManagement] change group selection',
    props<{ groupSelection: GroupSelection[] }>()
)

export const changeGroupSelectionSuccess = createAction(
    '[GroupManagement] change group selection success'
)

export const changeGroupSelectionError = createAction(
    '[GroupManagement] change group selection error',
    props<{ error: ErrorType | null }>()
)

// #######################
// General Group Overlay
// #######################

export const loadPossibleWagonsForUser = createAction(
    '[GroupManagement] load possible wagons for user from backend'
)

export const loadPossibleWagonsForUserSuccess = createAction(
    '[GroupManagement] load possible wagons for user from backend success',
    props<{ possibleWagonsForUser: Wagon[] | null }>()
)

export const loadPossibleWagonsForUserError = createAction(
    '[GroupManagement] load possible wagons for user from backend error',
    props<{ error: ErrorType | null }>()
)

export const changeSelectedWagonsForGroup = createAction(
    '[GroupManagement] Change selected wagons for group',
    props<{
        groupId: string
        selectedWagons: WagonId[]
    }>()
)

export const changeSelectedWagonsForGroupSuccess = createAction(
    '[GroupManagement] Change selected wagons for group success'
)

export const changeSelectedWagonsForGroupError = createAction(
    '[GroupManagement] Change selected wagons for group error',
    props<{ error: ErrorType | null }>()
)

export const changeMembersOfGroup = createAction(
    '[GroupManagement] Change members of group',
    props<{
        groupId: string
        selectedUserIds: string[]
    }>()
)

export const changeMembersOfGroupSuccess = createAction(
    '[GroupManagement] Change members of group success'
)

export const changeMembersOfGroupError = createAction(
    '[GroupManagement] Change members of group error',
    props<{ error: ErrorType | null }>()
)

export const createGroup = createAction(
    '[GroupManagement] Create group',
    props<{
        groupName: string
        selectedWagons: WagonId[]
        selectedUserIds: string[]
        selectedAccessPeriod: ParsedAccessPeriod
        isExtendedGroupManagementAllowed: boolean
    }>()
)

export const createGroupSuccess = createAction(
    '[GroupManagement] Create group success'
)

export const createGroupError = createAction(
    '[GroupManagement] Create group error',
    props<{ error: ErrorType | null }>()
)

// #######################
// Group Editor
// #######################

export const setGroupIdToEdit = createAction(
    '[GroupManagement] set group id to edit',
    props<{
        groupIdToEdit: string | null
    }>()
)

export const loadWagonsForSelectedGroup = createAction(
    '[GroupManagement] Load wagons for selected group',
    props<{ groupId: string }>()
)

export const loadWagonsForSelectedGroupSuccess = createAction(
    '[GroupManagement] Load wagons for selected group success',
    props<{ selectedWagonsForGroup: Wagon[] | null }>()
)

export const loadWagonsForSelectedGroupError = createAction(
    '[GroupManagement] Load wagons for selected group error',
    props<{ error: ErrorType | null }>()
)

export const editGroup = createAction(
    '[GroupManagement] Change group',
    props<{
        groupId: string
        groupName: string
        selectedWagons: WagonId[]
        selectedUserIds: string[]
        selectedAccessPeriod: ParsedAccessPeriod
        isDefaultGroup: boolean
        isExtendedGroupManagementAllowed: boolean
    }>()
)

export const editGroupSuccess = createAction(
    '[GroupManagement] Change group success'
)

export const displayedEditGroupSnackBar = createAction(
    '[GroupManagement] Displayed edit group success'
)

export const editGroupError = createAction(
    '[GroupManagement] Change group error',
    props<{ error: ErrorType | null }>()
)

export const deleteGroup = createAction(
    '[GroupManagement] Delete group',
    props<{ groupId: string }>()
)

export const deleteGroupSuccess = createAction(
    '[GroupManagement] Delete group success'
)

export const deleteGroupError = createAction(
    '[GroupManagement] Delete group error',
    props<{ error: ErrorType }>()
)
