import { createReducer, on } from '@ngrx/store'
import { MAX_NOTIFICATION_CHUNK_SIZE } from '@platform-lib/notification/notification.model'
import { notificationEntryAdapter } from '@platform-lib/notification/redux/notification.adapter'
import * as notificationActions from './notification.actions'
import { initialNotificationState } from './notification.state'

export const notificationReducer = createReducer(
    initialNotificationState,

    // count loading
    on(notificationActions.loadCountSuccess, (state, { response }) => ({
        ...state,
        // without the check and fallback,some testcafe test fail
        // when the notification count endpoint isn't mocked
        count:
            response && response.hasOwnProperty('unreadCount')
                ? response?.unreadCount || 0
                : state.count,
    })),

    // entry list loading
    on(notificationActions.loadEntries, (state) => ({
        ...state,
        entriesLoading: true,
        entriesLoadingError: null,
    })),

    on(notificationActions.loadEntriesSuccess, (state, { response }) => ({
        ...state,
        entries: notificationEntryAdapter.upsertMany(
            response.notifications,
            state.entries
        ),
        entriesAvailable: response.hasMoreNotifications,
        entriesLoading: false,
        entriesLoadingError: null,
    })),

    on(notificationActions.loadEntriesError, (state, { error }) => ({
        ...state,
        entriesLoading: false,
        entriesLoadingError: error,
    })),

    // chunk loading
    on(notificationActions.loadChunk, (state) => ({
        ...state,
        chunkLoading: true,
        chunkLoadingError: null,
    })),

    on(notificationActions.loadChunkSuccess, (state, { response }) => ({
        ...state,
        entries: notificationEntryAdapter.upsertMany(
            response.notifications,
            state.entries
        ),
        entriesAvailable: response.hasMoreNotifications,
        chunkLoading: false,
        chunkLoadingError: null,
        chunkEntriesLoaded:
            state.chunkEntriesLoaded + MAX_NOTIFICATION_CHUNK_SIZE,
    })),

    on(notificationActions.loadChunkError, (state, { error }) => ({
        ...state,
        chunkLoading: false,
        chunkLoadingError: error,
    })),

    // filtering
    on(notificationActions.setFilterValues, (state, { filterValues }) => ({
        ...state,
        filterValues,
    })),

    // remove entries
    on(notificationActions.removeEntries, (state, { request }) => ({
        ...state,
        removeEntriesParams: { ...request },
    })),

    on(notificationActions.removeEntriesSuccess, (state, { response }) => ({
        ...state,
        entries: notificationEntryAdapter.removeMany(
            response.notificationIds,
            state.entries
        ),
        removeEntriesParams: null,
    })),

    on(notificationActions.removeEntriesError, (state) => ({
        ...state,
        removeEntriesParams: null,
    })),

    // set isMarked flag for entries
    on(notificationActions.setIsMarked, (state, { request }) => ({
        ...state,
        setIsMarkedParams: { ...request },
    })),

    on(notificationActions.setIsMarkedSuccess, (state, { response }) => ({
        ...state,
        entries: notificationEntryAdapter.updateMany(
            response.notificationIds.map((id) => ({
                id,
                changes: { isMarked: response.value },
            })),
            state.entries
        ),
        setIsMarkedParams: null,
    })),

    on(notificationActions.setIsMarkedError, (state) => ({
        ...state,
        setIsMarkedParams: null,
    })),

    // set isRead flag for entries
    on(notificationActions.setIsRead, (state, { request }) => ({
        ...state,
        setIsReadParams: { ...request },
    })),

    on(notificationActions.setIsReadSuccess, (state, { response }) => ({
        ...state,
        entries: notificationEntryAdapter.updateMany(
            response.notificationIds.map((id) => ({
                id,
                changes: { isRead: response.value },
            })),
            state.entries
        ),
        setIsReadParams: null,
    })),

    on(notificationActions.setIsReadError, (state) => ({
        ...state,
        setIsReadParams: null,
    }))
)
