import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'
import { TranslationParameters } from '@localization-lib/language/models/TranslationParameters'
import { TranslationService } from '@localization-lib/language/translation.service'
import { convertElementToTranslationString } from '@localization-lib/language/utils/convertElementToTranslationString'
import { BehaviorSubject, Subscription, combineLatest, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

@Component({
    selector: 'translate', // eslint-disable-line @angular-eslint/component-selector
    templateUrl: './translation.component.html',
})
export class TranslationComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription()
    private originalContent?: string

    params$ = new BehaviorSubject<TranslationParameters | null>(null)
    @Input()
    set params(params: TranslationParameters | undefined) {
        this.params$.next(params ?? null)
    }

    translationArgument$ = new BehaviorSubject<string | null>(null)
    @Input()
    set translate(translationArgument: string) {
        this.translationArgument$.next(translationArgument)
    }

    constructor(
        private ref: ElementRef<HTMLElement>,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.originalContent = convertElementToTranslationString(
            this.ref.nativeElement
        )

        const translationContent =
            this.originalContent && typeof this.originalContent === 'string'
                ? (this.originalContent as string)?.trim()
                : null

        this.subscriptions.add(
            combineLatest([
                this.params$,
                this.translationArgument$.pipe(map((key) => key && key.trim())),
            ])
                .pipe(
                    switchMap(([params, translationArgument]) => {
                        const key = translationArgument || translationContent

                        if (!key) {
                            return of(null)
                        }

                        return this.translationService.getTranslation$(
                            key,
                            params || undefined
                        )
                    })
                )
                .subscribe((translation) => {
                    if (!translation) {
                        return
                    }

                    this.ref.nativeElement.innerHTML = translation
                })
        )
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
