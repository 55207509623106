import { NgModule } from '@angular/core'
import { ApiService } from '@env-lib/api/api.service'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'

/**
 * Utility module to import GoogleTagManagerModule and provide GTM tokens throught API service
 */
@NgModule({
    imports: [GoogleTagManagerModule.forRoot()],
    providers: [
        {
            provide: 'googleTagManagerId',
            useFactory: (api: ApiService) => api.analytics.googleTagManagerId,
            deps: [ApiService],
        },
        {
            provide: 'googleTagManagerAuth',
            useFactory: (api: ApiService) => api.analytics.googleTagManagerAuth,
            deps: [ApiService],
        },
        {
            provide: 'googleTagManagerPreview',
            useFactory: (api: ApiService) =>
                api.analytics.googleTagManagerEnvironment,
            deps: [ApiService],
        },
    ],
})
export class GtmModule {}
