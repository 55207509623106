import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { GroupManagementCreatorModule } from '../group-management-creator/group-management-creator.module'
import { GroupManagementEditorModule } from '../group-management-editor/group-management-editor.module'
import { GroupManagementDropdownComponent } from './group-management-dropdown.component'
import { MatTooltipModule } from '@angular/material/tooltip'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'
import { DataQaTagPipeModule } from '@shared-util-lib/pipes/dataQaTag/data-qa-tag-pipe.module'

@NgModule({
    declarations: [GroupManagementDropdownComponent],
    exports: [GroupManagementDropdownComponent],
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        DataQaTagPipeModule,
        GroupManagementCreatorModule,
        GroupManagementEditorModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslationModule,
        SystemResponseModule,
    ],
})
export class GroupManagementDropdownModule {}
