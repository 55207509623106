<img class="logo" src="../../../../assets/images/traigo-logo.svg" />
<div class="content-box">
    <h3 class="content-box__title">Improve your traigo experience</h3>
    <p>
        To ensure you have the best possible experience using traigo, we
        recommend you use a desktop and install the latest version of one of the
        following supported browsers:
    </p>
    <div class="browser-row">
        <div class="browser-logo-wrapper">
            <div class="browser-logo chrome"></div>
            <span class="browser-name">Chrome</span>
        </div>

        <div class="browser-logo-wrapper">
            <div class="browser-logo edge"></div>
            <span class="browser-name">Edge</span>
        </div>

        <div class="browser-logo-wrapper">
            <div class="browser-logo firefox"></div>
            <span class="browser-name">Firefox</span>
        </div>

        <div class="browser-logo-wrapper">
            <div class="browser-logo safari"></div>
            <span class="browser-name">Safari</span>
        </div>
    </div>

    <div class="continue-link-wrapper">
        <a href="#" class="continue-link" (click)="handleContinueClick($event)"
            >Continue with my current browser</a
        >
    </div>
</div>
<div class="contact">
    <p>You have more questions? Contact our Service Desk:</p>
    <a class="contact__mailto" href="mailto:servicedesk@traigo.com"
        >servicedesk&#64;traigo.com</a
    >
</div>
