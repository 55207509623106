import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Component, HostBinding, Input } from '@angular/core'

@Component({
    selector: 'app-section',
    template: '<ng-content></ng-content>',
    styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
    @HostBinding('class.padding')
    private _padding = false

    @Input()
    set padding(padding: boolean) {
        this._padding = coerceBooleanProperty(padding)
    }
    get padding() {
        return this._padding
    }

    @HostBinding('class.flat')
    private _flat = false

    @Input()
    set flat(flat: boolean) {
        this._flat = coerceBooleanProperty(flat)
    }
    get flat() {
        return this._flat
    }
}
