<ng-container>
    <div
        class="flex flex-row justify-between px-8 box-border items-center flex-1"
    >
        <div class="flex flex-row gap-4 items-center">
            <mat-icon
                [class]="iconTextColor"
                class="shrink-0"
                [svgIcon]="icon"
            ></mat-icon>
            <span class="text-left py-4">
                {{ bannerText$ | async }}
            </span>
        </div>
        <div class="flex flex-row gap-2 shrink-0">
            <app-button
                class="shrink-0"
                *ngIf="buttonText$ | async as buttonText"
                buttonType="basic-primary"
                [icon]="buttonIcon"
                iconPosition="right"
                (buttonClick)="handleMoreClick()"
            >
                <span>{{ buttonText }}</span> </app-button
            ><app-button
                *ngIf="closeable"
                class="self-center"
                icon="close"
                iconButton
                buttonType="ghost"
                (buttonClick)="handleCloseClick()"
            ></app-button>
        </div>
    </div>
</ng-container>
