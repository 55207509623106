import { Injectable, NgZone } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    static readonly SITE_DATA_SUPPORT_REQUIRED_MESSAGE =
        'Site data (cookies, local and session-storage) must not be disabled for traigo.'

    private static readonly MAX_COOKIE_DATE = new Date(
        '2038-01-01T01:01:01.345'
    )

    constructor(
        private readonly snackBar: MatSnackBar,
        private readonly zone: NgZone
    ) {}

    isEnabled(): boolean {
        if (!navigator.cookieEnabled) {
            return false
        }

        // needed if cookies were disabled while page was already loaded
        const testcookie = 'testcookie'
        const timestamp = new Date().toISOString()
        this.setUnchecked(testcookie, timestamp, CookieService.MAX_COOKIE_DATE)
        const cookiesActuallyEnabled =
            document.cookie.indexOf('testcookie' + '=' + timestamp) >= 0
        this.deleteUnchecked(testcookie)
        return cookiesActuallyEnabled
    }

    ensureEnabled() {
        if (!this.isEnabled()) {
            throw new Error(CookieService.SITE_DATA_SUPPORT_REQUIRED_MESSAGE)
        }
    }

    handleSiteDataRequiredError(message: string): boolean {
        if (
            message.indexOf(CookieService.SITE_DATA_SUPPORT_REQUIRED_MESSAGE) <
            0
        ) {
            return false
        }

        // needed for proper positioning of snackbar
        this.zone.run(() => {
            this.snackBar
                .open(
                    'The traigo application needs cookies to be enabled.',
                    'Reload',
                    {
                        panelClass: ['snackbar', 'snackbar--error'],
                    }
                )
                .onAction()
                .subscribe(() => {
                    location.reload()
                })
        })
        return true
    }

    get(key: string): string {
        if (!this.isEnabled()) {
            return ''
        }
        const cookie = document.cookie
        const map = cookie.split(';').map((value) => value.split('='))
        const result = map.find((value: string[]) => value[0].trim() === key)
        return (result && decodeURIComponent(result[1])) || ''
    }

    set(key: string, data: string): void {
        this.ensureEnabled()
        this.setUnchecked(key, encodeURIComponent(data))
    }

    private setUnchecked(key: string, data: string, expires?: Date): void {
        if (expires) {
            document.cookie = `${key}=${data}; expires=${expires.toUTCString()}; path=/; SameSite=Lax;`
        } else {
            document.cookie = `${key}=${data}; path=/; SameSite=Lax;`
        }
    }

    private deleteUnchecked(key: string): void {
        document.cookie = `${key}=; expires=Thu, 01-Jan-1970 00:00:01 GMT`
    }
}
