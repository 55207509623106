import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { BackToTopComponent } from '@shared-ui-lib/back-to-top/back-to-top.component'
import { ButtonModule } from '@shared-ui-lib/button/button.module'

@NgModule({
    declarations: [BackToTopComponent],
    exports: [BackToTopComponent],
    imports: [CommonModule, TranslationModule, ButtonModule],
})
export class BackToTopModule {}
