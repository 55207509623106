export interface MyGroupsMetadataResponse {
    groups: GroupMetaData[]
}

export interface MyColleaguesResponse {
    users: Colleague[]
}

export interface Colleague {
    id: string
    firstName: string
    lastName: string
    email: string
    isAdmin: boolean
}

export interface UserSelection {
    userId: string
    firstName: string
    lastName: string
    isMember: boolean
    isAdmin: boolean
}

export interface MinimalGroup {
    id: string
    name: string
    isDefault: boolean
}

export interface GroupMetaData {
    id: string
    name: string
    isSelected: boolean
    isDefault: boolean
}

export interface MyGroupsWithResourcesResponse {
    groups: Groups[]
}

export interface CreateGroupRequest {
    name: string
}

export interface EditGroupRequest {
    name: string
}

export interface PossibleWagonsResponse {
    wagons: Wagon[]
}

export interface Wagon {
    wagonId: string
    wagonNumber: string
    uicClass: string
}

export interface SelectedWagon extends Wagon {
    hasError: boolean
    isLoading: boolean
    isInEditMode: boolean
}

export interface WagonId {
    id: string
}

export interface UserId {
    id: string
}

export interface ChangeWagonSelectionRequest {
    wagons: WagonId[]
}

export interface SelectedWagonsResponse {
    wagons: Wagon[]
}

export interface ChangeMembersRequest {
    members: UserId[]
}

export interface ChangeAccessPeriodRequest {
    beginOn: string | null
    endOn: string | null
}

export interface CreateGroupResponse {
    id: string
    name: string
}

export interface GroupSelection {
    id: string
    selected: boolean
}

export interface ChangeGroupSelectionRequest {
    groups: GroupSelection[]
}

export interface AccessPeriod {
    beginOn?: string
    endOn?: string
}

export interface Groups {
    groupId: string
    groupName: string
    isDefault: boolean
    isMember: boolean
    memberDetails: MemberDetails
    resources: Resources
    period: AccessPeriod
}

export interface ParsedAccessPeriod {
    beginOn: Date | null
    endOn: Date | null
}
export interface MemberDetails {
    containsAllUsers: boolean
    members: Member[]
}

export interface Member {
    userId: string
    firstName: string
    lastName: string
    isAdmin: boolean
}

export interface Resources {
    wagons: Wagons
}

export interface Wagons {
    totalCount: number
    items: WagonItem[]
}

export interface WagonItem {
    uicClass: string
    count: number
}

export enum GroupManagementColumnId {
    NAME = 'NAME',
    WAGONS = 'WAGONS',
    USERS = 'USERS',
    EDIT = 'EDIT',
}

export enum GroupSource {
    TOKEN = 'TOKEN',
    BACKEND = 'BACKEND',
}
