import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'

import { CookieService } from '@storage-lib/cookie.service'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { CookieConsentService } from './cookie-consent.service'
import { CookieDialogComponent } from './cookie-dialog/cookie-dialog.component'
import { CookieDialogData } from './cookie.consent.model'

@Injectable({ providedIn: 'root' })
export class CookieGuard {
    constructor(
        private cookieService: CookieService,
        public cookieConsentService: CookieConsentService,
        private dialog: MatDialog
    ) {}

    canLoad(): Observable<boolean> {
        return this.getCookieConsent()
    }

    canActivate(): Observable<boolean> {
        return this.getCookieConsent()
    }

    private getCookieConsent(): Observable<boolean> {
        this.cookieService.ensureEnabled()

        if (this.cookieConsentService.consentGiven()) {
            return of(true)
        } else {
            const dialogRef: MatDialogRef<
                CookieDialogComponent,
                CookieDialogData
            > = this.dialog.open(CookieDialogComponent, {
                autoFocus: false,
                panelClass: 'cookie-dialog',
                width: '600px',
            })

            return dialogRef.afterClosed().pipe(
                map((data) => {
                    if (data && data.minimalAcceptenceReached) {
                        this.cookieConsentService.saveLocally(data.consents)
                        return true
                    } else {
                        return false
                    }
                }),
                catchError(() => of(false))
            )
        }
    }
}
