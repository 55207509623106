<ng-container *ngIf="error; else grid">
    <div class="collection__error" #errorContent>
        <ng-content select="[error]"></ng-content>
    </div>
    <div
        class="collection__error as-fallback"
        *ngIf="!errorContent.childNodes.length"
    >
        <mat-icon svgicon="warning" class="collection__error__icon"></mat-icon>
        <div>
            <strong *ngIf="errorInstance?.name"
                >{{ errorInstance?.name }}:</strong
            >
            {{ errorInstance?.message }}
        </div>
    </div>
</ng-container>

<ng-template #defaultEntryTemplate let-index="index" let-entry="entry">
    <p>#{{ index }}: Please provide an entry template for collection</p>
</ng-template>

<ng-template #grid>
    <section
        *ngIf="(renderEntries$ | async)?.length; else empty"
        class="collection-grid"
    >
        <ng-container
            *ngFor="let entry of renderEntries$ | async; let index = index"
        >
            <ng-template
                *ngTemplateOutlet="
                    entryTemplate || defaultEntryTemplate;
                    context: { index: index, entry: entry }
                "
            >
            </ng-template>
        </ng-container>
    </section>
</ng-template>

<ng-template #empty>
    <div class="collection__empty" #emptyContent>
        <ng-content select="[empty]"></ng-content>
    </div>
    <div
        class="collection__empty as-fallback"
        *ngIf="!emptyContent.childNodes.length"
    >
        <mat-icon svgIcon="info" class="collection__empty__icon"></mat-icon>
        {{ noEntriesText || ('NoData' | translate) }}
    </div>
</ng-template>
