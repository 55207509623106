import { Injectable } from '@angular/core'
import { Route, UrlSegment } from '@angular/router'
import { UserProfileService } from '@auth-util-lib/user-profile.service'
import { isTruthy } from '@util-lib/isTruthy'
import { of } from 'rxjs'
import { filter, mapTo, take, timeout } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class ProfileGuard {
    constructor(private profileService: UserProfileService) {}

    canLoad(route: Route, segments: UrlSegment[]) {
        return this.profileService
            .profileChanges()
            .pipe(
                filter(isTruthy),
                take(1),
                mapTo(true),
                timeout({ first: 15000, with: () => of(false) })
            )
    }
}
