import { Pipe, PipeTransform } from '@angular/core'
import { createDataQaTag } from '@shared-util-lib/pipes/dataQaTag/createDataQaTag'

@Pipe({
    name: 'dataQaTag',
})
export class DataQaTagPipe implements PipeTransform {
    transform(value: string): string {
        return createDataQaTag(value)
    }
}
