import { Injectable, OnDestroy } from '@angular/core'
import { StatusType, datadogLogs } from '@datadog/browser-logs'
import { RumEvent, datadogRum } from '@datadog/browser-rum'
import { ApiService } from '@env-lib/api/api.service'
import { EnvService } from '@env-lib/env.service'
import { isProdEnvironment } from '@env-lib/isProdEnvironment'
import { releaseInfo } from '@env-lib/releaseInfo'
import { Stage } from '@env-lib/stage'
import {
    COMBO_USE_CASES,
    FLEET_FORCE_USE_CASES,
    PLATFORM_PROPHETS_USE_CASES,
} from '@error-util-lib/datadog/team-usecases.config'
import { ReplaySubject, Subscription } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class DatadogService implements OnDestroy {
    private error$ = new ReplaySubject<Error | string>()
    private warning$ = new ReplaySubject<Error | string>()
    private info$ = new ReplaySubject<Error | string>()

    private subscription = new Subscription()

    constructor(
        private api: ApiService,
        private envService: EnvService
    ) {
        this.subscription.add(
            this.error$.subscribe((error) => {
                const err = typeof error === 'string' ? new Error(error) : error
                datadogLogs.logger.error(err.message, {
                    error: { stack: err.stack },
                })
                datadogRum.addError(err, {
                    team: this.addTeamTag(
                        datadogRum.getInternalContext()?.view?.url
                    ),
                })
            })
        )

        this.subscription.add(
            this.warning$.subscribe((warning) => {
                const warn =
                    typeof warning === 'string' ? new Error(warning) : warning
                datadogLogs.logger.warn(warn.message, {
                    warning: { stack: warn.stack },
                })
                datadogRum.addAction(warn.message, {
                    team: this.addTeamTag(
                        datadogRum.getInternalContext()?.view?.url
                    ),
                })
            })
        )

        this.subscription.add(
            this.info$.subscribe((info) => {
                const inf = typeof info === 'string' ? new Error(info) : info
                datadogLogs.logger.info(inf.message, {
                    info: { stack: inf.stack },
                })
                datadogRum.addAction(inf.message, {
                    team: this.addTeamTag(
                        datadogRum.getInternalContext()?.view?.url
                    ),
                })
            })
        )

        // @ts-ignore
        window.DD = isProdEnvironment()
            ? 'Datadog debugging utils not available on prod'
            : this.getDatadogDebuggingUtils()
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    initDatadogRum() {
        datadogRum.init({
            applicationId: 'c4629f0f-cc2a-41d8-91f3-344e10080adb',
            clientToken: 'pub0a8272ea3e4b8e6fe3aba7a047ab2ef3',
            env: this.envService.stage?.toLowerCase(),
            service: 'traigo-frontend',
            site: 'datadoghq.eu',
            trackInteractions: false,
            version: releaseInfo.version,
            allowedTracingUrls: [
                /https:\/\/lambda-reference\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/fleet\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/performance\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/sensor\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/wagon\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/auth\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/condition-monitoring\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/dashboard\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/dispatch\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/e2e-eta\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/email-service\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/servicedesk\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/transport\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/user-tracking\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/sales-care\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/contracts\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/fasttrack\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/notification\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/use-case-booking\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/user-interest\.(dev|int|prod)\.traigo\.com/,
                /https:\/\/wagon-rental\.(dev|int|prod)\.traigo\.com/,
            ],
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackResources: true,
            trackLongTasks: true,
            traceSampleRate: 100,
            sampleRate: 100,

            beforeSend: (event: RumEvent) => {
                if (
                    event.resource &&
                    event?.type === 'resource' &&
                    event.resource.url.includes('launchdarkly.com')
                ) {
                    return false
                }
            },
        })
    }

    initDatadogLogs() {
        datadogLogs.init({
            clientToken: 'pub0a8272ea3e4b8e6fe3aba7a047ab2ef3',
            env: this.envService.stage?.toLowerCase(),
            forwardErrorsToLogs: true,
            sampleRate: 100,
            service: 'traigo-frontend',
            site: 'datadoghq.eu',
            version: releaseInfo.version,
        })

        window.onerror = (message, source, lineno, colno, error) => {
            datadogLogs.logger.error(error?.message || '', {
                error: { stack: error?.stack },
            })
            datadogRum.addError(error)
        }
    }

    log(
        error: Error | string,
        severity?: StatusType,
        stages = [Stage.Dev, Stage.Int, Stage.Prod]
    ) {
        if (stages.includes(this.api.datadog.environmentName)) {
            if (severity === 'warn') {
                this.warning$.next(error)
            } else if (severity === 'info') {
                this.info$.next(error)
            } else {
                this.error$.next(error)
            }
        }
    }

    getDatadogDebuggingUtils() {
        return {
            logs: datadogLogs,
            rum: datadogRum,
            provokeError: (message = 'provokeError') => {
                throw new Error(message)
            },
            provokePromiseRejection: (message = 'provokePromiseRejection') =>
                Promise.reject(message),
            logError: (message = 'logError') =>
                datadogLogs.logger.log(message, new Error(message)),
            rumError: (message = 'rumError') =>
                datadogRum.addError(new Error(message)),
        }
    }

    addTeamTag(url?: string): string {
        if (FLEET_FORCE_USE_CASES.some((uc) => url?.includes(uc)))
            return 'fleet_force'
        if (COMBO_USE_CASES.some((uc) => url?.includes(uc))) return 'combo'
        if (PLATFORM_PROPHETS_USE_CASES.some((uc) => url?.includes(uc)))
            return 'platform_prophets'
        return 'general'
    }
}
