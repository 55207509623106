import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    ViewChild,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, fromEvent, Subscription, timer } from 'rxjs'
import { filter, map, mapTo, pairwise, shareReplay } from 'rxjs/operators'
import { loadCount } from '@platform-lib/notification/redux/notification.actions'
import { selectUnreadNotificationCount } from '@platform-lib/notification/redux/notification.selectors'
import { isDevelopment } from '@env-lib/isDevelopment'

@Component({
    selector: 'app-notification-bell',
    templateUrl: './notification-bell.component.html',
    styleUrls: ['./notification-bell.component.scss'],
})
export class NotificationBellComponent implements AfterViewInit, OnDestroy {
    @ViewChild('bellIcon', { static: false, read: ElementRef })
    bellIcon?: ElementRef<HTMLElement>

    @HostBinding('class.active') _active = false

    @Input()
    set active(value: boolean) {
        this._active = value
    }
    get active() {
        return this._active
    }

    bellRinging$ = new BehaviorSubject(false)

    private updateIntervalInMinutes = isDevelopment() ? 0.25 : 5

    unreadCount$ = this.store.select(selectUnreadNotificationCount)

    unreadCountLabel$ = this.unreadCount$.pipe(
        map((unreadCount) =>
            100 <= unreadCount ? '99+' : String(unreadCount || '')
        ),
        shareReplay(1)
    )

    subscription = new Subscription()

    constructor(private store: Store) {}

    ngAfterViewInit() {
        // signal unreadCount to update
        this.subscription.add(
            timer(3000, this.updateIntervalInMinutes * 60000).subscribe(() =>
                this.store.dispatch(loadCount())
            )
        )
        // bell should start ringing when unreadCount increases
        this.subscription.add(
            this.unreadCount$
                .pipe(
                    pairwise(),
                    filter(([a, b]) => a < b),
                    mapTo(true)
                )
                .subscribe(this.bellRinging$)
        )
        // remove bell animation class after animation has ended
        this.subscription.add(
            fromEvent(this.bellIcon?.nativeElement || [], 'animationend')
                .pipe(mapTo(false))
                .subscribe(this.bellRinging$)
        )
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
