import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { SidebarComponent } from '@platform-lib/components/sidebar/sidebar.component'
import { AnalyticsModule } from '@analytics-lib/analytics.module'

@NgModule({
    declarations: [SidebarComponent],
    exports: [SidebarComponent],
    imports: [
        AnalyticsModule,
        CommonModule,
        MatButtonModule,
        MatDividerModule,
        RouterModule,
        MatIconModule,
    ],
})
export class SidebarModule {}
