import { GroupSource } from '@group-management-lib/group-management.model'
import { checkTokenIdsEqualsBackendIds } from '@group-management-lib/util/checkTokenIdsEqualsBackendIds'
import { Action, createReducer, on } from '@ngrx/store'
import * as Actions from './group-management.actions'
import {
    GroupManagementState,
    initialGroupManagementState,
} from './group-management.state'

const _groupManagementReducer = createReducer(
    // #######################
    // General
    // #######################

    initialGroupManagementState,

    on(Actions.setGroupsFromToken, (state, { groupsFromToken }) => ({
        ...state,
        groupsFromToken,
        checkGroupsEqual: checkTokenIdsEqualsBackendIds(
            groupsFromToken,
            state.groupsFromBackend
        ),
        lastGroupSource: GroupSource.TOKEN,
    })),

    on(Actions.loadGroupsFromBackend, (state) => ({
        ...state,
        groupsFromBackendLoading: true,
        groupsFromBackendError: null,
    })),

    on(Actions.loadGroupsFromBackendError, (state, { error }) => ({
        ...state,
        groupsFromBackendLoading: false,
        groupsFromBackendError: error,
        groupsFromBackend: null,
        checkGroupsEqual: checkTokenIdsEqualsBackendIds(
            state.groupsFromToken,
            null
        ),
        lastGroupSource: GroupSource.BACKEND,
    })),

    on(
        Actions.loadGroupsFromBackendSuccess,
        (state, { groupsFromBackend }) => ({
            ...state,
            groupsFromBackendLoading: false,
            groupsFromBackendError: null,
            groupsFromBackend,
            checkGroupsEqual: checkTokenIdsEqualsBackendIds(
                state.groupsFromToken,
                groupsFromBackend
            ),
            lastGroupSource: GroupSource.BACKEND,
        })
    ),

    on(Actions.loadColleaguesSuccess, (state, { colleagues }) => ({
        ...state,
        colleagues,
    })),

    on(Actions.refreshBackend, (state, { refresh }) => ({
        ...state,
        refreshBackend: refresh,
    })),

    // #######################
    // Group Management Page
    // #######################

    on(Actions.loadGroupsWithResources, (state) => ({
        ...state,
        triggerLoadGroupsWithResources: false,
        groupsWithResourcesLoading: true,
        groupsWithResourcesError: null,
    })),

    on(Actions.loadGroupsWithResourcesError, (state, { error }) => ({
        ...state,
        groupsWithResourcesLoading: false,
        groupsWithResourcesError: error,
        groupsWithResources: null,
    })),

    on(
        Actions.loadGroupsWithResourcesSuccess,
        (state, { groupsWithResources }) => ({
            ...state,
            groupsWithResourcesLoading: false,
            groupsWithResourcesError: null,
            groupsWithResources,
        })
    ),

    // #######################
    // Dropdown / Header
    // #######################

    on(Actions.changeGroupSelection, (state) => ({
        ...state,
        changeGroupSelectionLoading: true,
        changeGroupSelectionError: null,
    })),

    on(Actions.changeGroupSelectionSuccess, (state) => ({
        ...state,
        changeGroupSelectionLoading: false,
        changeGroupSelectionError: null,
    })),

    on(Actions.changeGroupSelectionError, (state, { error }) => ({
        ...state,
        changeGroupSelectionLoading: false,
        changeGroupSelectionError: error,
    })),

    // #######################
    // General Group Overlay
    // #######################

    on(Actions.loadPossibleWagonsForUser, (state) => ({
        ...state,
        possibleWagonsForUserLoading: true,
        possibleWagonsForUserError: null,
    })),

    on(Actions.loadPossibleWagonsForUserError, (state, { error }) => ({
        ...state,
        possibleWagonsForUserLoading: false,
        possibleWagonsForUserError: error,
        possibleWagonsForUser: null,
    })),

    on(
        Actions.loadPossibleWagonsForUserSuccess,
        (state, { possibleWagonsForUser }) => ({
            ...state,
            possibleWagonsForUserLoading: false,
            possibleWagonsForUserError: null,
            possibleWagonsForUser,
        })
    ),

    on(Actions.changeSelectedWagonsForGroup, (state) => ({
        ...state,
        changeSelectedWagonsForGroupLoading: true,
        changeSelectedWagonsForGroupError: null,
    })),

    on(Actions.changeSelectedWagonsForGroupSuccess, (state) => ({
        ...state,
        changeSelectedWagonsForGroupLoading: false,
        changeSelectedWagonsForGroupError: null,
    })),

    on(Actions.changeSelectedWagonsForGroupError, (state, { error }) => ({
        ...state,
        changeSelectedWagonsForGroupLoading: false,
        changeSelectedWagonsForGroupError: error,
    })),

    on(Actions.changeMembersOfGroup, (state) => ({
        ...state,
        changeMembersOfGroupLoading: true,
        changeMembersOfGroupError: null,
    })),

    on(Actions.changeMembersOfGroupSuccess, (state) => ({
        ...state,
        changeMembersOfGroupLoading: false,
        changeMembersOfGroupError: null,
    })),

    on(Actions.changeMembersOfGroupError, (state, { error }) => ({
        ...state,
        changeMembersOfGroupLoading: false,
        changeMembersOfGroupError: error,
    })),

    on(Actions.createGroup, (state) => ({
        ...state,
        createGroupLoading: true,
        createGroupError: null,
    })),

    on(Actions.createGroupSuccess, (state) => ({
        ...state,
        createGroupLoading: false,
        createGroupError: null,
    })),

    on(Actions.createGroupError, (state, { error }) => ({
        ...state,
        createGroupLoading: false,
        createGroupError: error,
    })),

    // #######################
    // Group Editor
    // #######################

    on(Actions.setGroupIdToEdit, (state, { groupIdToEdit }) => ({
        ...state,
        groupIdToEdit,
    })),

    on(Actions.loadWagonsForSelectedGroup, (state) => ({
        ...state,
        wagonsForSelectedGroupLoading: true,
        wagonsForSelectedGroupError: null,
    })),

    on(
        Actions.loadWagonsForSelectedGroupSuccess,
        (state, { selectedWagonsForGroup }) => ({
            ...state,
            wagonsForSelectedGroup: selectedWagonsForGroup,
            wagonsForSelectedGroupLoading: false,
            wagonsForSelectedGroupError: null,
        })
    ),

    on(Actions.loadWagonsForSelectedGroupError, (state, { error }) => ({
        ...state,
        wagonsForSelectedGroup: null,
        wagonsForSelectedGroupLoading: false,
        wagonsForSelectedGroupError: error,
    })),

    on(Actions.editGroup, (state) => ({
        ...state,
        editGroupLoading: true,
        editGroupError: null,
    })),

    on(Actions.editGroupSuccess, (state) => ({
        ...state,
        editGroupLoading: false,
        editGroupError: null,
    })),

    on(Actions.editGroupError, (state, { error }) => ({
        ...state,
        editGroupLoading: false,
        editGroupError: error,
    })),

    on(Actions.deleteGroup, (state) => ({
        ...state,
        groupIdToEdit: null,
        deleteGroupLoading: true,
        deleteGroupError: null,
    })),

    on(Actions.deleteGroupSuccess, (state) => ({
        ...state,
        deleteGroupLoading: false,
        deleteGroupError: null,
    })),

    on(Actions.deleteGroupError, (state, { error }) => ({
        ...state,
        deleteGroupLoading: false,
        deleteGroupError: error,
    }))
)

export const groupManagementReducer = (
    state: GroupManagementState | undefined,
    action: Action
) => _groupManagementReducer(state, action)
