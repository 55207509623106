import { ComponentType } from '@angular/cdk/overlay'
import { Component, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { BannerConfigService } from '@banner-lib/banner-config.service'
import { BannerConfiguration, BannerPriority } from '@banner-lib/banner.model'
import { getIconForPriority } from '@banner-lib/util/getIconForPriority'
import { FastTrackAccessRequestDialogComponent } from '@fast-track/fast-track-user/fast-track-access/fast-track-access-request-dialog/fast-track-access-request-dialog.component'
import { processHttpResponse } from '@util-lib/processHttpResponse'
import {
    BehaviorSubject,
    Subscription,
    combineLatest,
    filter,
    map,
    shareReplay,
    startWith,
} from 'rxjs'

@Component({
    selector: 'app-banner-handler',
    templateUrl: './banner-handler.component.html',
    styleUrls: ['./banner-handler.component.scss'],
})
export class BannerHandlerComponent implements OnDestroy {
    private subscriptions = new Subscription()

    configurations$ = new BehaviorSubject<BannerConfiguration[]>([])

    getConfigurationsResponse$ = this.bannerConfigService
        .getBannerConfigs()
        .pipe(processHttpResponse(), shareReplay(1))

    responseData$ = this.getConfigurationsResponse$.pipe(
        map((response) => response.data?.body)
    )

    currentUrl$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).url),
        startWith(this.router.url)
    )

    constructor(
        private router: Router,
        private bannerConfigService: BannerConfigService
    ) {
        this.subscriptions.add(
            combineLatest([this.currentUrl$, this.responseData$]).subscribe(
                ([currentUrl, configs]) => {
                    const configurations: BannerConfiguration[] = []
                    if (configs) {
                        configs
                            .filter((config) => config.published)
                            .forEach((config) => {
                                // if paths empty display on every page
                                if (config.paths && config.paths.length === 0) {
                                    configurations?.push(config)
                                }

                                if (
                                    this.doesBannerConfigMatchCurrentUrl(
                                        config,
                                        currentUrl
                                    )
                                ) {
                                    configurations?.push(config)
                                }
                            })
                    }

                    this.configurations$.next(configurations)
                }
            )
        )
    }

    doesBannerConfigMatchCurrentUrl(
        config: BannerConfiguration,
        currentUrl: string
    ): boolean {
        if (config.paths && config.paths.length > 0) {
            const configPathMatches = config.paths.some((path) => {
                const pattern = new RegExp(path + '.*')
                const matches = currentUrl.match(pattern)

                if (matches && matches.length > 0) {
                    return true
                }

                return false
            })

            return !!configPathMatches
        }

        return false
    }

    getIconAttributes(priority: BannerPriority) {
        return getIconForPriority(priority)
    }

    getDialogComponent(
        dialogToOpen: string | undefined
    ): ComponentType<unknown> | undefined {
        if (
            dialogToOpen &&
            dialogToOpen === 'FastTrackAccessRequestDialogComponent'
        ) {
            return FastTrackAccessRequestDialogComponent
        }

        return undefined
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
