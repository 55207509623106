import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Component, HostBinding, Input, OnDestroy } from '@angular/core'
import { StatusType } from '@datadog/browser-logs'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'
import { ErrorType } from '@error-util-lib/error.model'
import { getErrorMessage } from '@error-util-lib/getErrorMessage'
import { isTruthy } from '@util-lib/isTruthy'
import { ReplaySubject, Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'

@Component({
    selector: 'app-error-panel',
    styleUrls: ['./error-panel.component.scss'],
    templateUrl: './error-panel.component.html',
})
export class ErrorPanelComponent implements OnDestroy {
    private subscriptions = new Subscription()

    @HostBinding('class.small')
    _small = false
    @Input()
    set small(small: boolean) {
        this._small = coerceBooleanProperty(small)
    }
    get small() {
        return this._small
    }

    @HostBinding('class.vertical')
    _vertical = false
    @Input()
    set vertical(vertical: boolean) {
        this._vertical = coerceBooleanProperty(vertical)
    }
    get vertical() {
        return this._vertical
    }

    @Input() customIcon?: string

    @Input() hideIcon = false

    error$ = new ReplaySubject<ErrorType>(1)
    @Input()
    set error(error: ErrorType) {
        this.error$.next(error)
    }

    errorMessage$ = this.error$.pipe(filter(isTruthy), map(getErrorMessage))

    constructor(private datadogService: DatadogService) {
        this.subscriptions.add(
            this.errorMessage$.subscribe((message) => {
                this.datadogService.log(message, StatusType.error)
            })
        )
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
