<app-layout
    [useBackgroundImage]="imageBackground"
    [customBackgroundColor]="customBackgroundColor"
    [headerClass]="headerClass"
    trackOverlayContext
>
    <ng-container header *ngIf="!hideHeader">
        <ng-template [cdkPortalOutlet]="headerPortal"></ng-template>
    </ng-container>

    <ng-container content>
        <app-content [fullWidth]="fullWidth">
            <router-outlet></router-outlet>
            <app-page
                *ngIf="authorizedGuardProcessing$ | async"
                hidePageTitle
                hideBreadcrumb
                [loadingWholePage]="true"
                class="fallback-content"
            ></app-page>
        </app-content>
    </ng-container>

    <ng-container footer *ngIf="!hideFooter">
        <ng-template [cdkPortalOutlet]="footerPortal"></ng-template>
    </ng-container>
</app-layout>
