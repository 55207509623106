import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { NotificationItemModule } from '@platform-lib/notification/notification-item/notification-item.module'
import { CollectionModule } from '@shared-ui-lib/collection/collection.module'
import { IntersectionModule } from '@shared-util-lib/directives/intersection/intersection.module'
import { NotificationListComponent } from './notification-list.component'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'

@NgModule({
    declarations: [NotificationListComponent],
    exports: [NotificationListComponent],
    imports: [
        CommonModule,
        RouterModule,
        CollectionModule,
        IntersectionModule,
        NotificationItemModule,
        TranslationModule,
        SystemResponseModule,
    ],
})
export class NotificationListModule {}
