import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ContactModule } from '@platform-lib/legal/contact/contact.module'
import { ImprintComponent } from '@platform-lib/legal/imprint/imprint.component'
import { PrivacyContent20230913Module } from '@platform-lib/legal/privacy/privacy-content-20230913/privacy-content-20230913.module'
import { PrivacyComponent } from '@platform-lib/legal/privacy/privacy.component'
import { TermsConditionsModule } from '@platform-lib/legal/terms-conditions/terms-conditions.module'
import { BackToTopModule } from '@shared-ui-lib/back-to-top/back-to-top.module'
import { PageModule } from '@shared-ui-lib/page/page.module'
import { LegalMaterialModule } from './legal-material.module'

@NgModule({
    declarations: [ImprintComponent, PrivacyComponent],
    imports: [
        BackToTopModule,
        CommonModule,
        ContactModule,
        LegalMaterialModule,
        PageModule,
        TermsConditionsModule,
        TranslationModule,
        PrivacyContent20230913Module,
    ],
})
export class LegalModule {}
