<div
    id="wave-container"
    class="wave-container"
    [class.primary]="primary"
    [class.small]="small"
>
    <div class="dot animate" id="d1"></div>
    <div class="dot animate" id="d2"></div>
    <div class="dot animate" id="d3"></div>
</div>
