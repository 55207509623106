import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar'
import { BehaviorSubject } from 'rxjs'
import { SnackbarData, SnackbarTextWithParams } from './snackbar.model'

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
    @Output()
    readonly actionTriggered = new EventEmitter<void>()

    contentText$ = new BehaviorSubject<SnackbarTextWithParams | null>(null)

    constructor(
        public snackbarRef: MatSnackBarRef<SnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData
    ) {
        this.contentText$.next(
            typeof data.contentText === 'string'
                ? { text: data.contentText }
                : data.contentText
        )

        this.snackbarRef.onAction().subscribe(() => {
            if (this.data.dismissAction) {
                this.data.dismissAction()
            }
        })
    }

    isPrimarySnackbar() {
        return this.data.snackbarType !== 'secondary'
    }
}
