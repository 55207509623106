import { ParsedAccessPeriod } from '../group-management.model'

export const accessPeriodsEqual = (
    initial: ParsedAccessPeriod,
    selected: ParsedAccessPeriod
): boolean =>
    datesEqual(initial.beginOn, selected.beginOn) &&
    datesEqual(initial.endOn, selected.endOn)

export const datesEqual = (
    initial: Date | null,
    selected: Date | null
): boolean => initial?.getTime() === selected?.getTime()
