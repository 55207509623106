import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { GroupManagementQuitDialogContentData } from '@group-management-lib/components/group-management-quit-dialog/group-management-quit-dialog.model'

@Component({
    selector: 'app-group-management-quit-dialog',
    templateUrl: './group-management-quit-dialog.component.html',
})
export class GroupManagementQuitDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<GroupManagementQuitDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: GroupManagementQuitDialogContentData
    ) {}

    onQuitClick() {
        this.dialogRef.close(true)
    }

    onCloseClick() {
        this.dialogRef.close(false)
    }
}
