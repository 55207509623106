import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { LogoutComponent } from './logout.component'

@NgModule({
    declarations: [LogoutComponent],
    imports: [ButtonGroupModule, ButtonModule, CommonModule, TranslationModule],
})
export class LogoutModule {}
