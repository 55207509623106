import {
    ErrorType,
    isError,
    isErrorWithOriginalError,
    isHttpErrorResponse,
} from '@error-util-lib/error.model'

export function getErrorMessage(error: ErrorType): string {
    if (isHttpErrorResponse(error)) {
        return error.message
    }
    if (isErrorWithOriginalError(error)) {
        return getErrorMessage(error.originalError)
    }
    if (isError(error)) {
        return error.message
    }
    return error.toString()
}
