import { AnalyticsModule } from '@analytics-lib/analytics.module'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { GroupManagementQuitDialogModule } from '@group-management-lib/components/group-management-quit-dialog/group-management-quit-dialog.module'
import { GroupManagementWagonNumberInputModule } from '@group-management-lib/components/group-management-wagon-number-input/group-management-wagon-number-input.module'
import { LoadingModule } from '@loading-lib/loading.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { GroupManagementCreatorComponent } from './group-management-creator.component'
import { GroupManagementUserSelectionModule } from '@group-management-lib/components/group-management-user-selection/group-management-user-selection.module'
import { MatStepperModule } from '@angular/material/stepper'
import { GroupManagementAccessPeriodSelectionModule } from '@group-management-lib/components/group-management-access-period-selection/group-management-access-period-selection.module'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'

@NgModule({
    declarations: [GroupManagementCreatorComponent],
    imports: [
        AnalyticsModule,
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        FormsModule,
        GroupManagementQuitDialogModule,
        GroupManagementWagonNumberInputModule,
        LoadingModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule,
        TranslationModule,
        GroupManagementUserSelectionModule,
        MatStepperModule,
        GroupManagementAccessPeriodSelectionModule,
        SystemResponseModule,
    ],
})
export class GroupManagementCreatorModule {}
