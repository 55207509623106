import dayjs from '@localization-lib/date-time/dayjs'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { NotificationChunk } from '@platform-lib/notification/notification.model'
import { notificationEntryAdapter } from '@platform-lib/notification/redux/notification.adapter'
import {
    NotificationState,
    notificationFeatureKey,
} from '@platform-lib/notification/redux/notification.state'

export const selectNotificationState = createFeatureSelector<NotificationState>(
    notificationFeatureKey
)

const adapterSelectors = notificationEntryAdapter.getSelectors()

export const selectUnreadNotificationCount = createSelector(
    selectNotificationState,
    ({ count }) => count
)

export const selectEntriesState = createSelector(
    selectNotificationState,
    ({ entries }) => entries
)

export const selectEntriesEntityIds = createSelector(
    selectEntriesState,
    adapterSelectors.selectIds
)

export const selectEntries = createSelector(
    selectEntriesState,
    adapterSelectors.selectAll
)

export const selectEntryLoadingError = createSelector(
    selectNotificationState,
    (state) => state.entriesLoadingError
)

export const selectFilterValues = createSelector(
    selectNotificationState,
    ({ filterValues }) => filterValues
)

export const selectFilteredEntries = createSelector(
    selectNotificationState,
    selectEntries,
    selectFilterValues,
    (_, entries, filterValues) =>
        entries.filter(
            ({ isMarked, priority, useCase }) =>
                (isMarked || !filterValues.isMarked) &&
                filterValues.priority.includes(priority) &&
                filterValues.useCase.includes(useCase)
        )
)

export const selectEntriesIds = createSelector(selectEntriesEntityIds, (ids) =>
    ids.map((id) => String(id))
)

export const selectTotalNotificationCount = createSelector(
    selectEntries,
    (entries) => entries.length
)

export const selectEntriesAvailable = createSelector(
    selectNotificationState,
    ({ entriesAvailable }) => entriesAvailable
)

export const selectEntriesLimit = createSelector(
    selectNotificationState,
    ({ entriesLimit }) => entriesLimit
)

export const isLoadingEntries = createSelector(
    selectNotificationState,
    ({ entriesLoading }) => entriesLoading
)

export const isLoadingChunk = createSelector(
    selectNotificationState,
    ({ chunkLoading }) => chunkLoading
)

export const selectChunkSize = createSelector(
    selectNotificationState,
    ({ chunkSize: chunkSize }) => chunkSize
)

export const selectChunkEntriesLoaded = createSelector(
    selectNotificationState,
    ({ chunkEntriesLoaded }) => chunkEntriesLoaded
)

export const selectChunkLoadingError = createSelector(
    selectNotificationState,
    ({ chunkLoadingError }) => chunkLoadingError
)

export const selectChunks = createSelector(
    selectNotificationState,
    selectFilteredEntries,
    (state, entries) => {
        // create chunks for notifications
        const chunks: NotificationChunk[] = []
        entries.forEach((notification) => {
            const { occurredAt } = notification

            const chunk = chunks.find(
                ({ start, finish }) =>
                    -1 < occurredAt.localeCompare(start) &&
                    occurredAt.localeCompare(finish) < 1
            )
            if (chunk) {
                chunk.notifications.push(notification)
            } else {
                chunks.push({
                    start: dayjs(occurredAt).startOf('month').toISOString(),
                    finish: dayjs(occurredAt).endOf('month').toISOString(),
                    notifications: [notification],
                })
            }
        })

        return chunks
    }
)

export const selectRemoveEntriesParams = createSelector(
    selectNotificationState,
    ({ removeEntriesParams }) => removeEntriesParams
)

export const selectSetIsMarkedParams = createSelector(
    selectNotificationState,
    ({ setIsMarkedParams }) => setIsMarkedParams
)
