import { NgModule } from '@angular/core'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { PrivacyContent20230913DeComponent } from '@platform-lib/legal/privacy/privacy-content-20230913/privacy-content-20230913-de.component'
import { PrivacyContent20230913EnComponent } from '@platform-lib/legal/privacy/privacy-content-20230913/privacy-content-20230913-en.component'
import { PrivacyContentComponent } from '@platform-lib/legal/privacy/privacy-content-20230913/privacy-content.component'
import { SnackbarModule } from '@shared-ui-lib/snackbar/snackbar.module'

@NgModule({
    declarations: [
        PrivacyContentComponent,
        PrivacyContent20230913DeComponent,
        PrivacyContent20230913EnComponent,
    ],
    exports: [
        PrivacyContent20230913DeComponent,
        PrivacyContent20230913EnComponent,
    ],
    imports: [MatSnackBarModule, SnackbarModule, TranslationModule],
})
export class PrivacyContent20230913Module {}
