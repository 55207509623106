import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NotificationBellComponent } from './notification-bell.component'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
    declarations: [NotificationBellComponent],
    exports: [NotificationBellComponent],
    imports: [CommonModule, MatIconModule],
})
export class NotificationBellModule {}
