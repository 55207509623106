import { EventEmitter } from '@angular/core'
import { AnimationEvent } from '@angular/animations'

export class OverlayAnimatedContent {
    animationState: 'void' | 'enter' | 'leave' = 'enter'
    animationStateChanged = new EventEmitter<AnimationEvent>()

    onAnimationStart(event: AnimationEvent) {
        this.animationStateChanged.emit(event)
    }

    onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event)
    }

    startExitAnimation() {
        this.animationState = 'leave'
    }
}
