import { Injectable, OnDestroy } from '@angular/core'
import { UserProfileService } from '@auth-util-lib/user-profile.service'
import {
    DEFAULT_LANGUAGE,
    Language,
    isSupportedLanguage,
} from '@localization-lib/language/models/Language'
import { DeveloperToolsService } from '@platform-lib/components/developer-tools/developer-tools.service'
import { CookieConsentService } from '@platform-lib/legal/cookie/cookie-consent.service'
import { LocalStorageService } from '@storage-lib/local-storage.service'
import { isTruthy } from '@util-lib/isTruthy'

import {
    Observable,
    ReplaySubject,
    Subscription,
    combineLatest,
    of,
} from 'rxjs'
import { filter, map, shareReplay } from 'rxjs/operators'

interface NavigatorWithUserLangage extends Navigator {
    userLanguage?: string
}

@Injectable({
    providedIn: 'root',
})
export class LanguageService implements OnDestroy {
    private readonly storageKey = 'USER_LANGUAGE'

    private _ignoreStore = false

    private storedLanguage$ = of(this.storageService.get(this.storageKey)).pipe(
        map((lang) => (lang && isSupportedLanguage(lang) ? lang : null)),
        shareReplay(1)
    )

    private browserLanguage$ = new ReplaySubject<Language>(1)

    selectedLanguage$: Observable<Language> = combineLatest([
        this.developerToolsService.overriddenLanguage$,
        this.profileService.profileChanges(),
        this.storedLanguage$,
        this.browserLanguage$,
    ]).pipe(
        map(
            ([
                languageOverride,
                userProfile,
                storedLanguage,
                browserLanguage,
            ]) => {
                let language: Language = DEFAULT_LANGUAGE

                if (languageOverride) {
                    language = languageOverride
                } else if (userProfile?.language) {
                    language = userProfile.language
                } else if (storedLanguage && !this._ignoreStore) {
                    language = storedLanguage
                } else {
                    language = browserLanguage
                }

                document.documentElement.lang = language
                return language
            }
        ),
        shareReplay(1)
    )

    private readonly subscription = new Subscription()

    constructor(
        private profileService: UserProfileService,
        private developerToolsService: DeveloperToolsService,
        private storageService: LocalStorageService,
        private cookieConsentService: CookieConsentService
    ) {
        this.subscription.add(
            this.profileService
                .profileChanges()
                .pipe(
                    filter(isTruthy),
                    map(({ language }) => language),
                    filter(isTruthy)
                )
                .subscribe((language) => this.storeLanguage(language))
        )

        this.detectBrowserLanguage()
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    private detectBrowserLanguage() {
        const nav = navigator as NavigatorWithUserLangage
        const language = nav.languages
            ? nav.languages[0]
            : nav.language || nav.userLanguage

        const match = language?.match(/^([a-zA-Z]+)/)

        const countryCode = match ? match[1] : null

        if (countryCode && isSupportedLanguage(countryCode)) {
            this.setLanguage(countryCode, true)
        } else {
            this.setLanguage(DEFAULT_LANGUAGE, true)
        }
    }

    setLanguage(lang: Language, ignoreStore = false) {
        this.browserLanguage$.next(lang)
        this._ignoreStore = ignoreStore
        if (!this._ignoreStore) {
            this.storeLanguage(lang)
        }
    }

    storeLanguage(lang: Language) {
        if (this.cookieConsentService.consentGiven()) {
            this.storageService.set(this.storageKey, lang)
        }
    }
}
