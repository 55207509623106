export const dateFormats = {
    display: {
        dateA11yLabel: 'LL',
        dateInput: 'DD.MM.YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
        monthYearLabel: 'MMM YYYY',
    },
    parse: {
        dateInput: ['DD.MM.YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY'],
    },
}
