<ng-container *ngIf="configurations$ | async as configs">
    <ng-container *ngFor="let config of configs">
        <app-banner
            [action]="config.action"
            [icon]="getIconAttributes(config.priority).icon"
            [iconTextColor]="getIconAttributes(config.priority).iconTextColor"
            [bannerText]="config.bannerText"
            [buttonText]="config.buttonText"
            [buttonIcon]="config.buttonIcon"
            [closeable]="config.closeable"
            [dialogToOpen]="getDialogComponent(config.dialogToOpen)"
            [routerLink]="config.routerLink"
            [id]="config.bannerConfigurationId"
        >
        </app-banner>
    </ng-container>
</ng-container>
