import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { SessionStorageService } from '@storage-lib/session-storage.service'
import Bowser from 'bowser'

@Injectable({
    providedIn: 'root',
})
export class IncompatibleBrowserService {
    private SESSION_STORAGE_REDIRECT_KEY = 'INCOMPATIBLE_BROWSER_REDIRECT_PATH'
    private SESSION_STORAGE_ACCEPTED_KEY = 'INCOMPATIBLE_BROWSER_ACCEPTED'
    private _isValidBrowser: boolean

    constructor(
        private sessionStorage: SessionStorageService,
        private router: Router
    ) {
        const browser = Bowser.getParser(window.navigator.userAgent)
        this._isValidBrowser = !!browser.satisfies({
            chrome: '>=51',
            chromium: '>=51',
            edge: '>=14',
            firefox: '>=54',
            safari: '>=10',
        })
    }

    isValidBrowser() {
        return this._isValidBrowser
    }

    hasAcceptedIncompatibleBrowser() {
        return !!this.sessionStorage.get(this.SESSION_STORAGE_ACCEPTED_KEY)
    }

    redirectToIncompatibleBrowserPage(redirectPath?: string) {
        if (redirectPath) {
            this.sessionStorage.set(
                this.SESSION_STORAGE_REDIRECT_KEY,
                redirectPath
            )
        }

        this.router.navigate(['/incompatible-browser'])
    }

    redirectBackToApplication() {
        const redirectPath =
            this.sessionStorage.get(this.SESSION_STORAGE_REDIRECT_KEY) || '/'

        this.sessionStorage.set(this.SESSION_STORAGE_ACCEPTED_KEY, 'TRUE')
        this.sessionStorage.delete(this.SESSION_STORAGE_REDIRECT_KEY)

        this.router.navigate([redirectPath])
    }
}
