import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { of } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class LandingPageHeaderClassResolver {
    constructor(private authn: AuthnService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return of(
            this.authn.stateChanges().pipe(
                startWith(undefined),
                map((authState) => {
                    if (authState === AuthenticationState.Authenticated) {
                        return undefined
                    }

                    return 'landing-page-header'
                })
            )
        )
    }
}
