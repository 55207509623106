import { Directive, ElementRef, OnInit } from '@angular/core'
import { IntersectionAbstract } from '@shared-util-lib/directives/intersection/intersection.abstract'

@Directive({
    selector: '[appIntersectionContext]',
})
export class IntersectionContextDirective
    extends IntersectionAbstract
    implements OnInit
{
    constructor(protected elementRef: ElementRef) {
        super(elementRef)
    }

    ngOnInit() {
        super.ngOnInit()

        this.rootSubject.next(this.elementRef.nativeElement)
    }
}
