import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BannerConfiguration } from '@banner-lib/banner.model'
import { ApiService } from '@env-lib/api/api.service'
import { Subject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class BannerConfigService {
    public triggerReload$ = new Subject()

    constructor(
        private api: ApiService,
        private http: HttpClient
    ) {}

    getBannerConfigs() {
        return this.http.get<BannerConfiguration[]>(
            `${this.api.notification.backendUrl}/banners/configurations`,
            { observe: 'response' }
        )
    }

    createOrUpdateBannerConfig(bannerConfiguration: BannerConfiguration) {
        const url = `${this.api.notification.backendUrl}/banners/configurations`
        const body = {
            ...bannerConfiguration,
        }

        return this.http.put(url, body, { observe: 'response' })
    }

    deleteBannerConfig(bannerConfigurationId: string) {
        const url = `${this.api.notification.backendUrl}/banners/configurations/${bannerConfigurationId}`
        return this.http.delete(url, { observe: 'response' })
    }
}
