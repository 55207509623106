import {
    AnalyticsCategory,
    AnalyticsGroupManagementAction,
} from '@analytics-lib/analytics.model'
import { AnalyticsService } from '@analytics-lib/analytics.service'
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Router } from '@angular/router'
import { GroupMetaData } from '@group-management-lib/group-management.model'
import { GroupManagementService } from '@group-management-lib/group-management.service'
import { changeGroupSelection } from '@group-management-lib/redux/group-management.actions'
import {
    hasGroupLoadingError,
    selectChangeGroupSelectionError,
    selectChangeGroupSelectionLoading,
    selectGroupOptions,
    selectGroupsFromBackendLoading,
    selectIsMemberOfDefaultGroup,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { select, Store } from '@ngrx/store'
import { deepEqual } from '@util-lib/deepEqual'
import { isTruthy } from '@util-lib/isTruthy'
import {
    BehaviorSubject,
    combineLatest,
    filter,
    Observable,
    Subscription,
} from 'rxjs'

@Component({
    selector: 'app-group-management-dropdown',
    styleUrls: ['./group-management-dropdown.component.scss'],
    templateUrl: './group-management-dropdown.component.html',
})
export class GroupManagementDropdownComponent implements OnDestroy {
    readonly searchFieldThreshold = 12

    readonly isGroupManagementAllowed$ =
        this.groupManagementService.isGroupManagementAllowed$

    readonly userIsInRootGroup$: Observable<boolean> = this.store.pipe(
        select(selectIsMemberOfDefaultGroup)
    )

    subscriptions = new Subscription()

    initialGroupOptions$ = new BehaviorSubject<GroupMetaData[] | undefined>(
        undefined
    )

    groupOptions: GroupMetaData[] | undefined
    groupOptions$ = new BehaviorSubject<GroupMetaData[] | undefined>(undefined)

    showSearchField$ = new BehaviorSubject<boolean>(false)
    filteredGroups$ = new BehaviorSubject<GroupMetaData[] | undefined>(
        undefined
    )

    hasGroupSelectionChanged = false

    isAnyGroupSelected = false

    readonly searchControl = this.formBuilder.control('')

    @Output()
    readonly emitCloseDialog = new EventEmitter()

    loading$ = this.store.pipe(select(selectGroupsFromBackendLoading))
    groupsLoadingError$ = this.store.pipe(select(hasGroupLoadingError))

    groupSelectionChangeLoading$ = this.store.pipe(
        select(selectChangeGroupSelectionLoading)
    )
    groupSelectionChangeError$ = this.store.pipe(
        select(selectChangeGroupSelectionError)
    )

    constructor(
        private analyticsService: AnalyticsService,
        private router: Router,
        private store: Store<GroupManagementState>,
        private groupManagementService: GroupManagementService,
        private formBuilder: FormBuilder
    ) {
        this.subscriptions.add(
            this.store
                .pipe(select(selectGroupOptions))
                .pipe(filter(isTruthy))
                .pipe(filter((g) => g.length >= 1))
                .subscribe((initialGroups) => {
                    this.initialGroupOptions$.next(initialGroups)
                    this.groupOptions$.next(initialGroups)
                    this.filteredGroups$.next(initialGroups)
                })
        )

        this.subscriptions.add(
            this.searchControl.valueChanges.subscribe((value) => {
                if (value) {
                    this.filteredGroups$.next(
                        this.groupOptions$.value?.filter((groupOption) =>
                            groupOption.name
                                .toLowerCase()
                                .includes(value?.toLowerCase())
                        )
                    )
                } else {
                    this.filteredGroups$.next(this.groupOptions$.value)
                }
            })
        )

        this.subscriptions.add(
            this.groupOptions$.subscribe((groups) => {
                this.groupOptions = groups
                if (
                    groups &&
                    groups.length >= this.searchFieldThreshold &&
                    !this.showSearchField$.value
                ) {
                    this.showSearchField$.next(true)
                } else if (
                    (!groups || groups.length < this.searchFieldThreshold) &&
                    !this.showSearchField$.value
                ) {
                    this.showSearchField$.next(false)
                }
            })
        )

        this.subscriptions.add(
            combineLatest([
                this.initialGroupOptions$,
                this.groupOptions$,
            ]).subscribe(([initialGroups, selectedGroups]) => {
                if (initialGroups && selectedGroups) {
                    this.hasGroupSelectionChanged = deepEqual(
                        initialGroups,
                        selectedGroups
                    )
                }
            })
        )

        this.subscriptions.add(
            this.groupOptions$.subscribe(
                (groups) =>
                    (this.isAnyGroupSelected =
                        !!groups &&
                        groups?.filter((group) => group.isSelected).length > 0)
            )
        )
    }

    toggleGroup(id: string) {
        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.DropdownToggleGroup,
            label: this.groupOptions?.find((group) => group.id === id)?.name,
        })

        this.groupOptions$.next(
            this.groupOptions?.map((group) => ({
                ...group,
                isSelected:
                    group.id === id ? !group.isSelected : group.isSelected,
            }))
        )
    }

    viewGroupDetails(id: string) {
        this.emitCloseDialog.emit()

        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.DropdownEditGroup,
            label: this.groupOptions?.find((group) => group.id === id)?.name,
        })

        this.groupManagementService.openLayer(id)
    }

    openSettings() {
        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.DropdownSettings,
        })
        this.router.navigate(['group-management'])
    }

    createNewGroup() {
        this.emitCloseDialog.emit()

        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.DropdownCreateGroup,
        })

        this.groupManagementService.openLayer()
    }

    handleReset() {
        this.emitCloseDialog.emit()

        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.DropdownReset,
        })

        this.groupOptions$.next(
            this.groupOptions?.map((group) => ({
                ...group,
                isSelected: false,
            }))
        )
        this.applyChanges()
    }

    applyChanges() {
        this.emitCloseDialog.emit()

        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.DropdownApply,
        })

        this.store.dispatch(
            changeGroupSelection({
                groupSelection:
                    this.groupOptions$.value?.map((groupOption) => ({
                        id: groupOption.id,
                        selected: groupOption.isSelected,
                    })) ?? [],
            })
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
