import {
    AnalyticsCategory,
    AnalyticsSidebarAction,
} from '@analytics-lib/analytics.model'
import { Component } from '@angular/core'
import { AuthzService } from '@auth-util-lib/authz.service'
import { FeatureFlagsService } from '@feature-flag-lib/feature-flags.service'
import { TranslationService } from '@localization-lib/language/translation.service'
import { removeHiddenEntries } from '@platform-lib/components/sidebar/utils/removeHiddenEntries'
import { OverlayAnimatedContent } from '@shared-ui-lib/overlay/overlay-animated-content'
import { Animations } from '@shared-util-lib/animations/animations'
import {
    MenuEntryFeatureFlag,
    MenuEntryWithTranslatedLabel,
} from '@shared-util-lib/models/menu-entry.model'
import { Observable, combineLatest, zip } from 'rxjs'
import { filter, map, shareReplay, startWith } from 'rxjs/operators'
import { sideMenuSections, sideMenuSectionsOrder } from './sidebar.config'
import { removeForbiddenEntries } from './utils/removeForbiddenEntries'
import { sortEntries } from './utils/sortEntries'
import { translateEntries } from './utils/translateEntries'

@Component({
    selector: 'app-sidebar',
    styleUrls: ['./sidebar.component.scss'],
    templateUrl: './sidebar.component.html',
    animations: [Animations.slideContentLeft],
})
export class SidebarComponent extends OverlayAnimatedContent {
    AnalyticsCategory = AnalyticsCategory
    AnalyticsSidebarAction = AnalyticsSidebarAction

    // collect feature flag values
    featureFlags$ = zip(
        ...sideMenuSectionsOrder
            .map((key) => sideMenuSections[key])
            .reduce((acc, section) => [...acc, ...section], [])
            .reduce(
                (acc, { requiredFeatureFlags }) => [
                    ...acc,
                    ...(requiredFeatureFlags || []),
                ],
                [] as MenuEntryFeatureFlag<unknown>[]
            )
            .map(({ featureFlag }) =>
                this.featureFlagService.getFeatureFlag(featureFlag).pipe(
                    map((value) => ({
                        name: featureFlag.name,
                        value,
                    }))
                )
            )
    )

    entriesList$: Observable<Map<string, MenuEntryWithTranslatedLabel[]>> =
        combineLatest([
            this.translate.translator$,
            this.authz.actions$,
            this.featureFlags$.pipe(startWith(null)),
        ]).pipe(
            map(
                ([translator, actions, featureFlagValues]) =>
                    sideMenuSectionsOrder
                        .map((key) => sideMenuSections[key])
                        .map(removeForbiddenEntries(actions))
                        .map(removeHiddenEntries(featureFlagValues))
                        .map(translateEntries(translator))
                        .map(sortEntries)
                        .map((entries, index) => [
                            translator(sideMenuSectionsOrder[index]),
                            entries,
                        ])
                        .filter(([, entries]) => !!entries.length) as Iterable<
                        readonly [string, MenuEntryWithTranslatedLabel[]]
                    >
            ),
            map((preparedEntryList) => new Map(preparedEntryList)),
            filter((entryMap) => entryMap.size > 0),
            shareReplay(1)
        )

    // used to preserve the original order of entriesList$
    originalOrder = () => 0

    constructor(
        private authz: AuthzService,
        private translate: TranslationService,
        private featureFlagService: FeatureFlagsService
    ) {
        super()
    }
}
