import { Injectable } from '@angular/core'
import { isDevelopment } from '@env-lib/isDevelopment'
import { GoogleTagManagerService } from 'angular-google-tag-manager'

@Injectable({
    providedIn: 'root',
})
export class GtmService {
    constructor(private service: GoogleTagManagerService) {}

    pushTag(item: Record<string, unknown>) {
        this.service.pushTag(item).catch((e) => {
            // suppress datadog errors on local
            // cause: errors were cluttering the console making debugging harder
            if (!isDevelopment()) {
                throw e
            }
        })
    }
}
