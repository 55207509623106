import {
    AnalyticsCategory,
    AnalyticsGroupManagementAction,
} from '@analytics-lib/analytics.model'
import { AnalyticsService } from '@analytics-lib/analytics.service'
import { Component, Injector, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms'
import { GroupManagementQuitDialogComponent } from '@group-management-lib/components/group-management-quit-dialog/group-management-quit-dialog.component'
import {
    ParsedAccessPeriod,
    Wagon,
    WagonId,
} from '@group-management-lib/group-management.model'
import { GroupManagementService } from '@group-management-lib/group-management.service'
import {
    createGroup,
    loadGroupsWithResources,
    loadPossibleWagonsForUser,
} from '@group-management-lib/redux/group-management.actions'
import {
    selectChangeSelectedWagonsForGroupError,
    selectChangeSelectedWagonsForGroupLoading,
    selectCreateGroupError,
    selectCreateGroupLoading,
    selectPossibleWagonsForUserError,
    selectUserSelectionForNewGroup,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { resetSteps } from '@group-management-lib/util/stepperControls'
import { validateGroupName } from '@group-management-lib/validators/validateGroupName'
import { Store, select } from '@ngrx/store'
import { DialogService } from '@shared-ui-lib/dialog/dialog.service'
import { OverlayAnimatedContent } from '@shared-ui-lib/overlay/overlay-animated-content'
import { Animations } from '@shared-util-lib/animations/animations'
import {
    BehaviorSubject,
    ReplaySubject,
    Subject,
    Subscription,
    combineLatest,
    distinctUntilChanged,
    startWith,
    withLatestFrom,
} from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
    selector: 'app-group-management-creator',
    templateUrl: './group-management-creator.component.html',
    styleUrls: ['./group-management-creator.component.scss'],
    animations: [Animations.slideContentRight],
})
export class GroupManagementCreatorComponent
    extends OverlayAnimatedContent
    implements OnDestroy, OnInit
{
    // delegate shared functions
    protected readonly resetSteps = resetSteps

    /*
        have to inject Service this way, otherwise would lead to
        "uncaught ReferenceError: Cannot access "GroupManagementService" before initialization"
        alternative: set "emitDecoratorMetadata" to false in tsconfig.base.ts
    */
    groupManagementService = this.inj.get(GroupManagementService)

    groupNameControl = new UntypedFormControl('', [
        Validators.required,
        validateGroupName(),
    ])

    // TODO combine with other controls
    groupNameFormGroup = this.formBuilder.group({
        groupName: this.groupNameControl,
    })

    readonly colleagues = this.store.pipe(
        select(selectUserSelectionForNewGroup)
    )
    readonly selectedUserIds = new ReplaySubject<string[]>(1)
    selectedAccessPeriod$ = new ReplaySubject<ParsedAccessPeriod>(1)
    private readonly saveTriggered$ = new Subject()

    selectedWagons$ = new BehaviorSubject<Wagon[]>([])
    containsInvalidWagonNumberEntries$ = new BehaviorSubject<boolean>(true)

    loadPossibleWagonsError$ = this.store.pipe(
        select(selectPossibleWagonsForUserError)
    )

    createGroupError$ = this.store.pipe(select(selectCreateGroupError))
    createGroupLoading$ = this.store.pipe(select(selectCreateGroupLoading))

    changeWagonSelectionLoading$ = this.store.pipe(
        select(selectChangeSelectedWagonsForGroupLoading)
    )
    changeWagonSelectionError$ = this.store.pipe(
        select(selectChangeSelectedWagonsForGroupError)
    )

    savingErrorOccurred$ = new BehaviorSubject<boolean>(false)

    private subscriptions = new Subscription()

    readonly isExtendedGroupManagementAllowed$ =
        this.groupManagementService.isAdminInGacClient$

    // show stepper only if it has a second page, i.e. if group members can be managed
    readonly showStepper$ = this.isExtendedGroupManagementAllowed$.pipe(
        distinctUntilChanged()
    )
    readonly stepIndex = new BehaviorSubject(0)

    readonly hasNextStep$ = combineLatest([
        this.stepIndex,
        this.showStepper$,
    ]).pipe(map(([index, showStepper]) => showStepper && index === 0))
    readonly hasPreviousStep$ = combineLatest([
        this.stepIndex,
        this.showStepper$,
    ]).pipe(map(([index, showStepper]) => showStepper && index === 1))

    constructor(
        private inj: Injector,
        public formBuilder: FormBuilder,
        private analyticsService: AnalyticsService,
        private dialogService: DialogService,
        private store: Store<GroupManagementState>
    ) {
        super()

        this.subscriptions.add(
            combineLatest([
                this.createGroupError$,
                this.changeWagonSelectionError$,
            ]).subscribe(([createGroupError, changeWagonSelectionError]) => {
                this.savingErrorOccurred$.next(
                    createGroupError !== null ||
                        changeWagonSelectionError !== null
                )
            })
        )

        this.subscriptions.add(
            this.saveTriggered$
                .pipe(
                    withLatestFrom(
                        this.selectedUserIds.pipe(startWith([])),
                        this.selectedAccessPeriod$.pipe(
                            startWith({} as ParsedAccessPeriod)
                        ),
                        this.isExtendedGroupManagementAllowed$
                    )
                )
                .subscribe(
                    ([
                        _,
                        selectedUserIds,
                        selectedAccessPeriod,
                        isExtendedGroupManagementAllowed,
                    ]) => {
                        const groupName =
                            this.groupNameFormGroup.controls.groupName.value
                        const selectedWagons = this.selectedWagons$.value.map(
                            (wagon) => ({ id: wagon.wagonId }) as WagonId
                        )
                        this.store.dispatch(
                            createGroup({
                                groupName,
                                selectedWagons,
                                selectedUserIds,
                                selectedAccessPeriod,
                                isExtendedGroupManagementAllowed:
                                    isExtendedGroupManagementAllowed,
                            })
                        )
                    }
                )
        )
    }

    ngOnInit() {
        this.store.dispatch(loadGroupsWithResources())
        this.store.dispatch(loadPossibleWagonsForUser())
    }

    disableCreateButton() {
        return (
            !this.groupNameFormGroup.valid ||
            this.containsInvalidWagonNumberEntries$.value
        )
    }

    requestClose(onCloseCallback?: () => void) {
        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.CreatorClose,
        })
        if (
            (this.groupNameFormGroup.controls.groupName.value === '' &&
                this.selectedWagons$.value.length === 0) ||
            this.savingErrorOccurred$.value
        ) {
            this.groupManagementService.closeLayer()
            onCloseCallback?.()
        } else {
            this.dialogService
                .openDialog(GroupManagementQuitDialogComponent, {
                    title: 'Quit creating a new group?',
                    body: 'The group will not be created.',
                    quitButton: 'Quit',
                    continueButton: 'Continue creating',
                })
                .afterClosed()
                .subscribe((quit) => {
                    if (quit) {
                        this.analyticsService.trackEvent({
                            category: AnalyticsCategory.GroupManagement,
                            action: AnalyticsGroupManagementAction.DialogQuitCreating,
                        })
                        this.groupManagementService.closeLayer()
                        onCloseCallback?.()
                    } else {
                        this.analyticsService.trackEvent({
                            category: AnalyticsCategory.GroupManagement,
                            action: AnalyticsGroupManagementAction.DialogContinueCreating,
                        })
                    }
                })
        }
    }

    public async selectedWagons(selectedWagons: Wagon[]) {
        this.selectedWagons$.next(
            selectedWagons.map(
                (wagon) =>
                    ({
                        wagonId: wagon.wagonId,
                        uicClass: wagon.uicClass,
                        wagonNumber: wagon.wagonNumber,
                    }) as Wagon
            )
        )
    }

    public async containsInvalidWagonNumberEntries(
        containsInvalidWagonNumberEntries: boolean
    ) {
        this.containsInvalidWagonNumberEntries$.next(
            containsInvalidWagonNumberEntries
        )
    }

    public async selectAccessPeriod(selectedAccessPeriod: ParsedAccessPeriod) {
        this.selectedAccessPeriod$.next(selectedAccessPeriod)
    }

    handleFormSubmit() {
        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.CreatorSubmit,
        })

        this.saveTriggered$.next(true)
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
