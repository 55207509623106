import { Injectable } from '@angular/core'
import { UrlSegment } from '@angular/router'
import {
    AppActivatedRouteSnapshot,
    AppRoute,
} from '@auth-util-lib/routing.model'
import { combineLatest, Observable, of } from 'rxjs'
import { filter, first, map, switchMap, tap } from 'rxjs/operators'
import { Action, Unprotected } from '../auth.model'
import { AuthnService } from '../authn.service'
import { AuthzService } from '../authz.service'

@Injectable({ providedIn: 'root' })
export class AuthzGuard {
    constructor(
        private readonly authz: AuthzService,
        private readonly authn: AuthnService
    ) {}

    canLoad(route: AppRoute, segments: UrlSegment[]): Observable<boolean> {
        return this.isAllowed(route)
    }

    canActivate(route: AppActivatedRouteSnapshot): Observable<boolean> {
        return this.isAllowed(route)
    }

    private isAllowed(route: AppRoute | AppActivatedRouteSnapshot) {
        return this.authz.authorizedForTraigo$.pipe(
            first(),
            tap((authorizedForTraigo) => {
                if (!authorizedForTraigo) {
                    this.authn.initSignOutFlow('/unregistered')
                }
            }),
            filter((authorizedForTraigo) => authorizedForTraigo),
            switchMap((authorizedForTraigo) => {
                if (!authorizedForTraigo) {
                    return of(false)
                }
                if (route.data.protection === Unprotected) {
                    return of(true)
                }
                return this.hasAnyAction(route.data.protection)
            })
        )
    }

    private hasAnyAction(input: Action | Action[]) {
        if (Array.isArray(input)) {
            return combineLatest(
                input.map((action) => this.authz.hasAction$(action))
            ).pipe(
                first(),
                map((hasActions) => hasActions.some((has) => has))
            )
        } else {
            return this.authz.hasAction$(input).pipe(first())
        }
    }
}
