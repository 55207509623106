<mat-icon
    #bellIcon
    svgIcon="notification"
    aria-label="notifications"
    class="bell-icon"
    [class.ringing]="bellRinging$ | async"
></mat-icon>

<span
    *ngIf="unreadCountLabel$ | async; let count"
    class="bell-bubble"
    aria-label="unread notifications"
>
    {{ count }}
</span>
