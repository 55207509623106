<img class="logo" src="../../../../assets/images/traigo-logo.svg" />
<div class="content-box">
    <h3 class="content-box__title" translate>You have been logged out</h3>
    <p class="content-box__text" translate>
        Your account has not been activated for traigo.
    </p>
    <app-button-group alignment="center">
        <app-button (buttonClick)="this.continue()" buttonType="flat-primary">
            <translate> Go back to landing page </translate>
        </app-button>
    </app-button-group>
</div>
<div class="contact">
    <p translate>
        You do not have login data yet? Contact our customer service
    </p>
    <a class="contact__mailto" href="mailto:servicedesk@traigo.com"
        >servicedesk&#64;traigo.com</a
    >
</div>
