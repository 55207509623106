<div
    [@slideContentRight]="animationState"
    (@slideContentRight.start)="onAnimationStart($event)"
    (@slideContentRight.done)="onAnimationDone($event)"
    class="h-full flex flex-col bg-white creator-root"
>
    <div class="flex flex-row flex-0 items-center px-6 py-2">
        <translate class="text-subtitle-1 flex-1">New Group</translate>
        <app-button
            buttonType="ghost"
            class="close-dialog"
            iconButton
            icon="close"
            (buttonClick)="requestClose()"
        ></app-button>
    </div>
    <mat-divider></mat-divider>

    <app-system-response
        *ngIf="loadPossibleWagonsError$ | async; else createGroupError"
        responseType="error"
        title="LoadPossibleWagonsError"
    ></app-system-response>

    <ng-template #createGroupError>
        <app-system-response
            *ngIf="savingErrorOccurred$ | async; else creatorContent"
            small
            responseType="error"
            title="CreateGroupError"
        ></app-system-response>
    </ng-template>

    <ng-template #creatorContent>
        <div class="flex flex-col flex-1 overflow-auto px-6">
            <div *ngIf="(showStepper$ | async) === false" class="pt-6"></div>
            <mat-stepper
                #stepper
                (selectionChange)="resetSteps(stepper)"
                (selectedIndexChange)="stepIndex.next(stepper.selectedIndex)"
                [ngClass]="{
                    'hidden-header': (showStepper$ | async) === false,
                }"
            >
                <mat-step>
                    <ng-template matStepLabel>
                        <translate>Basics</translate>
                    </ng-template>

                    <form
                        [formGroup]="groupNameFormGroup"
                        class="flex flex-col"
                    >
                        <span translate class="text-body-3 pb-6">Name</span>
                        <mat-form-field appearance="outline" class="w-80 pb-6">
                            <mat-label translate>Set a group name</mat-label>
                            <input
                                matInput
                                required
                                [placeholder]="'Set a group name' | translate"
                                formControlName="groupName"
                                [maxLength]="30"
                            />
                            <mat-error
                                *ngIf="
                                    groupNameFormGroup.get('groupName')?.errors
                                        ?.required ||
                                    groupNameFormGroup.get('groupName')?.errors
                                        ?.validateGroupName
                                "
                                translate
                            >
                                Please provide a group name
                            </mat-error>
                        </mat-form-field>
                    </form>
                    <app-group-management-wagon-number-input
                        (selectedWagons)="selectedWagons($event)"
                        (containsInvalidWagonNumberEntries)="
                            containsInvalidWagonNumberEntries($event)
                        "
                    ></app-group-management-wagon-number-input>
                </mat-step>
                <mat-step *ngIf="isExtendedGroupManagementAllowed$ | async">
                    <ng-template matStepLabel>
                        <translate>Share group</translate>
                    </ng-template>
                    <app-group-management-user-selection
                        [keepAdmins]="false"
                        [colleagues]="colleagues | async"
                        (selectedUserIds)="selectedUserIds.next($event)"
                    ></app-group-management-user-selection>
                    <app-group-management-access-period-selection
                        (selectedAccessPeriod)="selectAccessPeriod($event)"
                    >
                    </app-group-management-access-period-selection>
                </mat-step>
            </mat-stepper>
        </div>
        <div class="flex flex-col flex-0 p-6">
            <div class="flex justify-end">
                <app-button
                    *ngIf="hasPreviousStep$ | async"
                    class="mr-4"
                    buttonType="raised-basic"
                    (buttonClick)="stepper.previous()"
                >
                    <translate>Back</translate>
                </app-button>
                <app-button
                    [buttonType]="
                        (hasNextStep$ | async) ? 'raised-basic' : 'flat-primary'
                    "
                    (buttonClick)="handleFormSubmit()"
                    [disabled]="disableCreateButton()"
                    [status]="
                        (createGroupLoading$ | async) ||
                        (changeWagonSelectionLoading$ | async)
                            ? 'loading'
                            : 'default'
                    "
                >
                    <translate>Create</translate>
                </app-button>
                <app-button
                    *ngIf="hasNextStep$ | async"
                    class="ml-4"
                    buttonType="flat-primary"
                    (buttonClick)="stepper.next()"
                >
                    <translate>Next</translate>
                </app-button>
            </div>
        </div>
    </ng-template>
</div>
