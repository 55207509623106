import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DateInDefaultTimezonePipe } from '@localization-lib/date-time/pipes/date-in-default-timezone/date-in-default-timezone.pipe'

@NgModule({
    declarations: [DateInDefaultTimezonePipe],
    exports: [DateInDefaultTimezonePipe],
    imports: [CommonModule],
    providers: [DateInDefaultTimezonePipe],
})
export class DateInDefaultTimezonePipeModule {}
