import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UrlSerializer } from '@angular/router'
import {
    Notification,
    NotificationPriority,
    NotificationUseCase,
} from '@platform-lib/notification/notification.model'
import {
    AnalyticsCategory,
    AnalyticsNotificationAction,
} from '@analytics-lib/analytics.model'

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent {
    AnalyticsCategory = AnalyticsCategory
    AnalyticsNotificationAction = AnalyticsNotificationAction

    @Input() notification?: Notification
    @Input() disableBookmark?: boolean
    @Input() disableDelete?: boolean
    @Input() deleting?: boolean

    @Output() readonly itemClick = new EventEmitter()
    @Output() readonly itemBookmarkClick = new EventEmitter()
    @Output() readonly itemDeleteClick = new EventEmitter()

    NotificationPriority = NotificationPriority

    readonly useCaseIcons: Record<keyof typeof NotificationUseCase, string> = {
        Auth: 'user-management',
        Booking: 'booking',
        Contract: 'contract-management',
        Fleet: 'fleet-management',
        FastTrack: 'fast-track',
        TraigoUpdates: 'traigo-updates',
        WagonHire: 'wagon-hire',
        Geofence: 'geofence',
        GroupManagement: 'group-management',
        DataForwarding: 'data-forwarding',
    }

    get useCaseIcon() {
        const { notification, useCaseIcons } = this
        return (notification && useCaseIcons[notification.useCase]) || null
    }

    get notificationLink() {
        const path = this.notification?.path || ''
        const prefix = path.indexOf('/') === 0 ? '' : '/'
        return {
            path: `${prefix}${path.split('?')[0]}`,
            params: this.urlSerializer.parse(path).queryParams,
        }
    }

    get maskedNotificationPath() {
        const path = this.notification?.path || ''
        return path.replace(/\d/g, 'x')
    }

    constructor(private urlSerializer: UrlSerializer) {}

    onClick(event: MouseEvent) {
        if (this.deleting) {
            event.stopImmediatePropagation()
        }
        this.itemClick.emit()
    }

    onBookmarkClick(event: MouseEvent) {
        event.stopImmediatePropagation()
        if (!this.disableBookmark) {
            this.itemBookmarkClick.emit()
        }
    }

    onDeleteClick(event: MouseEvent) {
        event.stopImmediatePropagation()
        if (!this.deleting) {
            this.itemDeleteClick.emit()
        }
    }
}
