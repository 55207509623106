import { ComponentType } from '@angular/cdk/overlay'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { DialogWrapperComponent } from '@shared-ui-lib/dialog/dialog-wrapper.component'

const DIALOG_WIDTH = '400px'
const MODAL_WIDTH = '550px'
const POPUP_WIDTH = '950px'
@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private _showCloseButton = false

    public set showCloseButton(value: boolean) {
        this._showCloseButton = value
    }

    public get showCloseButton() {
        return this._showCloseButton
    }

    constructor(private dialog: MatDialog) {}

    openDialog(component: ComponentType<unknown>, data?: object) {
        this.showCloseButton = false

        return this.dialog.open(component, {
            data,
            autoFocus: false,
            hasBackdrop: true,
            width: DIALOG_WIDTH,
            disableClose: true,
        })
    }

    openSimpleDialog(
        title: string,
        bodyText: string,
        actionButtonText: string
    ) {
        return this.openDialog(DialogWrapperComponent, {
            simpleDialog: { title, bodyText, actionButtonText },
        })
    }

    openModal(
        component: ComponentType<unknown>,
        closeButton?: boolean,
        data?: object
    ) {
        this.showCloseButton = closeButton ?? false

        return this.dialog.open(component, {
            data,
            autoFocus: false,
            hasBackdrop: true,
            width: MODAL_WIDTH,
            disableClose: !this.showCloseButton,
        })
    }

    openPopup(
        component: ComponentType<unknown>,
        small?: boolean,
        data?: object
    ) {
        this.showCloseButton = true

        return this.dialog.open(component, {
            data: { hideActions: true, ...data },
            autoFocus: false,
            hasBackdrop: true,
            width: small ? MODAL_WIDTH : POPUP_WIDTH,
            disableClose: !this.showCloseButton,
        })
    }

    closeDialog() {
        this.dialog.closeAll()
    }
}
