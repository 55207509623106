import { Component, EventEmitter, Input, Output } from '@angular/core'
import {
    CookieConsent,
    CookieConsentId,
} from '@platform-lib/legal/cookie/cookie.consent.model'
import { Animations } from '@shared-util-lib/animations/animations'

@Component({
    animations: [Animations.flipIcon],
    selector: 'app-expandable-cookie',
    styleUrls: ['./expandable-cookie.component.scss'],
    templateUrl: './expandable-cookie.component.html',
})
export class ExpandableCookieComponent {
    @Input()
    cookiesAllowed: CookieConsent | null = null

    @Input()
    consentId: CookieConsentId | null = null

    @Input()
    expandedId: CookieConsentId | null = null

    @Output()
    readonly expandedIdChange = new EventEmitter<CookieConsentId | null>()

    handleExpandedChange(expanded: boolean) {
        this.expandedIdChange.emit(expanded ? this.consentId : null)
    }

    handleCheckbox(e: MouseEvent) {
        e.stopPropagation()
    }
}
