import { AnalyticsEvent } from '@analytics-lib/analytics.model'
import { AnalyticsService } from '@analytics-lib/analytics.service'
import { Injectable } from '@angular/core'
import { StatusType } from '@datadog/browser-logs'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'

@Injectable({
    providedIn: 'root',
})
export class TrackEventEmitterService {
    constructor(
        private analytics: AnalyticsService,
        private datadogService: DatadogService
    ) {}

    emitEvent<Event extends AnalyticsEvent>(
        event: {
            category?: Event['category']
            action?: Event['action']
            label?: string
        },
        suppressErrors = false
    ) {
        if (event?.category && event?.action) {
            this.analytics.trackEvent(event as Event)
        } else if (!suppressErrors) {
            this.datadogService.log(
                'Trying to track event without category or action: ' +
                    JSON.stringify(event),
                StatusType.warn
            )
        }
    }
}
