import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DataQaTagPipe } from './data-qa-tag.pipe'

@NgModule({
    declarations: [DataQaTagPipe],
    exports: [DataQaTagPipe],
    imports: [CommonModule],
    providers: [DataQaTagPipe],
})
export class DataQaTagPipeModule {}
