import { Injectable } from '@angular/core'
import { CookieService } from './cookie.service'

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {
    constructor(private cookieService: CookieService) {}

    set(key: string, value: string) {
        this.cookieService.ensureEnabled()
        sessionStorage.setItem(key, value)
    }

    get(key: string): string | null {
        if (!this.cookieService.isEnabled()) {
            return null
        }
        return sessionStorage.getItem(key)
    }

    delete(key: string) {
        this.cookieService.ensureEnabled()
        sessionStorage.removeItem(key)
    }

    has(key: string): boolean {
        if (!this.cookieService.isEnabled()) {
            return false
        }
        const value = this.get(key)
        return !!value
    }
}
