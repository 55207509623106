import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core'
import { UserSelection } from '@group-management-lib/group-management.model'
import { FormControl, FormRecord } from '@angular/forms'
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    Observable,
    ReplaySubject,
    startWith,
    Subscription,
    switchMap,
} from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { isTruthy } from '@util-lib/isTruthy'
import { TranslationService } from '@localization-lib/language/translation.service'

@Component({
    selector: 'app-group-management-user-selection',
    templateUrl: './group-management-user-selection.component.html',
    styleUrls: ['./group-management-user-selection.component.scss'],
})
export class GroupManagementUserSelectionComponent implements OnDestroy {
    @Input()
    set keepAdmins(value: boolean | null) {
        if (value !== null) {
            this.keepAdmins$.next(value)
        }
    }

    @Input()
    set colleagues(value: UserSelection[] | null) {
        if (value) {
            this.colleagues$.next(value)
        }
    }

    @Output() readonly selectedUserIds = new EventEmitter<string[]>()

    readonly keepAdmins$ = new ReplaySubject<boolean>(1)

    // Emits an array of all colleagues and their group membership status
    readonly colleagues$ = new BehaviorSubject<UserSelection[]>([])

    readonly selectAllUsersControl = new FormControl(false)

    private readonly disabledAdmins$ = combineLatest([
        this.keepAdmins$,
        this.colleagues$,
    ]).pipe(
        map(([keepAdmin, colleagues]) =>
            colleagues.filter(
                (colleague) =>
                    keepAdmin && colleague.isAdmin && colleague.isMember
            )
        )
    )

    readonly disabledAdminsTooltip$: Observable<string> = combineLatest([
        this.disabledAdmins$,
        this.translationService.getTranslation$('AdminAlwaysInDefaultGroup'),
    ]).pipe(
        map(([disabledAdmins, tooltip]) =>
            disabledAdmins.length > 0 ? tooltip : ''
        )
    )

    readonly usersFormRecord$: Observable<FormRecord> = combineLatest([
        this.disabledAdmins$,
        this.colleagues$,
        this.selectAllUsersControl.valueChanges.pipe(startWith(null)),
    ]).pipe(
        map(([disabledAdmins, colleagues, selectAllChecked]) => {
            const formRecord = new FormRecord({})
            colleagues
                .sort((a, b) => {
                    const result = a.firstName.localeCompare(b.firstName)
                    return result !== 0
                        ? result
                        : a.lastName.localeCompare(b.lastName)
                })
                .forEach((user) => {
                    const isUserDisabledAdmin = disabledAdmins.some(
                        (admin) => admin.userId === user.userId
                    )
                    formRecord.addControl(
                        user.userId,
                        new FormControl({
                            value: this.overrideUserSelection(
                                user,
                                selectAllChecked,
                                isUserDisabledAdmin
                            ),
                            disabled: isUserDisabledAdmin,
                        })
                    )
                })
            return formRecord
        }),
        shareReplay(1)
    )

    // Values of the user selections.
    private readonly userSelections$ = this.usersFormRecord$.pipe(
        switchMap((formRecord) =>
            formRecord.valueChanges.pipe(startWith(formRecord.value))
        )
    )

    private readonly allSelectedIndividually$: Observable<boolean> =
        this.userSelections$.pipe(
            map((userSelections) =>
                Object.values(userSelections).every(isTruthy)
            ),
            distinctUntilChanged()
        )

    readonly someSelected$: Observable<boolean> = combineLatest([
        this.disabledAdmins$,
        this.userSelections$,
    ]).pipe(
        map(([disabledAdmins, userSelections]) => {
            const values = Object.values(userSelections)
            const someSelected =
                disabledAdmins.length > 0 || values.some((selected) => selected)
            const allSelected = values.every((selected) => selected)
            return someSelected && !allSelected
        }),
        distinctUntilChanged()
    )

    private readonly subscriptions = new Subscription()

    constructor(private readonly translationService: TranslationService) {
        this.subscriptions.add(
            this.allSelectedIndividually$.subscribe((allSelected: boolean) => {
                this.selectAllUsersControl.setValue(allSelected, {
                    emitEvent: false,
                })
            })
        )

        this.subscriptions.add(
            combineLatest([this.disabledAdmins$, this.userSelections$])
                .pipe(
                    map(
                        ([disabledAdmins, userSelections]) =>
                            disabledAdmins.length > 0 &&
                            disabledAdmins.length ===
                                Object.keys(userSelections).length
                    ),
                    distinctUntilChanged()
                )
                .subscribe((disable) => {
                    if (disable) {
                        this.selectAllUsersControl.disable({ emitEvent: false })
                    } else {
                        this.selectAllUsersControl.enable({ emitEvent: false })
                    }
                })
        )

        this.subscriptions.add(
            combineLatest([
                this.disabledAdmins$,
                this.userSelections$,
            ]).subscribe(([disabledAdmins, userSelections]) => {
                const disabledAdminIds = disabledAdmins.map(
                    (admin) => admin.userId
                )
                const selectedUserIds = Object.entries(userSelections)
                    .filter(([_, selected]) => selected)
                    .map(([userId, _]) => userId)
                const uniqueUserIds = [
                    ...new Set([...disabledAdminIds, ...selectedUserIds]),
                ]
                this.selectedUserIds.emit(uniqueUserIds)
            })
        )
    }

    private readonly overrideUserSelection = (
        user: UserSelection,
        selectAllIsChecked: boolean | null,
        isUserDisabledAdmin: boolean
    ): boolean => {
        if (selectAllIsChecked === null) {
            return user.isMember
        }
        return !selectAllIsChecked && isUserDisabledAdmin
            ? true
            : selectAllIsChecked
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
