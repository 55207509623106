import { UntypedFormControl } from '@angular/forms'

export const MAX_NOTIFICATION_CHUNK_SIZE = 50

export interface NotificationCountResponse {
    unreadCount: number
}

// order of values is relevant for rendering of filters
export enum NotificationUseCase {
    Auth = 'Auth',
    Booking = 'Booking',
    Contract = 'Contract',
    Fleet = 'Fleet',
    FastTrack = 'FastTrack',
    TraigoUpdates = 'TraigoUpdates',
    WagonHire = 'WagonHire',
    Geofence = 'Geofence',
    GroupManagement = 'GroupManagement',
    DataForwarding = 'DataForwarding',
}

// order of values is relevant for rendering of filters
export enum NotificationPriority {
    Alert = 'Alert',
    Warn = 'Warn',
    Info = 'Info',
}

export interface Notification {
    id: string
    isMarked: boolean
    isRead: boolean
    occurredAt: string
    path: string
    priority: NotificationPriority
    useCase: NotificationUseCase
    text: string
}

export interface NotificationsRequest {
    limitNumberOfNotifications?: number
}

export interface NotificationsResponse {
    notifications: Notification[]
    hasMoreNotifications: boolean
}

export interface NotificationsActionRequest {
    notificationIds: string[]
}

export interface NotificationsActionRequestWithValue
    extends NotificationsActionRequest {
    value: boolean
}

export interface NotificationChunk {
    start: string
    finish: string
    notifications: Notification[]
}

export interface NotificationFilter<T> {
    label: string
    selected: UntypedFormControl
    value: T
}

export interface NotificationFilters {
    isMarked: NotificationFilter<boolean>
    priority: Map<
        NotificationPriority,
        NotificationFilter<NotificationPriority>
    >
    useCase: Map<NotificationUseCase, NotificationFilter<NotificationUseCase>>
}

export interface NotificationFilterValues {
    isMarked: boolean
    priority: NotificationPriority[]
    useCase: NotificationUseCase[]
}
