import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { NotificationEffects } from '@platform-lib/notification/redux/notification.effects'
import { notificationReducer } from '@platform-lib/notification/redux/notification.reducer'
import {
    notificationFeatureKey,
    NotificationState,
} from '@platform-lib/notification/redux/notification.state'

/**
 * Why a designated angular-redux module for notifications you ask!
 *
 * This module is loaded immediately since it's needed by the notification-panel.
 * It couldn't be part of notification.module, since that module is lazily loaded.
 */

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([NotificationEffects]),
        StoreModule.forFeature<NotificationState>(
            notificationFeatureKey,
            notificationReducer
        ),
    ],
})
export class NotificationStoreModule {}
