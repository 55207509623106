import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from '@env-lib/api/api.service'
import {
    ServiceDeskStatus,
    ServiceDeskStatusResponse,
} from '@platform-lib/legal/contact/service-desk-status/service-desk-status.model'

@Injectable({
    providedIn: 'root',
})
export class ServiceDeskStatusService {
    constructor(
        private api: ApiService,
        private http: HttpClient
    ) {}

    getServiceDeskStatus() {
        const url = `${this.api.serviceDesk.backendUrl}/serviceupdate/status`
        return this.http.get<ServiceDeskStatusResponse>(url)
    }

    putServiceDeskStatus(status: ServiceDeskStatus, en: string, de: string) {
        const url = `${this.api.serviceDesk.backendUrl}/serviceupdate/status`
        const payload: ServiceDeskStatusResponse = {
            status,
            message: { en, de },
        }
        return this.http.put<void>(url, payload)
    }
}
