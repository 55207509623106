import { Injectable } from '@angular/core'
import { environment } from './environment'
import { isDevEnvironment } from './isDevEnvironment'
import { isDevelopment } from './isDevelopment'
import { isIntEnvironment } from './isIntEnvironment'
import { isProdEnvironment } from './isProdEnvironment'
import { isTestCafeTest } from './isTestCafeTest'
import { Stage } from './stage'

@Injectable({
    providedIn: 'root',
})
export class EnvService {
    private _stage?: Stage

    constructor() {
        this.determineStage()
    }

    get stage() {
        return this._stage
    }

    private determineStage() {
        if (environment.stage) {
            this._stage = environment.stage
        } else {
            if (isTestCafeTest() || isDevelopment()) {
                this._stage = Stage.Int
            } else if (isDevEnvironment()) {
                this._stage = Stage.Dev
            } else if (isIntEnvironment()) {
                this._stage = Stage.Int
            } else if (isProdEnvironment()) {
                this._stage = Stage.Prod
            } else {
                console.error(
                    `Cannot find stage for host name ${window?.location?.hostname}`
                )
            }
        }
    }
}
