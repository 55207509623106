import { Pipe, PipeTransform } from '@angular/core'
import { transformUicWagonNumber } from '@shared-util-lib/pipes/uic-wagon-number/transformUicWagonNumber'

@Pipe({
    name: 'uicWagonNumber',
})
export class UicWagonNumberPipe implements PipeTransform {
    transform(wagonNumber: string): string {
        return transformUicWagonNumber(wagonNumber)
    }
}
