import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { AuthnService } from '@auth-util-lib/authn.service'
import { UserProfileService } from '@auth-util-lib/user-profile.service'
import { ApiService } from '@env-lib/api/api.service'
import { SessionStorageService } from '@storage-lib/session-storage.service'
import {
    Observable,
    Subject,
    Subscription,
    combineLatest,
    of,
    switchMap,
} from 'rxjs'
import { catchError, map, shareReplay, startWith } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class LoginTrackingService implements OnDestroy {
    private sessionTrackingSubject = new Subject()
    private subscription = new Subscription()
    private readonly USER_SESSION_LOGGED_STORAGE_KEY = 'user_session_logged'

    private userSessionCountObservable$ = combineLatest([
        this.userProfileService.profileChanges(),
        this.sessionTrackingSubject.pipe(startWith(undefined), shareReplay(1)),
    ]).pipe(
        switchMap(([profile]) =>
            // if profile exists, get session count, otherwise emit -1
            profile
                ? this.getUserSessionCount().pipe(
                      map((data) => (data ? data?.userSessionCount : -1)),
                      catchError(() => of(-1))
                  )
                : of(-1)
        ),
        shareReplay(1)
    )

    userSessionCount$() {
        return this.userSessionCountObservable$
    }

    trackLogin(): Observable<void> {
        return this.http
            .post<void>(
                `${this.apiService.userTracking.backendUrl}/trackings/login`,
                null
            )
            .pipe(catchError(() => of(void 0)))
    }

    trackUserSession(): Observable<void> {
        return this.http
            .post<void>(
                `${this.apiService.userTracking.backendUrl}/trackings/userSession`,
                null
            )
            .pipe(catchError(() => of(void 0)))
    }

    getUserSessionCount() {
        return this.http.get<{ userSessionCount: number }>(
            `${this.apiService.userTracking.backendUrl}/trackings/userSession/count`
        )
    }

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private storage: SessionStorageService,
        private authnService: AuthnService,
        private userProfileService: UserProfileService
    ) {
        this.subscription.add(
            this.authnService.finishSignIn$.subscribe(() => {
                // track login
                this.subscription.add(this.trackLogin().subscribe())
                // track user session
                this.userSessionStart()
            })
        )

        this.subscription.add(
            this.authnService.finishSignOut$.subscribe(() => {
                this.userSessionFinish()
            })
        )
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    userSessionStart() {
        if (!this.storage.has(this.USER_SESSION_LOGGED_STORAGE_KEY)) {
            this.subscription.add(
                this.trackUserSession().subscribe(() => {
                    // set flag in session storage
                    // to avoid re-logging on token refresh
                    this.storage.set(this.USER_SESSION_LOGGED_STORAGE_KEY, '1')
                    // emit change to re-fetch session count from backend
                    this.sessionTrackingSubject.next(undefined)
                })
            )
        }
    }

    userSessionFinish() {
        this.storage.delete(this.USER_SESSION_LOGGED_STORAGE_KEY)
    }
}
