import { AnalyticsEvent } from '@analytics-lib/analytics.model'
import { TrackEventEmitterService } from '@analytics-lib/track-event-emitter.service'
import { TrackOverlayContextDirective } from '@analytics-lib/track-overlay-context/track-overlay-context.directive'
import { findLabel, firstMatchInPath } from '@analytics-lib/track.util'
import { Directive, ElementRef, HostListener, Input } from '@angular/core'

/**
 * Emit tracking events from bound node or its children that match a given selector.
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[trackContext]',
})
export class TrackContextDirective<Event extends AnalyticsEvent> {
    /**
     * Supplied value will be used as a selector for nodes that trigger a tracking event.
     */
    @Input()
    trackContext?: string

    /**
     * Tracking event category.
     */
    @Input()
    trackCategory?: Event['category']

    /**
     * Tracking event action.
     */
    @Input()
    trackAction?: Event['action']

    /**
     * Set the tracking context of overlays
     *
     * @see TrackOverlayContextDirective
     */
    @Input()
    setTrackOverlayContext = true

    @HostListener('mousedown', ['$event'])
    onClick(event: MouseEvent) {
        if (this.trackContext) {
            const element = firstMatchInPath(
                event,
                this.elementRef.nativeElement,
                this.trackContext
            )
            if (element) {
                this.trackEventEmitter.emitEvent(
                    {
                        category: this.trackCategory,
                        action: this.trackAction,
                        label: findLabel(element),
                    },
                    true
                )
            }
        }
        // set global context regardless of trackContext
        if (this.setTrackOverlayContext) {
            TrackOverlayContextDirective.getInstance()?.setContext({
                category: this.trackCategory,
                action: this.trackAction,
            } as AnalyticsEvent)
        }
    }

    constructor(
        private elementRef: ElementRef,
        private trackEventEmitter: TrackEventEmitterService
    ) {}
}
