import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { LoadingModule } from '@loading-lib/loading.module'
import { ButtonComponent } from './button.component'

@NgModule({
    declarations: [ButtonComponent],
    exports: [ButtonComponent],
    imports: [CommonModule, LoadingModule, MatButtonModule, MatIconModule],
})
export class ButtonModule {}
