import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { SnackbarComponent } from './snackbar.component'

@NgModule({
    declarations: [SnackbarComponent],
    exports: [SnackbarComponent],
    imports: [ButtonModule, CommonModule, MatIconModule, TranslationModule],
})
export class SnackbarModule {}
