<app-collection
    [entries]="notificationsSubject | async"
    [entryTemplate]="notificationTpl"
    [loading]="showSkeletons"
    [loadingEntries]="5"
>
    <ng-template #notificationTpl let-notification="entry">
        <app-notification-item
            @itemTrigger
            [@.disabled]="-1 === (unread$ | async)?.indexOf(notification?.id)"
            [notification]="notification"
            [disableBookmark]="!!(bookmarkIds$ | async)?.length"
            [disableDelete]="!!(deleteIds$ | async)?.length"
            [deleting]="-1 < (deleteIds$ | async)?.indexOf(notification.id)"
            (itemClick)="onItemClick(notification)"
            (itemBookmarkClick)="onItemBookmarkClick(notification)"
            (itemDeleteClick)="onItemDeleteClick(notification)"
            class="notification-item"
            appIntersection
            [intersectionRoot]="intersectionRoot"
            intersectionRootMargin="0px"
            [intersectionThreshold]="1"
            (intersectionChange)="onIntersectionChange(notification, $event)"
        ></app-notification-item>
    </ng-template>

    <div empty>
        <app-system-response responseType="empty" small />
    </div>
</app-collection>

<ng-content select="[footer]"></ng-content>
