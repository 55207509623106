import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SkeletonComponent } from '@shared-util-lib/directives/skeleton/skeleton.component'
import { SkeletonDirective } from '@shared-util-lib/directives/skeleton/skeleton.directive'

@NgModule({
    declarations: [SkeletonComponent, SkeletonDirective],
    exports: [SkeletonComponent, SkeletonDirective],
    imports: [CommonModule],
})
export class SkeletonModule {}
