import { Component, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { combineLatest, timer } from 'rxjs'
import {
    filter,
    map,
    share,
    shareReplay,
    startWith,
    take,
    tap,
} from 'rxjs/operators'

@Component({
    selector: 'app-logout',
    styleUrls: ['./logout.component.scss'],
    templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnDestroy {
    private seconds = 5

    readonly countdown$ = timer(0, 1000).pipe(
        map((i) => this.seconds - i),
        take(this.seconds + 1),
        startWith(this.seconds),
        share()
    )

    readonly authenticated$ = this.authn.stateChanges().pipe(
        map((value) => value === AuthenticationState.Authenticated),
        shareReplay(1)
    )

    private readonly prepareLogout$ = this.authenticated$.pipe(
        filter((authenticated) => authenticated),
        tap(() => this.authn.initSignOutFlow())
    )

    private readonly processLogout$ = combineLatest([
        this.authenticated$,
        this.countdown$,
    ]).pipe(
        filter(
            ([authenticated, countdown]) => !authenticated && countdown === 0
        ),
        tap(() => this.navigateToLogoutOrigin())
    )

    private readonly subscription = combineLatest([
        this.prepareLogout$,
        this.processLogout$,
    ]).subscribe()

    constructor(
        private authn: AuthnService,
        private router: Router
    ) {}

    navigateToLogoutOrigin() {
        const logoutCallbackPath = this.authn.popLogoutRedirectPath()
        if (logoutCallbackPath) {
            this.router.navigate([logoutCallbackPath])
        } else {
            this.router.navigate(['/'])
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
