<app-breadcrumb *ngIf="showBreadcrumbs"></app-breadcrumb>
<div *ngIf="!hidePageTitle" class="title" data-qa="page_title">
    <mat-icon
        class="title_icon"
        *ngIf="this.icon"
        svgIcon="{{ this.icon }}"
        aria-hidden="true"
        data-qa="page_title_icon"
    ></mat-icon>
    <span
        class="text-headline-5"
        [class.skeleton-loading]="loadingPageTitle"
        data-qa="page_title_text"
    >
        {{ overwritePageTitle || (pageTitle | translate) }}
    </span>
</div>
