<div class="error-panel">
    <mat-icon
        class="icon"
        [svgIcon]="customIcon || 'error'"
        *ngIf="!this.hideIcon"
    ></mat-icon>
    <div
        class="error-message"
        [ngClass]="{ 'error-message--icon': !this.hideIcon }"
    >
        <div class="content-wrapper">
            <ng-content></ng-content>
        </div>
        <div class="default-message-wrapper">
            <translate>DefaultError</translate>
        </div>
    </div>
</div>
