import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { BreadcrumbComponent } from './breadcrumb.component'
import { DataQaTagPipeModule } from '@shared-util-lib/pipes/dataQaTag/data-qa-tag-pipe.module'

@NgModule({
    declarations: [BreadcrumbComponent],
    exports: [BreadcrumbComponent],
    imports: [
        CommonModule,
        DataQaTagPipeModule,
        MatIconModule,
        RouterModule,
        TranslationModule,
    ],
})
export class BreadcrumbModule {}
