<footer class="bg-primary h-18 text-white">
    <div class="flex flex-wrap justify-center items-center font-medium h-full">
        <a
            class="text-white no-underline mx-2 sm:mx-4 md:mx-8"
            [routerLink]="['/contact']"
        >
            <translate>Contact</translate>
        </a>
        <a
            class="text-white no-underline mx-2 sm:mx-4 md:mx-8"
            [routerLink]="['/imprint']"
        >
            <translate>Imprint</translate>
        </a>
        <a
            class="text-white no-underline mx-2 sm:mx-4 md:mx-8"
            [routerLink]="['/privacy']"
        >
            <translate>Privacy</translate>
        </a>
        <a
            class="text-white no-underline mx-2 sm:mx-4 md:mx-8"
            [routerLink]="['/terms-and-conditions']"
        >
            <translate>Terms of Use</translate>
        </a>
    </div>
</footer>
