import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from '@env-lib/api/api.service'
import {
    AWSUrlResponse,
    FastTrackBooking,
    FastTrackBookingRequest,
    FastTrackBookingRequestResponse,
    FastTrackBookingsResponse,
    FastTrackDocumentResponse,
    FastTrackDocumentType,
    FastTrackLocationResponse,
    FastTrackProduct,
    FastTrackProductAvailabilityResponse,
    FastTrackUICClassesResponse,
} from '@fast-track-lib/fast-track.model'
import { Observable, of, switchMap } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class FastTrackService {
    constructor(
        private api: ApiService,
        private http: HttpClient
    ) {}

    //---------------------------
    //------ LandingPage --------
    //---------------------------

    getLocations(
        showOnlyBookable: boolean
    ): Observable<FastTrackLocationResponse> {
        const params = new HttpParams().set('onlyBookable', showOnlyBookable)

        const url = `${this.api.fastTrack.backendUrl}/v2/locations`
        return this.http.get<FastTrackLocationResponse>(url, {
            params,
        })
    }

    getMyBookings(): Observable<FastTrackBookingsResponse> {
        const url = `${this.api.fastTrack.backendUrl}/v2/bookings`

        return this.http.get<FastTrackBookingsResponse>(url)
    }

    //-------------------------
    //------ Documents --------
    //-------------------------

    generateDocumentDownloadURL(
        bookingId: string,
        productId: string,
        documentType: FastTrackDocumentType
    ): Observable<AWSUrlResponse | null> {
        if (bookingId) {
            return this.generateDocumentDownloadURLForBooking(
                bookingId,
                documentType
            )
        } else if (productId) {
            return this.generateDocumentDownloadURLForProduct(
                productId,
                documentType
            )
        }

        return of(null)
    }

    generateDocumentDownloadURLForBooking(
        bookingId: string,
        documentType: FastTrackDocumentType
    ): Observable<AWSUrlResponse> {
        const url = `${this.api.fastTrack.backendUrl}/v2/bookings/${bookingId}/${documentType}/downloadUrl`
        return this.http.get<AWSUrlResponse>(url)
    }

    generateDocumentDownloadURLForProduct(
        productId: string,
        documentType: FastTrackDocumentType
    ): Observable<AWSUrlResponse> {
        const url = `${this.api.fastTrack.backendUrl}/v2/products/${productId}/${documentType}/downloadUrl`
        return this.http.get<AWSUrlResponse>(url)
    }

    uploadDocument(
        productId: string,
        documentType: FastTrackDocumentType,
        file: File,
        mimeType: string
    ) {
        const url = `${this.api.fastTrack.backendUrl}/v2/products/${productId}/${documentType}/uploadUrl`

        const filenameWithoutSpaces = file.name.replace(/ /g, '_')
        const encodedFileName = encodeURIComponent(filenameWithoutSpaces)
        const encodedFile = new File([file], encodedFileName)

        const payload = {
            documentType,
            mimeType,
            fileName: encodedFileName,
        }

        return this.http.post<AWSUrlResponse>(url, payload).pipe(
            switchMap(({ url }) => {
                const headers = new HttpHeaders({
                    SkipAuthInterceptor: 'true',
                    SkipLanguageInterceptor: 'true',
                    'Content-Type': mimeType,
                    'X-Amz-Meta-Filename': encodedFileName,
                    'Content-Disposition': `inline; filename=${encodedFileName}`,
                })

                const req = new HttpRequest('PUT', url, encodedFile, {
                    headers,
                    reportProgress: true,
                })

                return this.http.request(req)
            })
        )
    }

    getUploadedDocuments(
        productId: string
    ): Observable<FastTrackDocumentResponse> {
        const url = `${this.api.fastTrack.backendUrl}/v2/products/${productId}/documents`
        return this.http.get<FastTrackDocumentResponse>(url)
    }

    //-----------------------
    //------ Product --------
    //-----------------------

    deleteProduct(locationId: string, productId: string) {
        const url = `${this.api.fastTrack.backendUrl}/v2/locations/${locationId}/products/${productId}`
        return this.http.delete(url)
    }

    publishProduct(locationId: string, productId: string, published: boolean) {
        const url = `${this.api.fastTrack.backendUrl}/v2/locations/${locationId}/products/${productId}/change-published-state`
        const requestBody = { published }
        return this.http.put(url, requestBody)
    }

    createProduct(
        locationId: string,
        product: Partial<FastTrackProduct>
    ): Observable<Partial<FastTrackProduct>> {
        const url = `${this.api.fastTrack.backendUrl}/v2/locations/${locationId}/products`
        return this.http.post<Partial<FastTrackProduct>>(url, product)
    }

    updateProduct(
        locationId: string,
        product: Partial<FastTrackProduct>
    ): Observable<Partial<FastTrackProduct>> {
        const url = `${this.api.fastTrack.backendUrl}/v2/locations/${locationId}/products/${product.productId}`
        return this.http.put<Partial<FastTrackProduct>>(url, product)
    }

    getUicClasses(): Observable<FastTrackUICClassesResponse> {
        const url = `${this.api.fastTrack.backendUrl}/v2/uic`
        return this.http.get<FastTrackUICClassesResponse>(url)
    }

    getProductAvailability(
        locationId: string,
        productId: string
    ): Observable<FastTrackProductAvailabilityResponse> {
        const url = `${this.api.fastTrack.backendUrl}/v2/locations/${locationId}/products/${productId}/availability`
        return this.http.get<FastTrackProductAvailabilityResponse>(url)
    }

    //-----------------------
    //------ Booking --------
    //-----------------------

    submitBookingRequest(
        bookingRequest: FastTrackBooking
    ): Observable<FastTrackBookingRequestResponse> {
        const url = `${this.api.fastTrack.backendUrl}/v2/locations/${bookingRequest.location.locationId}/products/${bookingRequest.product.productId}/bookings`
        const requestBody: FastTrackBookingRequest = {
            bookingFrom: bookingRequest.bookingFrom,
            bookingTo: bookingRequest.bookingTo,
            quantity: bookingRequest.quantity,
            totalPriceInEur: bookingRequest.totalPriceInEur,
        }
        return this.http.post<FastTrackBookingRequestResponse>(url, requestBody)
    }

    //-----------------------
    //------ Access --------
    //-----------------------

    submitAccessRequest() {
        const url = `${this.api.fastTrack.backendUrl}/v2/access-request`
        return this.http.post<void>(url, null)
    }
}
