import { Component, Input, TemplateRef } from '@angular/core'
import { ReplaySubject } from 'rxjs'

@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent {
    @Input() entryTemplate?: TemplateRef<unknown>

    @Input() error?: Error | string

    @Input() noEntriesText?: string

    entries$ = new ReplaySubject(1)
    loadingEntries$ = new ReplaySubject(1)

    @Input()
    set entries(value: Record<string, unknown>[]) {
        this.entries$.next(value || [])
    }

    @Input()
    set loadingEntries(value: number) {
        this.loadingEntries$.next(
            new Array(value).fill({} as Record<string, unknown>)
        )
    }

    @Input() loading?: boolean

    get renderEntries$() {
        return this.loading ? this.loadingEntries$ : this.entries$
    }

    get errorInstance() {
        return typeof this.error === 'string'
            ? new Error(this.error)
            : this.error
    }
}
