import { animate, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Notification } from '@platform-lib/notification/notification.model'
import {
    removeEntries,
    setIsMarked,
    setIsRead,
} from '@platform-lib/notification/redux/notification.actions'
import {
    selectRemoveEntriesParams,
    selectSetIsMarkedParams,
} from '@platform-lib/notification/redux/notification.selectors'
import { IntersectionChangePayload } from '@shared-util-lib/directives/intersection/intersection.model'
import { isTruthy } from '@util-lib/isTruthy'
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription,
    bufferTime,
} from 'rxjs'
import {
    debounceTime,
    filter,
    map,
    pairwise,
    shareReplay,
    startWith,
} from 'rxjs/operators'

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    animations: [
        trigger('itemTrigger', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('500ms', style({ opacity: 1 })),
            ]),
        ]),
    ],
})
export class NotificationListComponent implements OnInit, OnDestroy {
    readonly notificationsSubject = new BehaviorSubject<Notification[]>([])
    @Input()
    set notifications(value: Notification[]) {
        this.notificationsSubject.next(value)
    }

    @Input() showSkeletons?: boolean

    @Input() documentAsIntersectionRoot?: boolean

    get intersectionRoot() {
        return this.documentAsIntersectionRoot ? document : undefined
    }

    private readonly notificationIds$ = this.notificationsSubject.pipe(
        filter(isTruthy),
        map((notifications) => notifications.map(({ id }) => id)),
        shareReplay(1)
    )

    readonly unread$ = this.notificationIds$.pipe(
        pairwise(),
        map(([last, current]) =>
            current.filter((id) => last.indexOf(id) === -1)
        ),
        startWith([]),
        shareReplay(1)
    )

    private readonly viewedSubject = new Subject<{
        id: string
        unobserve: () => void
    }>()
    readonly viewedChunks$ = this.viewedSubject.pipe(
        bufferTime(1000),
        filter((list) => !!list?.length),
        debounceTime(1000),
        shareReplay(1)
    )

    readonly bookmarkIds$: Observable<string[]> = this.store
        .select(selectSetIsMarkedParams)
        .pipe(
            map((params) => params?.notificationIds || []),
            shareReplay(1)
        )

    readonly deleteIds$: Observable<string[]> = this.store
        .select(selectRemoveEntriesParams)
        .pipe(
            map((params) => params?.notificationIds || []),
            shareReplay(1)
        )

    private readonly subscription = new Subscription()

    constructor(private store: Store) {}

    ngOnInit() {
        this.subscription.add(
            this.viewedChunks$.subscribe((chunk) => {
                // stop observing notification after it's been viewed
                chunk.map(({ unobserve }) => unobserve)
                const notificationIds = chunk.map(({ id }) => id)
                this.store.dispatch(
                    setIsRead({ request: { notificationIds, value: true } })
                )
            })
        )
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    onItemClick({ id, isRead }: Notification) {
        if (!isRead) {
            this.store.dispatch(
                setIsRead({ request: { notificationIds: [id], value: true } })
            )
        }
    }

    onItemBookmarkClick({ id, isMarked }: Notification) {
        this.store.dispatch(
            setIsMarked({
                request: { notificationIds: [id], value: !isMarked },
            })
        )
    }

    onItemDeleteClick({ id }: Notification) {
        this.store.dispatch(
            removeEntries({ request: { notificationIds: [id] } })
        )
    }

    onIntersectionChange(
        notification: Notification,
        { entry, unobserve }: IntersectionChangePayload
    ) {
        if (!notification?.id || notification.isRead) {
            unobserve()
            return
        }
        if (entry.intersectionRatio < 1) {
            return
        }
        this.viewedSubject.next({
            id: notification.id,
            unobserve,
        })
    }
}
