import { ViewportRuler } from '@angular/cdk/overlay'
import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { TranslationService } from '@localization-lib/language/translation.service'
import { ParallaxBlockScrollStrategy } from '@shared-util-lib/parallax/parallax-block-scroll-strategy'
import * as basicScroll from 'basicscroll'
import { Data } from 'basicscroll'
import { BehaviorSubject, Subscription } from 'rxjs'
import { filter, shareReplay } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class ParallaxService {
    private _isScrollingBlocked = new BehaviorSubject(false)

    constructor(
        private translationService: TranslationService,
        private _viewportRuler: ViewportRuler,
        @Inject(DOCUMENT) private document: Document
    ) {}

    get scrollStrategies() {
        return {
            blockAndPreserveParallax: () =>
                new ParallaxBlockScrollStrategy(
                    this,
                    this._viewportRuler,
                    this.document
                ),
        }
    }

    get isScrollingBlocked$() {
        return this._isScrollingBlocked.asObservable().pipe(shareReplay(1))
    }

    setScrollBlock(active: boolean) {
        this._isScrollingBlocked.next(active)
    }

    create(options: Partial<Data>) {
        const subscriptions = new Subscription()
        const instance = basicScroll.create({
            ...options,
            direct: options.direct || options.elem || undefined,
            from: options.from !== undefined ? options.from : 'top-bottom',
            to: options.to !== undefined ? options.to : 'bottom-top',
        })

        instance.start()

        subscriptions.add(
            this.translationService.loadingTranslations$
                .pipe(filter((loading) => !loading))
                .subscribe(() => {
                    setTimeout(() => {
                        instance.calculate()
                        instance.update()
                    })
                })
        )

        subscriptions.add(
            this.isScrollingBlocked$.subscribe((scrollingBlocked) => {
                if (scrollingBlocked) {
                    instance.stop()
                } else {
                    setTimeout(() => {
                        instance.start()
                        instance.calculate()
                        instance.update()
                    })
                }
            })
        )

        return () => {
            instance.stop()
            instance.destroy()
            subscriptions.unsubscribe()
        }
    }
}
