import {
    ComponentFactoryResolver,
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core'
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs'
import { SkeletonComponent } from './skeleton.component'

@Directive({
    selector: '[appSkeleton]',
})
export class SkeletonDirective implements OnInit, OnDestroy {
    readonly showSkeleton$ = new BehaviorSubject<unknown>(true)
    @Input()
    set appSkeleton(value: unknown) {
        this.showSkeleton$.next(value)
    }

    readonly placeholder$ = new BehaviorSubject<string>('Placeholder')
    @Input()
    set appSkeletonPlaceholder(value: string) {
        this.placeholder$.next(value)
    }

    readonly classname$ = new BehaviorSubject<string>('')
    @Input()
    set appSkeletonClassname(value: string) {
        this.classname$.next(value)
    }

    private subscription = new Subscription()

    private showingContent = false

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private cfr: ComponentFactoryResolver
    ) {}

    ngOnInit() {
        this.subscription.add(
            combineLatest([
                this.showSkeleton$,
                this.placeholder$,
                this.classname$,
            ]).subscribe(([showSkeleton, placeholder, classname]) => {
                if (!showSkeleton) {
                    if (!this.showingContent) {
                        this.showingContent = true
                        this.viewContainer.clear()
                        this.viewContainer.createEmbeddedView(this.templateRef)
                    }
                } else {
                    // rendering without checking showingContent flag
                    // in case one of the parameters was changed and an updates is needed
                    this.showingContent = false
                    this.viewContainer.clear()
                    const skeletonComp = this.viewContainer.createComponent(
                        this.cfr.resolveComponentFactory(SkeletonComponent)
                    )
                    skeletonComp.instance.placeholder = placeholder
                    skeletonComp.instance.classname = classname
                }
            })
        )
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
