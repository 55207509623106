import { AnalyticsService } from '@analytics-lib/analytics.service'
import { ComponentPortal } from '@angular/cdk/portal'
import {
    AfterViewInit,
    Component,
    ElementRef,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthorizedGuard } from '@auth-util-lib/guards/authorized.guard'
import { LoginTrackingService } from '@auth-util-lib/login-tracking.service'
import {
    AppActivatedRouteSnapshot,
    AppRouteData,
} from '@auth-util-lib/routing.model'
import { EnvService } from '@env-lib/env.service'
import { isDevelopment } from '@env-lib/isDevelopment'
import { Stage } from '@env-lib/stage'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'
import { loadGroupsFromBackend } from '@group-management-lib/redux/group-management.actions'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { TranslationService } from '@localization-lib/language/translation.service'
import { Store } from '@ngrx/store'
import { FooterComponent } from '@platform-lib/components/footer/footer.component'
import { HeaderComponent } from '@platform-lib/components/header/header.component'
import { GROUP_MANAGEMENT_CONFIG } from '@platform-lib/components/header/header.token'
import { FaviconService } from '@platform-lib/services/favicon.service'
import { SidebarService } from '@platform-lib/services/sidebar.service'
import { AppIconService } from '@portal-lib/app-icon.service'
import { ClarityService } from '@shared-util-lib/clarity/clarity.service'
import { isNavigationEndEvent } from '@util-lib/isNavigationEndEvent'
import { isNavigationStartEvent } from '@util-lib/isNavigationStartEvent'
import { combineLatest, isObservable, Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    private subscriptions = new Subscription()
    private footerSubscription = new Subscription()
    private headerSubscription = new Subscription()
    private headerClassSubscription = new Subscription()

    @ViewChild('mainSection', { static: false })
    mainSection?: ElementRef<HTMLElement>

    sidebarOpen = false
    fullWidth = false
    imageBackground = false
    headerPortal?: ComponentPortal<unknown>
    headerClass?: string
    footerPortal?: ComponentPortal<unknown>
    hideFooter = true
    hideHeader = true
    title = 'traigo Portal'
    customBackgroundColor?: string

    private routeData$ = this.router.events.pipe(
        filter(isNavigationEndEvent),
        map((): AppRouteData => {
            let route = this.route
            while (route.firstChild) {
                route = route.firstChild
            }
            const snapshot = route.snapshot as AppActivatedRouteSnapshot
            return snapshot.data || {}
        })
    )

    authorizedGuardProcessing$ = this.authorizedGuard.isProcessing$

    constructor(
        private env: EnvService,
        private iconService: AppIconService,
        public route: ActivatedRoute,
        public router: Router,
        private titleService: Title,
        private meta: Meta,
        private faviconService: FaviconService,
        private translationService: TranslationService,
        public analyticsService: AnalyticsService,
        private sidebarService: SidebarService,
        private loginTrackingService: LoginTrackingService,
        private authorizedGuard: AuthorizedGuard,
        private datadogService: DatadogService,
        private clarityService: ClarityService,
        private groupManagementStore: Store<GroupManagementState>,
        private injector: Injector
    ) {
        this.iconService.initCustomIcons()
    }

    ngOnInit() {
        this.faviconService.adjustFaviconForStage(this.env.stage)

        this.subscriptions.add(
            // this is just to ensure LoginTrackingService is instantiated
            // since its usage in code isn't always reachable
            this.loginTrackingService.userSessionCount$().subscribe()
        )

        // this is a temporary condition and should be replaced with cookie consent check
        // or ClarityIntegration feature flag check
        // todo revise after final decision
        if (this.env.stage === Stage.Int && !isDevelopment()) {
            this.clarityService.initClarity()
        }

        this.datadogService.initDatadogLogs()
        this.datadogService.initDatadogRum()
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.routeData$.subscribe((routeData) => {
                this.fullWidth = routeData.fullWidth || false

                this.imageBackground = routeData.useImageBackground || false

                this.hideHeader = routeData.hideHeader || false

                this.headerSubscription.unsubscribe()

                if (isObservable(routeData.headerComponent)) {
                    this.headerSubscription.add(
                        routeData.headerComponent.subscribe(
                            (headerComponent) =>
                                (this.headerPortal = new ComponentPortal(
                                    headerComponent || HeaderComponent,
                                    null,
                                    Injector.create({
                                        parent: this.injector,
                                        providers: [
                                            {
                                                provide:
                                                    GROUP_MANAGEMENT_CONFIG,
                                                useValue:
                                                    routeData.enableGroupManagementHeader ||
                                                    false,
                                            },
                                        ],
                                    })
                                ))
                        )
                    )
                } else {
                    this.headerPortal = new ComponentPortal(
                        routeData.headerComponent || HeaderComponent,
                        null,
                        Injector.create({
                            parent: this.injector,
                            providers: [
                                {
                                    provide: GROUP_MANAGEMENT_CONFIG,
                                    useValue:
                                        routeData.enableGroupManagementHeader ||
                                        false,
                                },
                            ],
                        })
                    )
                }

                this.headerClassSubscription.unsubscribe()
                if (isObservable(routeData.headerClass)) {
                    this.headerClassSubscription.add(
                        routeData.headerClass.subscribe(
                            (headerClass) =>
                                (this.headerClass = headerClass || '')
                        )
                    )
                } else {
                    this.headerClass = routeData.headerClass || ''
                }

                this.hideFooter = routeData.hideFooter || false

                this.footerSubscription.unsubscribe()
                if (isObservable(routeData.footerComponent)) {
                    this.footerSubscription.add(
                        routeData.footerComponent.subscribe(
                            (footerComponent) =>
                                (this.footerPortal = new ComponentPortal(
                                    footerComponent || FooterComponent
                                ))
                        )
                    )
                } else {
                    this.footerPortal = new ComponentPortal(
                        routeData.footerComponent || FooterComponent
                    )
                }
                this.customBackgroundColor =
                    routeData.customBackgroundColor || undefined
            })
        )

        this.subscriptions.add(
            this.routeData$.subscribe(() => {
                const shouldScrollUp =
                    this.router.getCurrentNavigation()?.extras.state
                        ?.scrollToTop !== false

                if (shouldScrollUp) {
                    window.scrollTo(0, 0)
                }
            })
        )

        this.subscriptions.add(
            this.router.events
                .pipe(filter(isNavigationStartEvent))
                .subscribe(() => {
                    this.sidebarService.close()
                })
        )

        this.subscriptions.add(
            combineLatest([
                this.routeData$,
                this.translationService.translator$,
            ]).subscribe(([routeData, translator]) => {
                const title = routeData.title || routeData.breadcrumb?.name
                this.titleService.setTitle(
                    'traigo' + (title ? ` · ${translator(title)}` : '')
                )
                this.meta.updateTag({
                    name: 'description',
                    content: routeData.metaDescription || '',
                })
                this.meta.updateTag({
                    name: 'keywords',
                    content: routeData.metaKeywords || '',
                })
                this.meta.updateTag({
                    name: 'viewport',
                    content: routeData.optimizedForMobile
                        ? 'width=device-width, initial-scale=1'
                        : 'width=1024',
                })
            })
        )

        // init group management
        // can't be in group management lib, because every navigation on page
        // will destroy and construct group management header again
        // this would lead to multiple calls of be
        // therefor the store MUST be included in app.module to prevent a rebuild
        // everytime someone navigates through traigo
        this.groupManagementStore.dispatch(loadGroupsFromBackend())
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
