import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { Action, Unprotected } from '@auth-util-lib/auth.model'
import { AuthorizedGuard } from '@auth-util-lib/guards/authorized.guard'
import { DashboardRedirectGuard } from '@auth-util-lib/guards/dashboard-redirect.guard'
import { AppRoute } from '@auth-util-lib/routing.model'
import { FeatureFlags } from '@feature-flag-lib/feature-flags'
import { FeatureFlagGuard } from '@feature-flag-lib/feature-flags.guard'
import { LegalModule } from '@legal/legal.module'
import { LandingPageFooterResolver } from '@platform-lib/components/footer/landing-page-footer.resolver'
import { LandingPageHeaderClassResolver } from '@platform-lib/components/header/landing-page-header-class.resolver'
import { LandingPageHeaderResolver } from '@platform-lib/components/header/landing-page-header.resolver'
import { IncompatibleBrowserGuard } from '@platform-lib/guards/incompatible-browser-guard'
import { IncompatibleBrowserComponent } from '@platform-lib/incompatible-browser/incompatible-browser.component'
import { ContactComponent } from '@platform-lib/legal/contact/contact.component'
import { CookieGuard } from '@platform-lib/legal/cookie/cookie.guard'
import { ImprintComponent } from '@platform-lib/legal/imprint/imprint.component'
import { PrivacyComponent } from '@platform-lib/legal/privacy/privacy.component'
import { TermsConditionsComponent } from '@platform-lib/legal/terms-conditions/terms-conditions-overview/terms-conditions-overview.component'
import { NotAllowedComponent } from '@shared-ui-lib/not-allowed/not-allowed.component'
import { NotFoundComponent } from '@shared-ui-lib/not-found/not-found.component'

export const appRoutes: AppRoute[] = [
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Contract Management',
            icon: 'contract-management',
            protection: Action.ShowContracts,
        },
        loadChildren: () =>
            import('../contract/contract.module').then(
                (mod) => mod.ContractModule
            ),
        path: 'contracts',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Fleet Management',
            icon: 'fleet-management',
            protection: Action.ShowFleet,
            enableGroupManagementHeader: true,
        },
        loadChildren: () =>
            import('../fleet/fleet.module').then((mod) => mod.FleetModule),
        path: 'fleet',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Idle Times',
            icon: 'idle-times',
            protection: Action.ShowPerformance,
            enableGroupManagementHeader: true,
        },
        loadChildren: () =>
            import('../performance/performance.module').then(
                (mod) => mod.PerformanceModule
            ),
        path: 'idle-times',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            fullWidth: true,
            hideFooter: true,
            title: 'Wagon Tracking',
            protection: Action.ShowWagonTracking,
            enableGroupManagementHeader: true,
        },
        loadChildren: () =>
            import('../pathfinder/pathfinder.module').then(
                (mod) => mod.PathfinderModule
            ),
        path: 'wagon-tracking',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'ETA Calculation',
            icon: 'eta',
            protection: Action.ShowEta,
        },
        loadChildren: () =>
            import('../eta/eta.module').then((mod) => mod.EtaModule),
        path: 'eta',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Wagon Details',
            protection: [
                Action.ShowContracts,
                Action.ShowFleet,
                Action.ShowWagonTracking,
            ],
        },
        loadChildren: () =>
            import('../wagon-details/wagon-details.module').then(
                (mod) => mod.WagonDetailsModule
            ),
        path: 'wagon',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Sales Support',
            protection: [Action.ShowSalesCare],
        },
        loadChildren: () =>
            import('../sales-care/sales-care.module').then(
                (mod) => mod.SalesCareModule
            ),
        path: 'sales-care',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Premium Services',
            icon: 'booking',
            protection: Unprotected,
        },
        loadChildren: () =>
            import('../booking/booking.module').then(
                (mod) => mod.BookingModule
            ),
        path: 'booking',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'FastTrack',
            icon: 'fast-track',
            protection: Unprotected,
        },
        loadChildren: () =>
            import('../fast-track/fast-track.module').then(
                (mod) => mod.FastTrackModule
            ),
        path: 'fast-track',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Group Management',
            icon: 'group-management',
            protection: Unprotected,
            enableGroupManagementHeader: true,
        },
        loadChildren: () =>
            import('../group-management/group-management.module').then(
                (mod) => mod.GroupManagementModule
            ),
        path: 'group-management',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Data Forwarding',
            icon: 'data-forwarding',
            protection: Action.ShowUserManagement,
            enableGroupManagementHeader: false,
        },
        loadChildren: () =>
            import('../data-forwarding/data-forwarding.module').then(
                (mod) => mod.DataForwardingModule
            ),
        path: 'data-forwarding',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Wagon Hire',
            icon: 'wagon-hire',
            protection: Action.ShowWagonHire,
        },
        loadChildren: () =>
            import('../wagon-hire/wagon-hire.module').then(
                (mod) => mod.WagonHireModule
            ),
        path: 'wagon-hire',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            fullWidth: true,
            title: 'Overview',
            protection: Unprotected,
            enableGroupManagementHeader: true,
        },
        loadChildren: () =>
            import('../dashboard/dashboard.module').then(
                (mod) => mod.DashboardModule
            ),
        path: 'dashboard',
    },
    {
        loadChildren: () =>
            import('../legal/legal.module').then((mod) => mod.LegalModule),
        path: 'legal',
        data: {
            protection: Unprotected,
        },
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'traigo Updates',
            icon: 'traigo-updates',
            protection: Unprotected,
        },
        loadChildren: () =>
            import('../../libs/platform-lib/updates/updates.module').then(
                (mod) => mod.UpdatesModule
            ),
        path: 'traigo-updates',
    },
    {
        canLoad: [AuthorizedGuard, FeatureFlagGuard],
        data: {
            title: 'Design System',
            protection: Unprotected,
            featureFlag: FeatureFlags.DeveloperTools,
        },
        loadChildren: () =>
            import(
                '../../libs/platform-lib/design-system/design-system.module'
            ).then((mod) => mod.DesignSystemModule),
        path: 'design-system',
    },
    {
        data: {
            protection: Unprotected,
        },
        path: 'release-notes',
        redirectTo: 'traigo-updates',
    },
    {
        canLoad: [AuthorizedGuard],
        data: { title: 'Account Settings', protection: Unprotected },
        loadChildren: () =>
            import('../account/account.module').then(
                (mod) => mod.AccountModule
            ),
        path: 'account',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Admincenter',
            protection: Action.ShowAdminCenter,
        },
        loadChildren: () =>
            import('../administration/administration.module').then(
                (mod) => mod.AdministrationModule
            ),
        path: 'administration',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Notifications',
            icon: 'notifications',
            protection: Unprotected,
        },
        loadChildren: () =>
            import(
                '../../libs/platform-lib/notification/notification.module'
            ).then((mod) => mod.NotificationModule),
        path: 'notifications',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'User Management',
            icon: 'user-management',
            protection: Action.ShowUserManagement,
        },
        loadChildren: () =>
            import('../user-management/user-management.module').then(
                (mod) => mod.UserManagementModule
            ),
        path: 'user-management',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Client Management',
            icon: 'client-management',
            protection: Action.ShowClientManagement,
        },
        loadChildren: () =>
            import('../client-management/client-management.module').then(
                (mod) => mod.ClientManagementModule
            ),
        path: 'client-management',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            title: 'Dispatch Management',
            protection: Action.ShowDispatchManagement,
        },
        loadChildren: () =>
            import('../dispatch-management/dispatch-management.module').then(
                (mod) => mod.DispatchManagementModule
            ),
        path: 'dispatch-management',
    },
    {
        component: IncompatibleBrowserComponent,
        data: {
            fullWidth: true,
            hideHeader: true,
            useImageBackground: true,
            protection: Unprotected,
        },
        path: 'incompatible-browser',
    },
    {
        canActivate: [DashboardRedirectGuard, IncompatibleBrowserGuard],
        data: {
            protection: Unprotected,
            enableGroupManagementHeader: true,
        },
        loadChildren: () =>
            import('@landing-page/landing-page.module').then(
                (mod) => mod.LandingPageModule
            ),
        path: '',
    },
    {
        path: 'legal/contact',
        redirectTo: 'contact',
        data: { protection: Unprotected },
    },
    {
        canActivate: [IncompatibleBrowserGuard],
        component: ContactComponent,
        data: {
            breadcrumb: {
                name: 'Service Desk',
                url: '/contact',
            },
            title: 'Service Desk',
            protection: Unprotected,
            optimizedForMobile: true,
        },
        resolve: {
            footerComponent: LandingPageFooterResolver,
            headerComponent: LandingPageHeaderResolver,
            headerClass: LandingPageHeaderClassResolver,
        },
        path: 'contact',
    },
    {
        path: 'legal/imprint',
        redirectTo: 'imprint',
        data: { protection: Unprotected },
    },
    {
        canActivate: [IncompatibleBrowserGuard],
        component: ImprintComponent,
        data: {
            breadcrumb: {
                name: 'Imprint',
                url: '/imprint',
            },
            title: 'Imprint',
            protection: Unprotected,
            optimizedForMobile: true,
        },
        resolve: {
            footerComponent: LandingPageFooterResolver,
            headerComponent: LandingPageHeaderResolver,
            headerClass: LandingPageHeaderClassResolver,
        },
        path: 'imprint',
    },
    {
        path: 'legal/privacy',
        redirectTo: 'privacy',
        data: { protection: Unprotected },
    },
    {
        canActivate: [IncompatibleBrowserGuard],
        component: PrivacyComponent,
        data: {
            breadcrumb: {
                name: 'Privacy',
                url: '/privacy',
            },
            title: 'Privacy',
            protection: Unprotected,
            optimizedForMobile: true,
        },
        resolve: {
            footerComponent: LandingPageFooterResolver,
            headerComponent: LandingPageHeaderResolver,
            headerClass: LandingPageHeaderClassResolver,
        },
        path: 'privacy',
    },
    {
        path: 'legal/terms-and-conditions',
        redirectTo: 'terms-and-conditions',
        data: { protection: Unprotected },
    },
    {
        canActivate: [IncompatibleBrowserGuard],
        component: TermsConditionsComponent,
        data: {
            breadcrumb: {
                name: 'Terms of Use',
                url: '/terms-and-conditions',
            },
            title: 'Terms of Use',
            protection: Unprotected,
            optimizedForMobile: true,
        },
        resolve: {
            footerComponent: LandingPageFooterResolver,
            headerComponent: LandingPageHeaderResolver,
            headerClass: LandingPageHeaderClassResolver,
        },
        path: 'terms-and-conditions',
    },
    {
        canLoad: [AuthorizedGuard],
        data: {
            protection: Unprotected,
        },
        loadChildren: () =>
            import('../connect/connect.module').then(
                (mod) => mod.ConnectModule
            ),
        path: 'connect',
    },
    {
        component: NotAllowedComponent,
        data: {
            title: 'NotAllowedTitle',
            protection: Unprotected,
        },
        path: 'unauthorized',
    },
    {
        canActivate: [CookieGuard],
        component: NotFoundComponent,
        data: {
            redirect: [],
            protection: Unprotected,
        },
        path: '**',
    },
]

@NgModule({
    exports: [RouterModule],
    imports: [
        LegalModule,
        RouterModule.forRoot(appRoutes, {
            enableTracing: false,
            anchorScrolling: 'enabled',
        }),
    ],
})
export class AppRoutingModule {}
