import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core'
import { BehaviorSubject, combineLatest, startWith, Subscription } from 'rxjs'
import { ParsedAccessPeriod } from '@group-management-lib/group-management.model'
import { FormControl } from '@angular/forms'
import { map } from 'rxjs/operators'
import dayjs from '@localization-lib/date-time/dayjs'

@Component({
    selector: 'app-group-management-access-period-selection',
    templateUrl: './group-management-access-period-selection.component.html',
    styleUrls: ['./group-management-access-period-selection.component.scss'],
})
export class GroupManagementAccessPeriodSelectionComponent
    implements OnDestroy
{
    @Input()
    set accessPeriod(value: ParsedAccessPeriod | null) {
        if (value) {
            this.accessPeriod$.next(value)
        }
    }

    @Output() readonly selectedAccessPeriod =
        new EventEmitter<ParsedAccessPeriod>()

    readonly accessPeriod$ = new BehaviorSubject<ParsedAccessPeriod>({
        beginOn: null,
        endOn: null,
    })
    startDateControl = new FormControl<Date | null>(null)
    endDateControl = new FormControl<Date | null>(null)

    private readonly subscriptions = new Subscription()

    constructor() {
        this.subscriptions.add(
            this.accessPeriod$.subscribe((a) => {
                if (a.beginOn) {
                    this.startDateControl.setValue(a.beginOn)
                }

                if (a.endOn) {
                    this.endDateControl.setValue(a.endOn)
                }
            })
        )

        this.subscriptions.add(
            combineLatest([
                this.startDateControl.valueChanges.pipe(
                    startWith(null),
                    map((d) => (d ? new Date(d) : null))
                ),
                this.endDateControl.valueChanges.pipe(
                    startWith(null),
                    map((d) => (d ? new Date(d) : null)),
                    map((endDate) => {
                        if (endDate) {
                            // The end date should be inclusive, such that the group stays accessible until the end of the day.
                            // Also make sure the timezone is set to Berlin.
                            return dayjs
                                .tz(endDate, 'Europe/Berlin')
                                .set('hours', 23)
                                .set('minutes', 59)
                                .set('seconds', 59)
                                .toDate()
                        }
                        return null
                    })
                ),
            ]).subscribe(([startDate, endDate]) => {
                this.selectedAccessPeriod.emit({
                    beginOn: startDate,
                    endOn: endDate,
                })
            })
        )
    }

    isValidStartDate = (d: Date | null): boolean => {
        const endDate = this.endDateControl.value
        return d === null || endDate === null || d <= endDate
    }

    isValidEndDate = (d: Date | null): boolean => {
        const startDate = this.startDateControl.value
        return d === null || startDate === null || startDate <= d
    }

    resetPeriodSelection(event: MouseEvent, formControl: FormControl) {
        event.stopImmediatePropagation()
        formControl.setValue(null)
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
