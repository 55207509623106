import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import {
    MAX_NOTIFICATION_CHUNK_SIZE,
    NotificationsRequest,
} from '@platform-lib/notification/notification.model'
import { NotificationService } from '@platform-lib/notification/notification.service'
import {
    selectChunkEntriesLoaded,
    selectEntriesLimit,
    selectUnreadNotificationCount,
} from '@platform-lib/notification/redux/notification.selectors'
import { deferHttpResponseToAction } from '@util-lib/deferHttpResponseToAction'
import { Observable, combineLatest, exhaustMap } from 'rxjs'
import { filter, map, mapTo, shareReplay, withLatestFrom } from 'rxjs/operators'
import * as notificationActions from './notification.actions'

@Injectable()
export class NotificationEffects {
    loadCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationActions.loadCount),
            exhaustMap(() => this.service.getNotificationCount()),
            withLatestFrom(this.store.select(selectUnreadNotificationCount)),
            filter(
                ([response, storedCount]) =>
                    response?.unreadCount !== storedCount
            ),
            map(([response]) =>
                notificationActions.loadCountSuccess({ response })
            )
        )
    )

    reloadCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                notificationActions.removeEntriesSuccess,
                notificationActions.setIsReadSuccess
            ),
            mapTo(notificationActions.loadCount())
        )
    )

    loadEntries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationActions.loadEntries),
            withLatestFrom(this.store.select(selectEntriesLimit)),
            exhaustMap(([{ limitNumberOfNotifications }, defaultLimit]) =>
                this.service
                    .getNotifications({
                        limitNumberOfNotifications:
                            limitNumberOfNotifications || defaultLimit,
                    })
                    .pipe(
                        deferHttpResponseToAction(
                            notificationActions.loadEntriesSuccess,
                            notificationActions.loadEntriesError
                        )
                    )
            )
        )
    )

    private nextChunkParams$: Observable<NotificationsRequest> = combineLatest([
        this.store.select(selectChunkEntriesLoaded),
    ]).pipe(
        map(([chunkEntriesLoaded]) => ({
            limitNumberOfNotifications:
                chunkEntriesLoaded + MAX_NOTIFICATION_CHUNK_SIZE,
        })),
        shareReplay(1)
    )

    loadChunk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationActions.loadChunk),
            withLatestFrom(this.nextChunkParams$),
            exhaustMap(([_, nextChunkParams]) =>
                this.service
                    .getNotifications({
                        limitNumberOfNotifications:
                            nextChunkParams.limitNumberOfNotifications ||
                            MAX_NOTIFICATION_CHUNK_SIZE,
                    })
                    .pipe(
                        deferHttpResponseToAction(
                            notificationActions.loadChunkSuccess,
                            notificationActions.loadChunkError
                        )
                    )
            )
        )
    )

    removeEntries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationActions.removeEntries),
            exhaustMap(({ request }) =>
                this.service.deleteNotifications(request).pipe(
                    map(() => request),
                    deferHttpResponseToAction(
                        notificationActions.removeEntriesSuccess,
                        notificationActions.removeEntriesError
                    )
                )
            )
        )
    )

    setIsMarked$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationActions.setIsMarked),
            exhaustMap(({ request }) => {
                const { notificationIds, value } = request
                const observable = value
                    ? this.service.bookmark({ notificationIds })
                    : this.service.unbookmark({ notificationIds })
                return observable.pipe(
                    map(() => request),
                    deferHttpResponseToAction(
                        notificationActions.setIsMarkedSuccess,
                        notificationActions.setIsMarkedError
                    )
                )
            })
        )
    )

    setIsRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationActions.setIsRead),
            exhaustMap(({ request }) => {
                const { notificationIds, value } = request
                const observable = value
                    ? this.service.markAsRead({ notificationIds })
                    : this.service.markAsUnread({ notificationIds })
                return observable.pipe(
                    map(() => request),
                    deferHttpResponseToAction(
                        notificationActions.setIsReadSuccess,
                        notificationActions.setIsReadError
                    )
                )
            })
        )
    )

    constructor(
        private actions$: Actions,
        private store: Store,
        private service: NotificationService
    ) {}
}
