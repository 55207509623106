import { createFeatureFlag } from '@feature-flag-lib/createFeatureFlag'

export const FeatureFlags = {
    DashboardNewsWidget: createFeatureFlag('dashboard-news-widget', false),
    DeveloperTools: createFeatureFlag('developer-tools', false),
    FastTrackFilter: createFeatureFlag('fast-track-filter', false),
    FastTrackWidget: createFeatureFlag('fast-track-widget', false),
    FleetOverviewRatingDialog: createFeatureFlag(
        'fleet-management-show-rating-popup',
        { enablePopup: false }
    ),
    NewClientTable: createFeatureFlag('new-client-table', false),
    PathfinderRatingPopup: createFeatureFlag('pathfinder-rating-popup', {
        enablePopup: false,
    }),
    PathfinderNewUI: createFeatureFlag('pathfinder-use-new-ui', false),
    PathfinderOnboardingPopup: createFeatureFlag(
        'pathfinder-onboarding-popup',
        false
    ),
    UserAnalytics: createFeatureFlag('user-analytics', false),
    ExtendedSensorTimeWindows: createFeatureFlag('sensor-time-windows', false),
    WagonTrackingCes: createFeatureFlag('wagon-tracking-ces', false),
}
