import { apiConfig as devApiConfig } from './api-config.dev'
import { ApiConfigModel } from './api-config.model'

const baseUrl = 'http://localhost:4200'
const authDomain = 'https://traigo-dev.auth.eu-central-1.amazoncognito.com'
const clientId = '4l5d79ehpek15t8785nvkfs0t8'
const adLogoutUrl = `https://vtgb2c.b2clogin.com/vtgb2c.onmicrosoft.com/B2C_1_Traigo_SignIn_Dev/oauth2/v2.0/logout?post_logout_redirect_uri=${baseUrl}/logout`

export const apiConfig: ApiConfigModel = {
    ...devApiConfig,

    auth: {
        ...devApiConfig.auth,
        cognitoLogoutUrl: `${authDomain}/logout?client_id=${clientId}&logout_uri=${adLogoutUrl}`,
        redirectUrl: `${baseUrl}/auth`,
    },
}
