import { GroupMetaData } from '../group-management.model'

export const checkTokenIdsEqualsBackendIds = (
    groupsFromToken: string[] | null,
    groupsFromBackend: GroupMetaData[] | null
): boolean => {
    const tokenGroupIds = groupsFromToken
        ? [...groupsFromToken].sort((a, b) => a.localeCompare(b))
        : null

    const backendGroupIds = groupsFromBackend
        ? [...groupsFromBackend]
              .map((group) => group.id)
              .sort((a, b) => a.localeCompare(b))
        : null

    return (
        tokenGroupIds !== null &&
        backendGroupIds !== null &&
        tokenGroupIds.length === backendGroupIds.length &&
        tokenGroupIds.every((id, idx) => id === backendGroupIds[idx])
    )
}
