import { AnalyticsEvent } from '@analytics-lib/analytics.model'
import { GtmService } from '@analytics-lib/gtm.service'
import { Injectable, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Action } from '@auth-util-lib/auth.model'
import { AuthzService } from '@auth-util-lib/authz.service'
import { UserProfileService } from '@auth-util-lib/user-profile.service'
import { isDevelopment } from '@env-lib/isDevelopment'
import { isIntEnvironment } from '@env-lib/isIntEnvironment'
import { FeatureFlags } from '@feature-flag-lib/feature-flags'
import { FeatureFlagsService } from '@feature-flag-lib/feature-flags.service'
import { LocalStorageService } from '@storage-lib/local-storage.service'
import { deepEqual } from '@util-lib/deepEqual'
import { isNavigationEndEvent } from '@util-lib/isNavigationEndEvent'
import { isTruthy } from '@util-lib/isTruthy'
import { combineLatest, merge, NEVER, ReplaySubject } from 'rxjs'
import {
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
} from 'rxjs/operators'
import { SHA3 } from 'sha3'

@Injectable({ providedIn: 'root' })
export class AnalyticsService implements OnDestroy {
    private static readonly TRACKING_OPT_OUT_KEY = 'TRACKING_OPT_OUT'

    private readonly userOptedOutOfTracking$ = new ReplaySubject<boolean>(1)

    private readonly userAuthenticated$ = this.user
        .profileChanges()
        .pipe(map((profile) => !!profile))

    private readonly trackingFlagActive$ = this.featureFlag.getFeatureFlag(
        FeatureFlags.UserAnalytics
    )

    private readonly shouldTrackUser$ = combineLatest([
        this.userAuthenticated$,
        this.trackingFlagActive$,
        this.userOptedOutOfTracking$,
    ]).pipe(
        map(
            ([authenticated, active, optedOut]) =>
                authenticated && active && !optedOut
        ),
        distinctUntilChanged(),
        shareReplay(1)
    )

    private readonly navigationEndEvents$ = this.router.events.pipe(
        filter(isNavigationEndEvent),
        map((event) => event as NavigationEnd)
    )

    private readonly trackableUserId$ = combineLatest([
        this.shouldTrackUser$,
        this.user.profileChanges(),
    ]).pipe(
        map(([shouldTrack, userProfile]) =>
            shouldTrack ? userProfile?.userId : null
        ),
        filter(isTruthy),
        map((userId) => {
            const hash = new SHA3(512)
            hash.update(userId)
            return hash.digest('hex')
        })
    )

    private readonly userHasFastTrackAction$ = this.authzService
        .hasAction$(Action.ShowFastTrackUser)
        .pipe(shareReplay(1))

    private readonly trackableFastTrackAction = combineLatest([
        this.shouldTrackUser$,
        this.userHasFastTrackAction$,
    ]).subscribe(([shouldTrack, hasFastTrackAction]) => {
        if (shouldTrack) {
            this.gtmService.pushTag({
                fastTrackUser: hasFastTrackAction,
            })
        }
    })

    private readonly navigationCache$ = new ReplaySubject<NavigationEnd>()
    private readonly eventCache$ = new ReplaySubject<AnalyticsEvent>()
    lastEventFromCache: AnalyticsEvent | null = null

    private readonly trackableNavigationEvents$ = this.shouldTrackUser$.pipe(
        switchMap((shouldTrack) =>
            shouldTrack ? this.navigationCache$ : NEVER
        ),
        map((navigation) => ({
            event: 'page',
            pageName: navigation.urlAfterRedirects,
        }))
    )

    private readonly trackableClickEvents$ = this.shouldTrackUser$.pipe(
        switchMap((shouldTrack) => (shouldTrack ? this.eventCache$ : NEVER)),
        map((click) => ({
            event: 'event',
            category: click.category,
            action: click.action,
            label: click.label,
            value: click.value,
        }))
    )

    private readonly cacheNavigationEvents =
        this.navigationEndEvents$.subscribe(this.navigationCache$)

    private readonly trackEvents = merge(
        this.trackableClickEvents$,
        this.trackableNavigationEvents$
    ).subscribe((trackingEvent) => {
        this.gtmService.pushTag(trackingEvent)
    })

    private readonly trackUserId = this.trackableUserId$.subscribe((userId) => {
        this.gtmService.pushTag({ userId })
    })

    constructor(
        private router: Router,
        private user: UserProfileService,
        private featureFlag: FeatureFlagsService,
        private gtmService: GtmService,
        private localStorage: LocalStorageService,
        private authzService: AuthzService
    ) {
        this.userOptedOutOfTracking$.next(
            localStorage.has(AnalyticsService.TRACKING_OPT_OUT_KEY)
        )
    }

    ngOnDestroy() {
        this.cacheNavigationEvents.unsubscribe()
        this.trackEvents.unsubscribe()
        this.trackUserId.unsubscribe()
        this.trackableFastTrackAction.unsubscribe
    }

    trackEvent(event: AnalyticsEvent, noRepeat = false) {
        if (
            !noRepeat ||
            (noRepeat && !deepEqual(this.lastEventFromCache, event))
        ) {
            if (isDevelopment() || isIntEnvironment()) {
                console.log('google analytics', JSON.stringify(event))
            }
            this.eventCache$.next(event)
            this.lastEventFromCache = event
        }
    }

    deactivateTrackingOnThisDevice() {
        this.localStorage.set(
            AnalyticsService.TRACKING_OPT_OUT_KEY,
            new Date().toISOString()
        )
        this.userOptedOutOfTracking$.next(true)
    }
}
