import { Stage } from '@env-lib/stage'
import { apiConfig as intApiConfig } from './api-config.int'
import { ApiConfigModel } from './api-config.model'

const baseUrl = 'http://localhost:6000'
const authDomain = 'https://traigo-int.auth.eu-central-1.amazoncognito.com'
const clientId = '77ckel4a1gceug83md78rigs52'
const adLogoutUrl = `https://vtgb2c.b2clogin.com/vtgb2c.onmicrosoft.com/B2C_1_Traigo_SignIn_Int/oauth2/v2.0/logout?post_logout_redirect_uri=${baseUrl}/logout`

export const apiConfig: ApiConfigModel = {
    ...intApiConfig,
    auth: {
        ...intApiConfig.auth,
        cognitoLogoutUrl: `${authDomain}/logout?client_id=${clientId}&logout_uri=${adLogoutUrl}`,
        redirectUrl: `${baseUrl}/auth`,
    },
    launchDarkly: {
        ...intApiConfig.launchDarkly,
        useStreaming: false,
    },
    datadog: {
        environmentName: Stage.Local,
    },
}
