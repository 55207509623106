import { ConnectionPositionPair } from '@angular/cdk/overlay'
import { Component, HostBinding } from '@angular/core'
import { Action } from '@auth-util-lib/auth.model'
import { AuthzService } from '@auth-util-lib/authz.service'
import { FeatureFlags } from '@feature-flag-lib/feature-flags'
import { FeatureFlag } from '@feature-flag-lib/feature-flags.model'
import { LaunchDarklyService } from '@feature-flag-lib/launch-darkly.service'
import { Language } from '@localization-lib/language/models/Language'
import { DeveloperToolsService } from '@platform-lib/components/developer-tools/developer-tools.service'
import { ParallaxService } from '@shared-util-lib/parallax/parallax.service'
import { objectKeys } from '@util-lib/objectKeys'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
    selector: 'app-developer-tools',
    templateUrl: './developer-tools.component.html',
    styleUrls: ['./developer-tools.component.scss'],
})
export class DeveloperToolsComponent {
    Language = Language

    @HostBinding('class.open')
    isOpen = false

    scrollStrategy =
        this.parallaxService.scrollStrategies.blockAndPreserveParallax()
    position = new ConnectionPositionPair(
        { originX: 'end', originY: 'bottom' },
        { overlayX: 'end', overlayY: 'top' }
    )

    renderedLanguage$ = this.developerToolsService.overriddenLanguage$

    private tokenActions$ = this.authzService
        .getClaim$('permitted_actions')
        .pipe(
            map((permittedAcctionsClaim): Action[] => {
                const actionsFromToken = (
                    permittedAcctionsClaim
                        ? JSON.parse(String(permittedAcctionsClaim))
                        : []
                ) as Array<{ name: Action; activeSince?: string }>

                return actionsFromToken.map(
                    (actionFromToken) => actionFromToken.name as Action
                )
            })
        )

    renderedActions$ = combineLatest([
        this.tokenActions$,
        this.developerToolsService.disabledActions$,
    ]).pipe(
        map(([tokenActions, disabledActions]) =>
            objectKeys(Action)
                .sort()
                .map((key) => ({ label: key, action: Action[key] }))
                .filter(({ action }) => tokenActions.includes(action))
                .map(({ label, action }) => ({
                    label,
                    action,
                    checked: !disabledActions.includes(action),
                }))
        )
    )

    private launchDarklyFlags$ = this.launchDarklyService.allFlags$

    renderedFeatureFlags$ = combineLatest([
        this.launchDarklyFlags$,
        this.developerToolsService.overriddenFeatureFlags$,
    ]).pipe(
        map(([launchDarklyFlags, overriddenFeatureFlags]) =>
            objectKeys(FeatureFlags)
                .sort()
                .map((key) => ({ label: key, name: FeatureFlags[key].name }))
                .filter(({ name }) =>
                    objectKeys(launchDarklyFlags).includes(name)
                )
                .map(({ label, name }) => {
                    const overriddenValue = overriddenFeatureFlags.find(
                        (override) => override.flag.name === name
                    )?.value

                    return {
                        label,
                        name,
                        value:
                            overriddenValue !== undefined
                                ? overriddenValue
                                : launchDarklyFlags[name],
                    }
                })
                .filter(({ value }) => typeof value === 'boolean')
                .map(({ label, name, value }) => ({
                    label,
                    name,
                    value: !!value,
                    checked: !!value,
                    flag: FeatureFlags[label],
                }))
        )
    )

    selectLanguage(language: Language) {
        this.developerToolsService.overrideLanguage(language)
    }

    toggleAction(action: Action) {
        this.developerToolsService.toggleAction(action)
    }

    toggleFeatureFlag({
        checked,
        flag,
    }: {
        checked: boolean
        flag: FeatureFlag<boolean>
    }) {
        this.developerToolsService.overrideFeatureFlag({
            value: !checked,
            flag,
        })
    }

    constructor(
        private parallaxService: ParallaxService,
        private authzService: AuthzService,
        private launchDarklyService: LaunchDarklyService,
        public developerToolsService: DeveloperToolsService
    ) {}

    handleToggleClick(event: MouseEvent) {
        event.stopPropagation()
        this.isOpen = !this.isOpen
    }
}
