<mat-expansion-panel expanded class="mx-1 my-1">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <span translate>Users</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex flex-col flex-1 justify-between overflow-auto py-4">
        <span translate class="text-accent-medium py-2">
            Specify who can see this group
        </span>
        <mat-checkbox
            color="primary"
            [indeterminate]="someSelected$ | async"
            [formControl]="selectAllUsersControl"
        >
            <translate>Select all</translate>
        </mat-checkbox>
        <div
            *ngFor="let colleague of colleagues$ | async"
            [matTooltip]="
                colleague.isAdmin ? (disabledAdminsTooltip$ | async) : ''
            "
            class="pl-10"
        >
            <mat-checkbox
                color="primary"
                [formControl]="
                    (usersFormRecord$ | async)?.controls[colleague.userId]
                "
            >
                {{ colleague.firstName }} {{ colleague.lastName }}
            </mat-checkbox>
        </div>
    </div>
</mat-expansion-panel>
