import { Component } from '@angular/core'
import { CookieConsent, CookieConsentId } from '../cookie.consent.model'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-cookie-dialog',
    styleUrls: ['./cookie-dialog.component.scss'],
    templateUrl: './cookie-dialog.component.html',
})
export class CookieDialogComponent {
    constructor(private dialogRef: MatDialogRef<CookieDialogComponent>) {}

    cookiesAllowed: CookieConsent = {
        auth: false,
        cookieConsent: false,
        maptiler: false,
    }

    expandedId: CookieConsentId | null = null

    handleCheckbox(e: MouseEvent) {
        e.stopPropagation()
    }

    allowAll() {
        this.cookiesAllowed.cookieConsent = true
        this.cookiesAllowed.maptiler = true
        this.cookiesAllowed.auth = true
    }

    minimalAcceptenceReached(): boolean {
        if (
            this.cookiesAllowed.cookieConsent &&
            this.cookiesAllowed.maptiler &&
            this.cookiesAllowed.auth
        ) {
            return true
        }
        return false
    }

    handleClose(isAccepted: boolean) {
        let minimalAcceptence: boolean

        if (isAccepted) {
            minimalAcceptence = this.minimalAcceptenceReached()
        } else {
            minimalAcceptence = false
        }

        this.dialogRef.close({
            consents: this.cookiesAllowed,
            minimalAcceptenceReached: minimalAcceptence,
        })
    }
}
