import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { CookieConsentService } from '@platform-lib/legal/cookie/cookie-consent.service'
import { combineLatest, of } from 'rxjs'
import { catchError, concatMap, take, tap } from 'rxjs/operators'

@Component({
    selector: 'app-auth',
    styleUrls: ['./callback.component.scss'],
    templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnDestroy {
    private readonly authenticated$ = combineLatest([
        this.authn.stateChanges(),
        this.route.queryParamMap,
    ]).pipe(
        take(1),
        tap(([authState, queryParams]) => {
            if (
                !(queryParams.has('code') && queryParams.has('state')) ||
                authState === AuthenticationState.Authenticated
            ) {
                this.router.navigate(['/dashboard'])
                throw new Error('No code and state were provided')
            }
        }),
        concatMap(() => this.authn.finishSignIn()),
        tap(() => this.cookieConsent.eventuallySaveToServer()),
        tap((redirectUrl) => {
            const url = redirectUrl || '/'
            const path = url.split('?')[0]
            const queryParams = this.router.parseUrl(url).queryParams
            this.router.navigate([path], { queryParams })
        }),
        catchError(() => of(false))
    )

    private readonly subscription = this.authenticated$.subscribe()

    constructor(
        private authn: AuthnService,
        private router: Router,
        private route: ActivatedRoute,
        private cookieConsent: CookieConsentService
    ) {}

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
