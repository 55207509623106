export enum ServiceDeskStatus {
    CHECK = 'CHECK',
    WARNING = 'WARNING',
    ISSUE = 'ISSUE',
    INFO = 'INFO',
    MAINTENANCE = 'MAINTENANCE',
}

export interface ServiceDeskStatusResponse {
    status: ServiceDeskStatus
    message: {
        de: string
        en: string
    }
}
