import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { BannerHandlerComponent } from './banner-handler.component'
import { BannerComponent } from './banner/banner.component'

@NgModule({
    declarations: [BannerHandlerComponent, BannerComponent],
    exports: [BannerHandlerComponent, BannerComponent],
    imports: [CommonModule, ButtonModule, MatIconModule, TranslationModule],
})
export class BannerHandlerModule {}
