import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { defer, Observable, throwError, timer } from 'rxjs'
import { retry } from 'rxjs/operators'
import { AuthenticationState, AuthnService } from './authn.service'

@Injectable({ providedIn: 'root' })
export class TokenRefreshInterceptorService implements HttpInterceptor {
    protected maxRetryCount = 10

    protected retryDelayFactor = 100

    constructor(private authn: AuthnService) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return defer(() => next.handle(request)).pipe(
            retry({
                delay: (error, retryCount) => {
                    if (
                        retryCount <= this.maxRetryCount &&
                        (error as HttpErrorResponse)?.status === 401
                    ) {
                        if (
                            this.authn.authenticationState ===
                            AuthenticationState.Authenticated
                        ) {
                            this.authn.refreshAccessToken()
                        }
                        return timer(retryCount * this.retryDelayFactor)
                    }
                    return throwError(() => error)
                },
            })
        )
    }
}
