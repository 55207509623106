import { ErrorType } from '@error-util-lib/error.model'
import { createAction, props } from '@ngrx/store'
import {
    NotificationCountResponse,
    NotificationFilterValues,
    NotificationsActionRequest,
    NotificationsActionRequestWithValue,
    NotificationsRequest,
    NotificationsResponse,
} from '@platform-lib/notification/notification.model'

// count loading
export const loadCount = createAction('[Notification] Load count')

export const loadCountSuccess = createAction(
    '[Notification] Load count success',
    props<{ response: NotificationCountResponse }>()
)

// entry list loading
export const loadEntries = createAction(
    '[Notification] Load entries',
    props<NotificationsRequest>()
)

export const loadEntriesSuccess = createAction(
    '[Notification] Load entries success',
    props<{ response: NotificationsResponse }>()
)

export const loadEntriesError = createAction(
    '[Notification] Load entries error',
    props<{ error: ErrorType }>()
)

// chunk loading
export const loadChunk = createAction(
    '[Notification] Load chunk',
    props<NotificationsRequest>()
)

export const loadChunkSuccess = createAction(
    '[Notification] Load chunk success',
    props<{ response: NotificationsResponse }>()
)

export const loadChunkError = createAction(
    '[Notification] Load chunk error',
    props<{ error: ErrorType }>()
)

// filtering
export const setFilterValues = createAction(
    '[Notification] Set filter values',
    props<{ filterValues: NotificationFilterValues }>()
)

// remove entries
export const removeEntries = createAction(
    '[Notification] Remove entries',
    props<{ request: NotificationsActionRequest }>()
)

export const removeEntriesSuccess = createAction(
    '[Notification] Remove entries success',
    props<{ response: NotificationsActionRequest }>()
)

export const removeEntriesError = createAction(
    '[Notification] Remove entries error',
    props<{ error: ErrorType }>()
)

// set isMarked flag for entries
export const setIsMarked = createAction(
    '[Notification] Set isMarked',
    props<{ request: NotificationsActionRequestWithValue }>()
)

export const setIsMarkedSuccess = createAction(
    '[Notification] Set isMarked success',
    props<{ response: NotificationsActionRequestWithValue }>()
)

export const setIsMarkedError = createAction(
    '[Notification] Set isMarked error',
    props<{ error: ErrorType }>()
)

// set isRead flag for entries
export const setIsRead = createAction(
    '[Notification] Set isRead',
    props<{ request: NotificationsActionRequestWithValue }>()
)

export const setIsReadSuccess = createAction(
    '[Notification] Set isRead success',
    props<{ response: NotificationsActionRequestWithValue }>()
)

export const setIsReadError = createAction(
    '[Notification] Set isRead error',
    props<{ error: ErrorType }>()
)
