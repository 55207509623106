import { Component } from '@angular/core'
import { LanguageService } from '@localization-lib/language/language.service'
import { Language } from '@localization-lib/language/models/Language'

@Component({
    selector: 'app-terms-conditions',
    templateUrl: './terms-conditions-overview.component.html',
})
export class TermsConditionsComponent {
    readonly Language = Language

    readonly language$ = this.languageService.selectedLanguage$

    constructor(private languageService: LanguageService) {}
}
