import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { ApiService } from '@env-lib/api/api.service'
import { Language } from '@localization-lib/language/models/Language'
import { Observable, ReplaySubject, of } from 'rxjs'
import { distinctUntilChanged, map, retry, switchMap } from 'rxjs/operators'

export interface UserProfile {
    userId: string
    firstName: string
    lastName: string
    email: string
    timezone?: string
    language?: Language
}

@Injectable({ providedIn: 'root' })
export class UserProfileService implements OnDestroy {
    private readonly userProfile$ = new ReplaySubject<UserProfile | null>(1)
    private readonly userProfileSubscription = this.authn
        .stateChanges()
        .pipe(
            switchMap((authState) =>
                authState === AuthenticationState.Authenticated
                    ? this.fetchUserProfile()
                    : of(null)
            ),
            distinctUntilChanged()
        )
        .subscribe((userProfile) => this.userProfile$.next(userProfile))

    constructor(
        private api: ApiService,
        private authn: AuthnService,
        private http: HttpClient
    ) {}

    profileChanges() {
        return this.userProfile$.asObservable()
    }

    reloadProfile() {
        this.fetchUserProfile().subscribe((userProfile) =>
            this.userProfile$.next(userProfile)
        )
    }

    private fetchUserProfile(): Observable<UserProfile> {
        return this.http
            .get<UserProfile>(`${this.api.account.backendUrl}/users/profile`, {
                headers: new HttpHeaders({
                    SkipLanguageInterceptor: 'true',
                }),
            })
            .pipe(
                retry({
                    count: 3,
                    delay: 300,
                    resetOnSuccess: true,
                }),
                map((profile) => {
                    const language = profile.language
                    const profileCopy = { ...profile }
                    delete profileCopy.language
                    if (!!language && language in Language) {
                        profileCopy.language =
                            language.toLowerCase() as Language
                    }
                    return profileCopy
                })
            )
    }

    ngOnDestroy() {
        this.userProfileSubscription.unsubscribe()
    }
}
