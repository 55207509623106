import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslationModule } from '@localization-lib/language/translation.module'

@Component({
    standalone: true,
    imports: [CommonModule, TranslationModule, RouterModule],
    selector: 'app-landing-page-footer',
    templateUrl: './landing-page-footer.component.html',
})
export class LandingPageFooterComponent {}
