import { ErrorHandler, Injectable } from '@angular/core'
import { StatusType } from '@datadog/browser-logs'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'
import { ErrorType } from './error.model'

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private datadogService: DatadogService) {}

    handleError(error: ErrorType) {
        console.error(error)
        if (/Loading chunk [\d]+ failed/.test(error.toString())) {
            this.datadogService.log(error, StatusType.warn)
            window.location.reload()
        } else {
            this.datadogService.log(error)
        }
    }
}
